import { GET_CRONTAB_LIST, CREATE_CRONTAB, DELETE_CRONTAB, UPDATE_CRONTAB } from "./types";
import CrontabService from "../../services/crontab.service";
import { toast } from 'react-toastify';

export const createCronTab = (object) => async (dispatch) => {
    try {
        let res = await CrontabService.createCronTab(object);
        if (res.status === 200) {
            dispatch({
                type: CREATE_CRONTAB,
                payload: res.data
            });
            toast.success("Crone tab created successfully");
        } else {
            toast.error("Unable to create crone tab : " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};

export const updateCronTab = (object) => async (dispatch) => {
    try {
        let res = await CrontabService.updateCronTab(object);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_CRONTAB,
                payload: res.data
            });
            toast.success("Crone tab updated successfully");
        } else {
            toast.error("Unable to update crone tab : " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};

export const deleteCronTab = (id) => async (dispatch) => {
    try {
        let res = await CrontabService.deleteCronTabJob(id);
        if (res.status === 200) {
            dispatch({
                type: DELETE_CRONTAB,
                payload: res.data
            });
            toast.success("Crone tab deleted successfully");
        } else {
            toast.error("Unable to delete crontab : " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};

export const getCronTabList = (page, pageSize, sortColumn, sortDirection,filters) => async (dispatch) => {
    try {
        let res = await CrontabService.getCronTabList(page, pageSize, sortColumn, sortDirection,filters);
        if (res.status === 200) {
            dispatch({
                type: GET_CRONTAB_LIST,
                payload: res.data
            });
        } else {
            toast.error("Unable to fetch crontab list: " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};
