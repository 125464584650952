import { GET_PROJECTS_FOR_USERS, ASSIGN_PROJECTS_TO_USERS, UPDATE_PROJECTS, GET_ALL_USERS_LIST , 
  GET_PROJECTS_BY_USER_ID , DELETE_PROJECTS_BY_USER_ID , CLEAR_PROJECT_LIST_BY_USER} from "../actions/types";

const initialState = {
  projectList: null,
  usersList: null,
  projListByUser: null
};

const assignUsers = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PROJECTS_FOR_USERS:
      return {
        ...state,
        projectList: payload,
      };

    case ASSIGN_PROJECTS_TO_USERS:
      return {
        ...state,
        assignUsers: payload,
      };

    case UPDATE_PROJECTS:
      return {
        ...state,
        updatedList: payload,
      };

    case GET_ALL_USERS_LIST:
      return {
        ...state,
        usersList: payload,
      };

    case GET_PROJECTS_BY_USER_ID:
      return {
        ...state,
        projListByUser: payload,
      };

    case DELETE_PROJECTS_BY_USER_ID:
      return {
        ...state,
        isDeleted: true,
      };
    case CLEAR_PROJECT_LIST_BY_USER:
        return {
          ...state,
          projListByUser: null,
      };

    default:
      return state;
  }
};
export default assignUsers;
