import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";

class TestJobService {
    constructor() {
        this.headers = createHeaders();
    }

    async getTestJobList() {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/userwisetestcasejob/`;
            const response = await axios.get(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    async getSuiteTestCase(suite_job_id) { 
        try {    
            const url = `${ENV_VAR.API_HOST}/api/v1/suitejobtestCase/${suite_job_id}`;
            const response = await axios.get(url,this.headers);
            return response;
        } 
        catch (error) {
            throw error.response.data.error;
        }
    }

    async getTestDetailedReport(obj) { 
        try {    
            const url = `${ENV_VAR.API_HOST}/api/v1/getTestCaseJobBySearch/`;
            const response = await axios.post(url,obj,this.headers);
            return response;
        } 
        catch (error) {
            throw error.response.data.error;
        }
    }
    async deleteTestJobList(sel_job_id) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/delete/${sel_job_id}`;
            const response = await axios.delete(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }
}


export default new TestJobService();