import { CREATE_CLOCK_SCHEDULE , UPDATE_CLOCK_SCHEDULE , DELETE_CLOCK_SCHEDULE , GET_CLOCKED_LIST } from "./types";
import ClockedScheduleService from "../../services/clocked.service";
import { toast } from 'react-toastify';

export const getClockedList = (page, pageSize, sortColumn, sortDirection,filters) => async (dispatch) => {
    try {
        let res = await ClockedScheduleService.getClockedSchedule(page, pageSize, sortColumn, sortDirection,filters);
        if (res.status === 200) {
            dispatch({
                type: GET_CLOCKED_LIST,
                payload: res.data
            });
        } else {
            toast.error("Unable to fetch clock task list : " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};

export const createClockedTask = (object) => async (dispatch) => {
    try {
        let res = await ClockedScheduleService.createClockedSchedule(object);
        if (res.status === 200) {
            dispatch({
                type: CREATE_CLOCK_SCHEDULE,
                payload: res.data
            });
            toast.success("Clock task created successfully");
        } else {
            toast.error("Unable to create clock task : " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};

export const updateClokedList = (object) => async (dispatch) => {
    try {
        let res = await ClockedScheduleService.updateClockedSchedule(object);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_CLOCK_SCHEDULE,
                payload: res.data
            });
            toast.success("clock task updated successfully");
        } else {
            toast.error("Unable to update clock tab : " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};

export const deleteClocked = (id) => async (dispatch) => {
    try {
        let res = await ClockedScheduleService.deleteClockedSchedule(id);
        if (res.status === 200) {
            dispatch({
                type: DELETE_CLOCK_SCHEDULE,
                payload: res.data
            });
            toast.success("Clock task deleted successfully");
        } else {
            toast.error("Unable to delete clock task : " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};

