import Swal from 'sweetalert2';
import '../styles/common.css'

const showAlert = (message, type = 'info') => {
  return Swal.fire({
    icon: type,
    title: type === 'success' ? 'Success' : 'Alert',
    text: message,
    allowOutsideClick: false, 
    allowEscapeKey: false,
    allowEnterKey: false,
    focusConfirm: false,
    customClass: {
      popup: 'swal-popup', 
    },
  });
};

export const showSuccessAlert = (message) => {
  return showAlert(message, 'success');
};

export const showErrorAlert = (message) => {
  return showAlert(message, 'error');
};

export const showWarningAlert = (message) => {
  return showAlert(message, 'warning');
};

export const showInfoAlert = (message) => {
  return showAlert(message, 'info');
};

export default showAlert;