import NotificationsIcon from '@mui/icons-material/Notifications';
import { List, ListItem, Divider } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import io from "socket.io-client";
import ENV_VAR from "../common";

const SOCKET_SERVER_URL = `${ENV_VAR.API_HOST}`;

function Notification() {
    const [toggle, setToggle] = useState(false);
    const [jobList, setJobList] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dropdownRef = useRef(null);

    const socketRef = useRef(null);
    const [message, setMessage] = useState([]);

    useEffect(() => {
        // Initialize socket connection
        socketRef.current = io(SOCKET_SERVER_URL, {
            withCredentials: true,
        });

        const handleNotification = (data) => {
            setMessage((prevMessages) => [...prevMessages, data.data[0]]);
        };
        
      

        // Set up event listeners
        socketRef.current.on("Queue_messageto_react", handleNotification);

        // Clean up on unmount
        return () => {
            if (socketRef.current) {
                socketRef.current.off("Queue_messageto_react", handleNotification);
                socketRef.current.disconnect();
            }
        };
    }, []);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setToggle(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const length = message?.length;

    // const data = length > 0 ? (
    //     message.map((job, index) => (
    //         <ListItem key={index}>
    //             {job}
    //             <hr />
    //         </ListItem>
            
    //     ))
    // ) : (
    //     <div>No running jobs</div>
    // );
    const data = message.length > 0 ? (
        <List>
          {message.map((job, index) => (
            <React.Fragment key={index}>
              <ListItem>{job}</ListItem>
              <Divider sx={{ borderBottomWidth: '3px'}}/> {/* Divider to separate items */}
            </React.Fragment>
          ))}
        </List>
      ) : (
        <div>No running jobs</div>
      );

    return (
        <div style={{ position: 'relative', display: 'inline-block' }} ref={dropdownRef}>
            <NotificationsIcon onClick={() => setToggle(!toggle)} style={{ position: 'relative', fontSize: '30px', cursor: 'pointer' }} />
            <span style={{
                position: 'absolute',
                top: '-10px',
                right: '-10px',
                background: 'red',
                color: 'white',
                borderRadius: '100%',
                padding: '3px 7px',
                fontSize: '12px',
                fontWeight: 'bold',
                zIndex: 2
            }}>
                {length}
            </span>

            {toggle && (
                <div style={{
                    position: 'absolute',
                    zIndex: 1,
                    color: '#34B1AA',
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    padding: '10px',
                    borderRadius: '4px',
                    width: '300px',
                    right: '100%',
                    marginRight: '-20px',
                }}>
                    {loading ? (
                        <div>
                            <span style={{ color: 'black' }}>Loading...</span>
                        </div>
                    ) : (
                        data
                    )}
                </div>
            )}
        </div>
    );
}

export default Notification;



// // import NotificationsIcon from '@mui/icons-material/Notifications';
// import WorkIcon from '@mui/icons-material/Work';
// import { Link,Tooltip,ListItem} from "@mui/material";
// import React, { useEffect, useState ,useRef} from 'react';
// import { useNavigate } from 'react-router-dom';
// import io from "socket.io-client";
// import ENV_VAR from "../common";

// const SOCKET_SERVER_URL = `${ENV_VAR.API_HOST}`;
// export function DataList(suiteJob, TestCaseJob) {
    
    
    
//     const JobList = [];

    
//     if (suiteJob?.length > 0) {
//         for (let item of suiteJob) {
//             if (item.status === 'running') {
//                 JobList.push({ id: item.id, status: item.status, name: item.suite_name, task: item.task ,job_id:item.suite_id,type:'suite'});
//             }
//         }
//     }
//     if (TestCaseJob?.length > 0) {
//         for (let item of TestCaseJob) {
//             if (item.status === 'running') {
//                 JobList.push({ id: item.id, status: item.status, name: item.test_case_name, task: item.task, job_id:item.test_case_id, type:'testcase' });
//             }
//         }
//     }

//     return JobList.length > 0 ? JobList : null;
// }

// function Notification({ Suite, TestCase }) {
//     const [toggle, setToggle] = useState(false);
//     const [jobList, setJobList] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const navigate = useNavigate();
//     const dropdownRef = useRef(null);

//     const socketRef = useRef(null);
//     const [Message, setMessage] = useState(null);
//     const notify=[]
//     useEffect(() => {
//         // Initialize socket connection
//         socketRef.current = io(SOCKET_SERVER_URL, {
//           withCredentials: true,
//         });
        
//         const handleNotification = (data) => {
//             console.log("message from socket",data.data)
//             notify.push(data.data)
//         };
//         setMessage(notify)
//         console.log("inside socket",Message)
//         // Set up event listeners
//         socketRef.current.on("Queue_messageto_react", handleNotification);
//         // Clean up on unmount
//         return () => {
//           if (socketRef.current) {
//             socketRef.current.off("Queue_messageto_react", handleNotification);
//             socketRef.current.disconnect();
//           }
//         };
//       }, [Message,notify]);
      


//       console.log("message",Message)
//     const length = Message?.length || 0;
//     const data = Message?.length > 0 ? (
//         Message.map((job, index) => (
//             <ListItem key={index}>
//                 {job}
//             </ListItem>
//         ))
//     ) : (
//         <div>No running jobs</div>
//     );
    
//     const handleClickOutside = (event) => {
//         if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//             setToggle(false);
//         }
//     };
//     useEffect(() => {
//         document.addEventListener('mousedown', handleClickOutside);
//         return () => {
//             document.removeEventListener('mousedown', handleClickOutside);
//         };
//     }, []);


//     return (
//         <div style={{ position: 'relative', display: 'inline-block' }} ref={dropdownRef}>
//             <WorkIcon onClick={() => setToggle(!toggle)} style={{ position: 'relative', fontSize: '30px',cursor: 'pointer' }} />
//             <span style={{
//                 position: 'absolute',
//                 top: '-10px',
//                 right: '-10px',
//                 background: 'red',
//                 color: 'white',
//                 borderRadius: '100%',
//                 padding: '3px 7px',
//                 fontSize: '12px ',
//                 fontWeight: 'bold',
//                 zIndex: 2
//             }}>
//                 {length}
//             </span>

//             {toggle && (
//                 <div style={{
//                     position: 'absolute',
//                     zIndex: 1,
//                     color: '#34B1AA', 
//                     backgroundColor: 'white',
//                     border: '1px solid #ccc',
//                     padding: '10px',
//                     borderRadius: '4px',
//                     width:'300px',
//                     right:'100%',
//                     marginRight:'-20px',
                   
//                 }}>
//                     {loading ? (
//                         <div>
//                         <span style={{ color: 'black'}}>No Running Job</span>
//                         </div>
//                     ) : 
//                     {data}}
//                 </div>
//             )}
//         </div>
//     );
// }

  
// export default Notification;


