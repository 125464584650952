import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";

class attributeService {
    constructor() {
        this.headers = createHeaders();
     }

async createAttribute(obj) {
    try {
        const url = `${ENV_VAR.API_HOST}/api/v1/test_attributes`;
        const response = await axios.post(url, obj ,this.headers);
        return response;
    } 
    catch (error) {
        throw error.response.data.error;
    }
  }

async getAllAttributes(stepId) {
    try {
        const url = `${ENV_VAR.API_HOST}/api/v1/getattributebystepid/${stepId}`;
        const response = await axios.get(url, this.headers);
        return response;
    } 
    catch (error) {
        throw error.response.data.error;
    }
  }

  async updateAttributes(object) {
    try {
        const url = `${ENV_VAR.API_HOST}/api/v1/test_attributes`;  
        const response = await axios.put(url, object , this.headers);
        return response;
    } 
    catch (error) {
        throw error.response.data.error;
    }
  }

  async deleteAttributeById(test_attributes_id) {
    try {
        const url = `${ENV_VAR.API_HOST}/api/v1/test_attributes/${test_attributes_id}`;  
        const response = await axios.delete(url, this.headers);
        return response;
    } 
    catch (error) {
        throw error.response.data.error;
    }
  }
}

export default new attributeService();