import React from 'react';
import { Card, CardContent, Button, Tooltip, TableCell, TableRow, TextField , Divider , Typography , Grid } from '@mui/material';
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { formatDate } from '../../components/DateFormatter';
import { createClockedTask, updateClokedList, deleteClocked, getClockedList } from '../../redux/actions/clocked';
import extractSortAndFilterParams from '../../components/Table/filterSortParams';
import ServerPaginationGridNoRowCount from "../../components/Table/DataGrid";
import { useNavigate } from "react-router-dom";
import '../../styles/project.css';
import moment from 'moment-timezone';
import '../../styles/common.css';
import '../../styles/custom.css';
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const ClockTask = ({ tasksList, createClockedTask, updateClokedList, deleteClocked, getClockedList, clockList , count}) => {
    const [name, setName] = useState('');
    const [value, setValue] = React.useState();
    const [clockedData, setClockedData] = useState();
    const [isEditMode, setEditMode] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [dateError, setDateError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [clockedList, setClockedList] = useState([]);
    const [totalCount, setTotalCount] = useState([]);
    const navigate = useNavigate();
    const authConfig = JSON.parse(localStorage.getItem('authentication'));
    const timezone = authConfig ? authConfig['timezone'] : 'UTC';
    const date_format = authConfig ? authConfig['date_time_format'] : 'DD/MM/YYYY hh:mm A'
    const [paginationModals, setPaginationModals] = useState({
        page: 0,
        pageSize: 10,
    });
    const [sortModels, setSortModels] = useState([]);
    const [filterModels, setFilterModels] = useState({ items: [] });
    
    const columns = [
        { field: "name", headerName: "Name" },
        { field: "clocked_time", headerName: "Clocked Time" },
        { field: "u_name", headerName: "Created By" },
        {
            field: "Actions",
            headerName: "Actions",
            resizable: false,
            renderCell: (params) => (
                <>
                    <Tooltip title="Edit">
                    <EditIcon className='action-icon' onClick={() => editButtonClicked(params.row)}/>
                    </Tooltip>
                    <Tooltip title="Delete">
                    <DeleteIcon className='action-icon-delete' onClick={() => deleteClockedTask(params.row)}/>
                    </Tooltip>
                </>
            ),
        },
    ];
     const [width, setWidth] = useState(100/columns.length + '% !important');

    useEffect(() => {
        const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
        getClockedList(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort,JSON.stringify(filters));
    }, [ getClockedList, paginationModals.page , paginationModals.pageSize]
    )
 
    const formatDate2 = (dateString) => {
        // Format the date string using moment
        const authConfig = JSON.parse(localStorage.getItem('authentication'));
        const date_format = authConfig ? authConfig['date_time_format'] : 'YYYY-MM-DD HH:mm:ss'; // Default timezone
        const formattedDate = moment(dateString).format(date_format);
      
        return formattedDate;
      };
    useEffect(() => {
            const dataWithIds = clockList?.map((row, index) => ({
                ...row,
                id: row.clk_id,
                name: row.name,
                clocked_time: formatDate2(row.clocked_time),
                u_name: row.u_name,
            }));

            setClockedList(dataWithIds);
            setTotalCount(count);
    }, [clockList]
    )

    const handleNameChange = (event) => {
        event.target.value !=""?setNameError(false):setNameError(true);
        setName(event.target.value);
    };
    const handleTimeChange = (newValue) => {
       
        newValue !== "" ? setDateError(false) : setDateError(true);
        
        setValue(newValue);
      };
    const convertDate = (inputDateStr) => {
        const authConfig = JSON.parse(localStorage.getItem('authentication'));
        const timezone = authConfig ? authConfig['timezone'] : 'UTC'; // Default timezone
        const parsedDate = moment(inputDateStr, "DD/MM/YYYY hh:mm A").tz(timezone);
        return parsedDate.format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (zzzz)");
      };

    const onSubmit = () => {
        if (!value && name === "") {
            setDateError(true);
            setNameError(true);
            return false;
        } else if(value && name === "") {
            setDateError(false);
            setNameError(true);
            return false;
        } else if (!value && name === "") {
            setDateError(true);
            setNameError(false);
            return false;
        }
        
        let obj = {
            name: name,
            clocked_time: value
        }
        createClockedTask(obj).then(() => {
            const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
            getClockedList(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort,JSON.stringify(filters));
            setName('');
            setValue();
            setDateError(false);
            setNameError(false);
        })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const deleteClockedTask = (row) => {
        deleteClocked(row.clk_id).then(() => {
            const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
            getClockedList(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort,JSON.stringify(filters));
        })
            .catch((error) => {
                console.error('Error:', error);
            });
    }


    const editButtonClicked = (clockedData) => {
        setEditMode(true);
        setName(clockedData.name);
        setClockedData(clockedData);
        setValue(convertDate(clockedData.clocked_time));
        setDateError(false);
        setNameError(false);
       
    }

    const update = () => {
        let obj = {
            id: clockedData.clk_id,
            name: name,
            clocked_time: value
        }
        updateClokedList(obj).then(() => {
            const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
            getClockedList(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort,JSON.stringify(filters));
            setEditMode(false);
            setName('');
            setValue();
        }).catch((error) => {
                console.error('Error:', error);
            });
    }

    const cancelClicked = () => {
        setName('');
        setEditMode(false);
        setValue();
        navigate('/Scheduler');
    }

    // const tableRows = clockList?.map((row, index) => (
    //     <TableRow key={index}>
    //         <TableCell>{index + 1}</TableCell>
    //         <TableCell>{row.name}</TableCell>
    //         <TableCell>{dayjs(row.clocked_time).format('DD-MM-YYYY,HH:mm:ss')}</TableCell>
    //         <TableCell>{row.u_name}</TableCell>
    //         <TableCell>
    //             <Button colorStyle='gray' onClick={() => editButtonClicked(row)}>Edit</Button>
    //             <Button colorStyle='cancel' onClick={() => deleteClockedTask(row)}>Delete</Button>
    //         </TableCell>
    //     </TableRow>
    // ));

    return (
        <div>
            <Card
                raised
                sx={{
                    minWidth: "100%",
                    overflowX: "auto" 
                }}>
                <Typography variant="h5" sx={{
                    p: 2
                }}>
                  Create New Clock
                </Typography>
                <Divider></Divider>
                <CardContent>
                    {/* <div className='margin-profile' style={{ width: '26%', block: 'inline' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <label className='label-bold' >Clocked Date time</label>
                            <DatePicker
                                selected={value}
                                onChange={(newValue) => {setValue(newValue);newValue!==""?setDateError(false):setDateError(true)}}
                                timeInputLabel="Time:"
                                dateFormat="MM/dd/yyyy h:mm aa"
                                showTimeInput
                                // style={{ width: '2%' }}
                            />
                            {dateError ? <p className="erroColor">* Date time field is required</p> : ""}
                           
                            <br></br>
                            <label className='label-bold'>Name</label><TextField value={name} onChange={handleNameChange} />
                            {nameError ? <p className="erroColor">* Name field is required</p> : ""}
                        </LocalizationProvider>
                    </div> */}

                     {/* <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                            <DateTimePicker style={{ width: '30%' }}
                                    value={value}
                                    onChange={(newValue) => setValue(newValue)}
                                />

                             </DemoContainer> */}
                    
                    <Grid container spacing={2} sx={{ p: 2 }}>
                        <Grid item xs={4} sm={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Name:
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <TextField value={name} onChange={handleNameChange} style={{ width: '66%' }} />
                            {nameError ? <p className="erroColor">* Name field is required</p> : ""}
                        </Grid>

                        <Grid item xs={4} sm={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Clocked Date time:
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                selected={value}
                                onChange={handleTimeChange}
                                timeInputLabel="Time:"
                                dateFormat="MM/dd/YYYY hh:mm a"
                                //dateFormat={date_format}
                                showTimeInput
                                style={{ width: '100%' }}
                            />
                            {dateError && <p className="erroColor">* Date time field is required</p>}
                        </LocalizationProvider>
                        {/* <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                selected={value}
                                onChange={handleTimeChange}
                                // onChange={(newValue) => {setValue(newValue);newValue!==""?setDateError(false):setDateError(true)}}
                                timeInputLabel="Time:"
                                dateFormat="MM/dd/yyyy h:mm aa"
                                showTimeInput
                                style={{ width: '100%' }}
                            />
                            {dateError ? <p className="erroColor">* Date time field is required</p> : ""}
                        </LocalizationProvider> */}
                        </Grid>
                    </Grid>

                    <div>
                        {!isEditMode && <Button className='right' onClick={onSubmit}>Save</Button>}
                        {isEditMode && <Button className='right' onClick={update}>Update</Button>}
                        <Button className='right' colorStyle='cancel' onClick={() => cancelClicked()}>Back</Button>
                    </div>
                    <br></br>
                </CardContent>
            </Card>
            <br></br>
            <br></br>
            <Card
                raised
                sx={{
                    minWidth: "100%",
                    overflowX: "auto"
                }}>
                <Typography variant="h5" sx={{
                    p: 2
                }}>
                    Clocked List
                </Typography>
                <Divider></Divider>
                 <CardContent>
                        <ServerPaginationGridNoRowCount
                                setPaginationModals={setPaginationModals}
                                paginationModals={paginationModals}
                                rowsData={clockedList}
                                total={count}
                                columns={columns}
                                columnWidth={width}
                                setSortModels={setSortModels}
                                sortModels={sortModels}
                                setFilterModels={setFilterModels}
                                filterModels={filterModels}
                            />
                    </CardContent>
                {/* <TableContainer component={Card} className='dashboard-table table td'>
                    <Table>
                        <TableHead className='th'>
                            <TableRow style={cellStyle} >
                                <TableCell style={cellStyle}>Index</TableCell>
                                <TableCell style={cellStyle}>Name</TableCell>
                                <TableCell style={cellStyle}>Clocked Time</TableCell>
                                <TableCell style={cellStyle}>Created By</TableCell>
                                <TableCell style={cellStyle}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableRows}
                        </TableBody>
                    </Table>
                </TableContainer> */}
            </Card>
        </div>
    );
}

const mapStateToProps = (state) => ({
    clockList: state?.clocked?.clockedList?.rows,
    count: state?.clocked?.clockedList?.count
});

const mapDispatchToProps = {
    createClockedTask,
    updateClokedList,
    deleteClocked,
    getClockedList
};

export default connect(mapStateToProps, mapDispatchToProps)(ClockTask);


