import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";

class ProjectService {
    constructor() {
        this.headers = createHeaders();
    }

    async getProjectList(page,pageSize,sortColumn, sortDirection,filters) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/projects?pageNo=${page}&size=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&filters=${filters}`;
            const response = await axios.get(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }


    addNewProject = async (projectName) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/projects`;
            const response = await axios.post(url, {
                name: projectName,
            }, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    updateProjectName = async (projectInfo) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/projects`;
            const response = await axios.put(url, {
                name: projectInfo.name,
                id: projectInfo.id
            }, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    deleteProject = async (projectId) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/projects/${projectId}`;
            const response = await axios.delete(url,
                {
                    id: projectId
                }, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }
}


export default new ProjectService();