import { GET_TEMPLATES, ADD_TEMPLATES } from "./types";
import templateService from "../../services/template.service";
import { toast } from 'react-toastify';

export const getTemplateList = () => async (dispatch) => {
    try {
        let res = await templateService.getTemplates();
        if (res.status === 200) {
            dispatch({
                type: GET_TEMPLATES,
                payload: res.data
            });
        } else {
            toast.error("Unable to fetch template list : " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};

export const addTemplates = (obj) => async (dispatch) => {
    try {
        let res = await templateService.addTemplates(obj);
        if (res.status === 200) {
            dispatch({
                type: ADD_TEMPLATES,
                payload: res.data
            });
            toast.success("Template added successfully")
        } else {
            toast.error("Unable to add template : " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};
