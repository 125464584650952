import React from 'react';
import { Card, CardContent, Button, TextField, Divider, Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { useState, useEffect } from 'react';
import '../../styles/project.css'
import { createAITest } from '../../redux/actions/aiTest';
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import '../../styles/common.css';
import '../../styles/custom.css';

const AICard = styled(Card)({
    marginBottom: '10px',
    padding: '20px'
});

const AITest = ({
    createAITest
}) => {

    const [name, setname] = useState('');
    const [email, setEmail] = useState('');
    const [url, seturl] = useState('');
    const [password, setpassword] = useState('');
    const[loading,setloading]=useState(false);
    const [formData, setFormData] = useState({
        name:'',
        email: '',
        password: '',
        url: ''
    });


    const onChangename = (event) => {
        setname(event.target.value);
    }

    const onChangeEmail = (event) => {
        setEmail(event.target.value);
    }

    const onChangeurl = (event) => {
        seturl(event.target.value);
    }

    const onChangepassword = (event) => {
        setpassword(event.target.value);
    }

    const onSubmit = (event) => {
        event.preventDefault();
        if (name==='' ) {
            toast.error('Test case name can not be empty');
            return;
        }
       else if ( url==='') {
            toast.error('Web site url can not be empty');
            return;
        }
       else{ 
        const obj = {
            name: name,
            email: email,
            password: password,
            url: url
        }
         setloading(true);
        createAITest(obj)
                .then(() => {
                    setloading(false);
                    setname('');
                    seturl('');
                    setEmail('');
                    setpassword('');
                    // toast.success('AI Test case generated successfully');
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
            }
    }

    return (
        <div>
            {loading ? <Typography>Test Generating.....</Typography>:(<Card
                raised
                sx={{
                    minWidth: "100%",
                    overflowX: "auto"
                }}>
                <Typography variant="h5" sx={{
                    p: 2
                }}>
                    AI-Powered Test
                </Typography>
                <Divider></Divider>
                <CardContent>
                    <Grid container spacing={2} sx={{ p: 2 }}>
                        <Grid item xs={4} sm={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Testcase name:
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <TextField placeholder="Enter testcase name" required  onChange={onChangename}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                URL:
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <Typography variant="subtitle2" fontSize={14}>
                                <TextField placeholder="Enter URL" onChange={onChangeurl} />
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Username:
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <TextField placeholder="Enter username" required onChange={onChangeEmail} />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Password:
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <Typography variant="subtitle2" fontSize={14}>
                                <TextField placeholder="Enter password" type="password"  onChange={onChangepassword} />
                            </Typography>
                        </Grid>
                    </Grid>

                    <div style={{ marginBottom: '30px' }}>
                        <Button sx={{ float: 'right'}} onClick={onSubmit}>Generate AI Test Case</Button>
                    </div>
                </CardContent>
              
            </Card>)}
        </div>
    );
}

const mapStateToProps = (state) => ({
    //currentUserDetails: state.profile.userDetails
});

const mapDispatchToProps = {
    createAITest
};

export default connect(mapStateToProps, mapDispatchToProps)(AITest);


