import {
  CREATE_SUITES, GET_SUITES, UPDATED_SUITES, DELETE_SUITES, ALL_TEST_LIST_FOR_SUITES,
  CREATE_TEST_SUITE_CASE, GET_SUITES_TESTCASES, GET_SUITES_TESTCASES_BY_ID,
  UPDATED_SUITES_TESTCASES, DELETE_SUITE_TESTCASES, RUN_SUITES
}
  from "./types";
import SuiteService from "../../services/suites.service";
import runJobService from "../../services/runJob.service";
import { toast } from 'react-toastify';

export const createSuites = (object) => async (dispatch) => {
  try {
    let res = await SuiteService.createSuites(object);
    if (res.status === 200) {
      dispatch({
        type: CREATE_SUITES,
        payload: res.data
      });
      toast.success("Suite created Successfully");
    } else {
      toast.error("Unable to create suite" + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const updateSuites = (updatedSuitesData) => async (dispatch) => {
  try {
    let res = await SuiteService.updateSuites(updatedSuitesData);
    if (res.status === 200) {
      dispatch({
        type: UPDATED_SUITES,
        payload: res.data
      });
      toast.success("Suite updated Successfully");
    } else {
      toast.error("Unable to update suite" + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const deleteSuites = (id) => async (dispatch) => {
  try {
    let res = await SuiteService.deleteSuites(id);
    if (res.status === 200) {
      dispatch({
        type: DELETE_SUITES,
        payload: res.data
      });
      toast.success("Suite deleted Successfully");
    } else {
      toast.error("Unable to delete suite : " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const getSuitesList = (page,size, sortColumn, sortDirection,filters) => async (dispatch) => {
  try {
    let res = await SuiteService.getSuitesList(page,size, sortColumn, sortDirection,filters);
    if (res.status === 200) {
      dispatch({
        type: GET_SUITES,
        payload: res.data
      });
    } else {
      toast.error("Unable to fetch suite list : " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

//------------------------ Get all cases for suites ---------------------------

export const getAllTestCaseForSuites = (page, pageSize, sortColumn, sortDirection,filters) => async (dispatch) => {
  try {
    let res = await SuiteService.allTestCases(page, pageSize, sortColumn, sortDirection,filters);
    if (res.status === 200) {
      dispatch({
        type: ALL_TEST_LIST_FOR_SUITES,
        payload: res.data
      });
    } else {
      toast.error("Unable to fetch suite list : " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

//------------------------ Suite Test Case -----------------------------------

export const createSuiteTestCases = (obj) => async (dispatch) => {
  try {
    let res = await SuiteService.createSuiteTestCase(obj);
    if (res.status === 200) {
      dispatch({
        type: CREATE_TEST_SUITE_CASE,
        payload: res.data
      });
    } else {
      toast.error("Unable to create suite testcase : " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const getSuitesTestCase = () => async (dispatch) => {
  try {
    let res = await SuiteService.getSuiteTestCase();
    if (res.status === 200) {
      dispatch({
        type: GET_SUITES_TESTCASES,
        payload: res.data
      });
    } else {
      toast.error("Unable to fetch suite case : " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};


export const getSuitesTestCaseForDetailedReport = (page,size, sortColumn, sortDirection,filters) => async (dispatch) => {
  try {
    let res = await SuiteService.getSuitesList(page,size, sortColumn, sortDirection,filters);
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error("Unable to fetch suite case : " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const getSuitesTestCaseId = (suiteId) => async (dispatch) => {
  try {
    let res = await SuiteService.getSuiteTestCaseById(suiteId);
    if (res.status === 200) {
      dispatch({
        type: GET_SUITES_TESTCASES_BY_ID,
        payload: res.data
      });
    } else {
      toast.error("Unable to fetch suite testcase : " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const getTestCaseNames = (suiteId) => async (dispatch) => {
  try {
    let res = await SuiteService.getSuiteTestCaseById(suiteId);
    if (res.status === 200) {
      return res.data;
    } 
  } catch (error) {
    toast.error(error);
  }
};

export const updateSuitesTestCase = (obj) => async (dispatch) => {
  try {
    let res = await SuiteService.updateSuiteTestCase(obj);
    if (res.status === 200) {
      dispatch({
        type: UPDATED_SUITES_TESTCASES,
        payload: res.data
      });
    } else {
      toast.error("Unable to update suite testcase : " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const updateTestCaseStatusById = (obj) => async (dispatch) => {
  try {
    let res = await SuiteService.updateSuiteTestCaseById(obj);
    if (res.status === 200) {
      dispatch({
        type: UPDATED_SUITES_TESTCASES,
        payload: res.data
      });
      toast.success("Suite case status updated successfully");
    } else {
      toast.error("Unable to fetch suite testcase : " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const deleteSuitesTestCase = (obj) => async (dispatch) => {
  try {
    let res = await SuiteService.deleteSuiteTestCase(obj);
    if (res.status === 200) {
      dispatch({
        type: DELETE_SUITE_TESTCASES,
        payload: res.data
      });
      toast.success("Suite case deleted successfully");
    } else {
      toast.error("Unable to delete suite case : " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const runSuiteJob = (obj) => async (dispatch) => {
  try {
    let res = await runJobService.runSuites(obj);
    if (res.status === 200) {
      return res.data;
      
    } 
  } catch (error) {
    
  }
};
