import React, { useEffect, useState } from 'react';
import { Card, CardContent } from '@mui/material';
import { getFilesList, uploadFiles, replaceFiles, deleteFile } from '../../redux/actions/files';
import { updateFile } from "../../redux/actions/testcase";
import Tooltip from '@mui/material/Tooltip';
import { connect } from "react-redux";
import BackupIcon from '@mui/icons-material/Backup';
import SaveIcon from '@mui/icons-material/Save';
import Select from 'react-select';
import Divider from '@mui/material/Divider';
import { toast } from 'react-toastify';
import '../../styles/common.css';
import '../../styles/custom.css';
import '../../styles/files.css';

function UploadExcel({ getFilesList, uploadFiles, updateFile, data , refreshStepList }) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileList, setFilelist] = useState([]);
    const [filesArray, setFilesArray] = useState([]);

    useEffect(() => {
        getFilesList().then((res) => {
            setFilelist(res);
            createDropDownList(res);
        });
    }, [getFilesList]);

    const createDropDownList = (allFileslist) => {
        let allFiles = [];
        if (allFileslist) {
            allFileslist.forEach((file, index) => {
                let obj = {
                    id: file.f_id,
                    value: file.f_original_name,
                    label: file.f_original_name
                }
                allFiles.push(obj);
            });
        }
        setFilesArray(allFiles);
    }

    const handleFileChange = (event) => {
        setSelectedFile(event)
        console.log("File change", event);
    }

    const saveFiles = () => {
        let obj = {
            id: data?.step_id,
            file_id: selectedFile.id
        }
        updateFile(obj).then(() => {
            if (refreshStepList) {
                refreshStepList();
            }
        });
    }

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('files', file);
        uploadFiles(formData).then((res) => {
            let obj = {
                id: data?.step_id,
                file_id: res?.data[0]?.f_id
            }
            updateFile(obj).then(() => {
                if (refreshStepList) {
                    refreshStepList();
                }
            });
        }); 
    };

    return (
        <div className='marginTop'>
            <h3 className='label-bold'>{ data.step_name}</h3>
            <div className='flex-container marginTop'>
                <br />
                <div className='flex-item'>
                    <div>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={filesArray[0]}
                            name="name"
                            options={filesArray}
                            onChange={handleFileChange}
                        />
                    </div>
                </div>
                <div className='flex-item'>
                    <Tooltip title="Save selected file">
                        <SaveIcon style={{ color: '#34B1AA' }} onClick={() => saveFiles()} className="action-icon" />
                    </Tooltip>
                </div>
            </div>
            <div className='marginTop'>
                <Divider><span style={{ color: 'grey' }}>Or upload from local system</span></Divider>
            </div>
            <div className='marginTop'>
                <Card>
                    <CardContent>
                        <div className='upload-container'>
                            <label htmlFor="file-upload" className="upload-label">
                                <BackupIcon className="upload-icon" />
                                <span>Upload documents from here</span>
                            </label>
                            <input id="file-upload" type="file" className="file-input" onChange={handleFileUpload} />
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
}


const mapStateToProps = (state) => ({
    filesList: state?.files?.filesList
});

const mapDispatchToProps = {
    getFilesList, 
    uploadFiles,
    replaceFiles,
    deleteFile,
    updateFile
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadExcel);