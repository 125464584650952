import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";

class assignUserService {
    constructor() {
        this.headers = createHeaders();
     }

    async getAllProjects() {
        try {    
            const url = `${ENV_VAR.API_HOST}/api/v1/users_projects`;
             const headers = createHeaders();  
            const response = await axios.get(url,this.headers);
            return response;
        } 
        catch (error) {
            throw error.response.data.error;
        }
    }

    async assignProjectToUser(obj) { 
        try {    
            const url = `${ENV_VAR.API_HOST}/api/v1/users_projects`;
             const headers = createHeaders();  
            const response = await axios.post(url,obj,this.headers);
            return response;
        } 
        catch (error) {
            throw error.response.data.error;
        }
    }

    
    async updateProjectAssignmentToUsers(obj) { 
        try {    
            const url = `${ENV_VAR.API_HOST}/api/v1/users_projects`;
             const headers = createHeaders();  
            const response = await axios.put(url,obj,this.headers);
            return response;
        } 
        catch (error) {
            throw error.response.data.error;
        }
    }

    async getAllUsers(obj) { 
        try {    
            const url = `${ENV_VAR.API_HOST}/api/v1/Userlist`;
             const headers = createHeaders();  
            const response = await axios.get(url,this.headers);
            return response;
        } 
        catch (error) {
            throw error.response.data.error;
        }
    }

    async getProjectListUserWise(id) { 
        try {    
            const url = `${ENV_VAR.API_HOST}/api/v1/Userwiselist/${id}`;
             const headers = createHeaders();  
            const response = await axios.get(url,this.headers);
            return response;
        } 
        catch (error) {
            throw error.response.data.error;
        }
    }

    async deleteProjectsUserWise(id) { 
        try {    
            const url = `${ENV_VAR.API_HOST}/api/v1/deleteuserwise/${id}`;
             const headers = createHeaders();  
            const response = await axios.delete(url,this.headers);
            return response;
        } 
        catch (error) {
            throw error.response.data.error;
        }
    }
}

export default new assignUserService();