import {GET_TEMPLATES , ADD_TEMPLATES} from "../actions/types";
    
    const initialState = {
        templateList: null
    };
    
    const template = (state = initialState, action) => {
      const { type, payload } = action;
    
      switch (type) {
  
          case GET_TEMPLATES:
            return {
              ...state,
              templateList: payload, 
          };
          
          case ADD_TEMPLATES:
            return {
              ...state,
              isTemplateAdded: payload, 
          };
          
        default:
          return state;
      }
    };
    export default template;
    