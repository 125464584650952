// ErrorBox.js
import React from 'react';
import parse from 'html-react-parser';
const ErrorBox = ({ message, onClose }) => {
  return (
    <div className="error-box">
      {parse(message)}
      <button className="close-btn" onClick={onClose}>
        &times;
      </button>
    </div>
  );
};

export default ErrorBox;
