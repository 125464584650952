import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';
import CustomNoRowsOverlay from './CustomNoRowsOverlay';
// Define custom styles using makeStyles
const useStyles = makeStyles(() => ({
  dataGrid: {
    '& .MuiDataGrid-columnHeader': {
      flex: 1,
      width: props => props.columnWidth, // Use the dynamic columnWidth prop here
      minWidth: props => props.columnWidth,
      maxWidth: props => props.columnWidth,
      boxSizing: 'border-box',
    },
    '& .MuiDataGrid-cell': {
      flex: 1,
      width: props => props.columnWidth,
      minWidth: props => props.columnWidth,
      maxWidth: props => props.columnWidth,
      boxSizing: 'border-box',
    },
  },
}));
function NoRowsOverlay() {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      No rows in DataGrid
      <pre>(rows=&#123;[]&#125;)</pre>
    </Stack>
  );
}

function NoResultsOverlay() {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      No results in DataGrid
      <pre>(rows=&#123;rowData&#125;)</pre>
      But local filter returns no result
    </Stack>
  );
}
export default function ServerPaginationGridNoRowCount({
  setPaginationModals,
  paginationModals,
  setSortModels,
  sortModels,
  setFilterModels,
  filterModels,
  rowsData,
  total,
  columns,
  columnWidth,
}) {
  const classes = useStyles({ columnWidth });
  const [paginationModel, setPaginationModel] = useState(paginationModals);
  const [sortModel, setSortModel] = useState(sortModels);
  const [filterModel, setFilterModel] = useState(filterModels);

  useEffect(() => {
    setPaginationModals(paginationModel);
    setSortModels(sortModel);
    setFilterModels(filterModel);
  }, [paginationModel, sortModel, filterModel]);

  return (
    <DataGrid
      className={classes.dataGrid}
      rows={rowsData}
      // columns={columns}
      columns={columns.map(col => ({ ...col, filterable: false }))}
      pageSizeOptions={[5, 10, 25, 50]}
      paginationModel={paginationModel}
      paginationMode="server"
      onPaginationModelChange={setPaginationModel}
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      // filterMode="server"
      // filterModel={filterModel}
      // onFilterModelChange={setFilterModel}
      disableRowSelectionOnClick
      rowCount={total}
      localeText={{
        noRowsLabel: 'No data found' // Your custom message
      }} 
      sx={{
        mt: 2,
      }}
    />
  );
}
