import {GET_ATTRIBUTES_FOR_EXPORT , GET_VALIDATIONS_FOR_EXPORT ,GET_TEST_STEPS_FOR_EXPORT ,CLEAR_ATTRIBUTES_LIST_EXPORT,
  CLEAR_VALIDATION_LIST_EXPORT, CLEAR_TESTSTEPS_LIST_EXPORT} from "../actions/types";
    
    const initialState = {
        attributesListForExport: null,
        testStepListForExport: null,
        validationsListForExport: null
    };
    
    const exportImport = (state = initialState, action) => {
      const { type, payload } = action;
    
      switch (type) {
  
          case GET_ATTRIBUTES_FOR_EXPORT:
            return {
              ...state,
              attributesListForExport: payload, 
          };

          case GET_VALIDATIONS_FOR_EXPORT:
            return {
              ...state,
              validationsListForExport: payload, 
          };

          case GET_TEST_STEPS_FOR_EXPORT:
            return {
              ...state,
              testStepListForExport: payload, 
          };
          
          case CLEAR_ATTRIBUTES_LIST_EXPORT:
            return {
              ...state,
              attributesListForExport: null, 
          };

          case CLEAR_VALIDATION_LIST_EXPORT:
            return {
              ...state,
              validationsListForExport: null, 
          };

          case CLEAR_TESTSTEPS_LIST_EXPORT:
            return {
              ...state,
              testStepListForExport: null, 
          };
          
        default:
          return state;
      }
    };
    export default exportImport;
    