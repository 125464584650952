import { useEffect, useState } from "react";
import { Line} from "react-chartjs-2";
import DateFormatter from "./DateFormatter";


function LineChart({dashboardList}){

    const [label,setLabel]=useState([]);
    const [pass ,setPass ]=useState([]);
    const [fail ,setFail ]=useState([]);
    const [stop ,setStop ]=useState([]);

    useEffect(()=>{

        let newLabel=[];
        let newPass=[]; 
        let newFail=[]; 
        let newStop=[]; 
        if (dashboardList?.length > 0){
            for (let item in dashboardList) {
              let dateObject = new Date(dashboardList[item].execution_time)
              dateObject = <DateFormatter date={dateObject} />
              const date = dateObject?.props?.date.toLocaleDateString(); // Fetches the date
              const time = dateObject?.props?.date.toLocaleTimeString(); // Fetches the time
              const obj=[date,time]
              
                newLabel.push(obj);
                if (dashboardList[item].suite_status === "success") {
                  newPass.push(dashboardList[item].total_cases);
                  newFail.push(0);
                  newStop.push(0);
                } else if(dashboardList[item].suite_status === "failure"){
                  let obj = dashboardList[item].sel_logs;
                  let pass_count = 0;
                  let fail_count = 0;
                  let stop_count = 0;
                  for( let item in obj){
                    
                    if (obj[item].test_status === "success"){
                      pass_count=pass_count + 1
                    }else{
                      fail_count = fail_count + 1
                    }
            
                  }
                  newPass.push(pass_count);
                  newFail.push(fail_count);
                  newStop.push(stop_count);
                } else{
                  let total = dashboardList[item].total_cases
                  let obj = dashboardList[item].sel_logs;
                  let pass_count = 0;
                  let fail_count = 0;
                  let stop_count = 0;
                  for( let item in obj){
                    
                    if (obj[item].test_status === "success"){
                      pass_count=pass_count + 1
                    }else if(obj[item].test_status === "failure"){
                      fail_count = fail_count + 1
                    }else{
                      stop_count = stop_count + 1
                    }
            
                  }
                  stop_count+= total - (pass_count + fail_count + stop_count)
                  newPass.push(pass_count);
                  newFail.push(fail_count);
                  newStop.push(stop_count);
            
                }
              }

        }
        newPass.push(0);
        newFail.push(0);
        newStop.push(0);
        newLabel.push(0);

        newPass = newPass.reverse();
        newFail = newFail.reverse();
        newStop = newStop.reverse();
        newLabel = newLabel.reverse();

        setLabel(newLabel);
        setPass(newPass);
        setFail(newFail);
        setStop(newStop);
        

    },[dashboardList]);

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const gradientPass = ctx.createLinearGradient(0, 0, 0, 400);
    gradientPass.addColorStop(0, "rgba(0, 255, 0, 0.9)");
    gradientPass.addColorStop(1, "rgba(0, 255, 0, 0)");

    const gradientFail = ctx.createLinearGradient(0, 0, 0, 400);
    gradientFail.addColorStop(0, "rgba(255, 0, 0, 0.9)");
    gradientFail.addColorStop(1, "rgba(255, 0, 0, 0)");

    const gradientStop = ctx.createLinearGradient(0, 0, 0, 400);
    gradientStop.addColorStop(0, "rgba(255, 234, 0, 0.9)");
    gradientStop.addColorStop(1, "rgba(255, 234, 0, 0)");

    const data = {
        labels: label,
        datasets: [
          {
            label: "Failed",
            data: fail,
            fill: false,
            borderColor: "rgb(255,0,0)",
            // backgroundColor:gradientFail ,//"rgba(255,0,0,0.2)", // Red background color for "Failed"
            borderWidth: 1.5,
            pointBorderWidth: 1,
            pointRadius: 4,
            pointHoverRadius: 2,
            pointBackgroundColor: "rgb(255,0,0)",
            pointBorderColor: "#fff",
          },
          {
            label: "Passed",
            data: pass,
            // backgroundColor: gradientPass, // Green background color for "Passed"
            borderColor: "rgb(0,245,0)",
            borderWidth: 1.5,
            fill: false,
            pointBorderWidth: 1,
            pointRadius: 4,
            pointHoverRadius: 2,
            pointBackgroundColor: "rgb(0,255,0)",
            pointBorderColor: "#fff",
          },
          {
            label: "Stopped",
            data: stop,
            fill: false,
            // backgroundColor: gradientStop, // Red background color for "Failed"
            borderColor: "rgb(255, 234, 0)",
            borderWidth: 1.5,
            pointBorderWidth: 1,
            pointRadius: 4,
            pointHoverRadius: 2,
            pointBackgroundColor: "rgb(255, 234, 0)",
            pointBorderColor: "#fff",
          },
        ],
      };
      const options = {
        responsive: true,
        maintainAspectRatio: true,
        // backgroundColor: 'rgba(240, 240, 240, 0.5)',
        // scales: {
        //   yAxes: [
        //     {
              
        //       ticks: {
        //         beginAtZero: true,
        //         autoSkip: true,
        //         maxTicksLimit: 2,
        //         fontSize: 10,
        //         color: "green",
        //         grid: {
        //           display: false, // Remove grid lines on x-axis
        //         },
        //       },
        //     },
        //   ],
        //   xAxes: [
        //     {
              
        //       ticks: {
        //         beginAtZero: true,
        //         autoSkip: true,
        //         maxTicksLimit: 2,
        //         fontSize: 10,
        //         color: "red",
        //         grid: {
        //           display: false, // Remove grid lines on x-axis
        //         },
        //       },
        //     },
        //   ],
        // },
        scales: {
              y: {
                ticks: {
                  beginAtZero: true,
                  autoSkip: true,
                  maxTicksLimit: 5,
                  fontSize: 10,
                  color: "grey",
                },
                grid: {
                  display: true, // Remove grid lines on y-axis
                },
              },
              x: {
                ticks: {
                    beginAtZero: true,
                    autoSkip: true,
                    maxTicksLimit: 10,
                    fontSize: 10,
                    color: "grey",
                },
                grid: {
                  display: false, // Remove grid lines on x-axis
                },
              },
            },
        legend: {
          display: false, // Hides the legend
        },
        elements: {
          line: {
            tension: 0.5,
          },
        },
        tooltips: {
          backgroundColor: "rgba(31, 59, 179, 0)",
        },
      };
      
    return(
        <Line data={data} options={options} />
    )
}

export default LineChart
