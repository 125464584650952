import { Card, CardContent, Button } from '@mui/material';
import { connect } from "react-redux";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import { createTestCase } from "../redux/actions/testcase";
import '../styles/common.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import validationRules from '../components/ValidationRules';
import validateForm from '../components/Validate';
import ErrorBox from '../components/ErrorBox';
export const AddTestCases = ({createTestCase, testCaseData}) =>{
const { state } = useLocation();
const [testcaseName, setName] = useState('');
const navigate = useNavigate();

  const initialValues = {
    name: '',
    proj_id: state?.projectInfo?.proj_id,
    case_is_template:false
  };
  const fields = [
    { name: 'name', label: 'Test Case', type: 'text', validations: [validationRules.required] },
  ];
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState();
  const [globalError, setGlobalError] = useState('');
  const onChangeTestcaseName = (e) => {
    // setName(event.target.value);
    const {name, value} = e.target;
    setValues({...values, [name]:value});
    
  }
  const validate = () => {
    const { newErrors, globalError } = validateForm(values, fields);
    setErrors(newErrors);
    setGlobalError(globalError);
    return Object.keys(newErrors).length === 0;
  };
   const create = async () => {
    if (validate()) {
     await createTestCase(values);
     navigate('/testcase');
    }
  }

   const navigateToAllTestCase = () => {
      navigate('/testcases/all');
    }


  return (
    <div className='marginTop'>
      <h3 className='label-bold'>Create New Testcase {state?.projectInfo.proj_name}</h3>    
      <Card className='marginTop'>
        <CardContent style ={{padding: '2%'}}>
          <Row>
          {globalError && <ErrorBox message={globalError} onClose={() => setGlobalError('')} />}
          {fields.map((field) => (
            <Col className='col-md-2'>
            <label className='label-bold marginRight'>Testcase name:</label>
            <span>
              <TextField type={field.type}
                      name={field.name}
                      value={values[field.name]} 
                      onChange={onChangeTestcaseName} />
            </span>
          </Col>
          ))}
          </Row>
          <Row className='marginTop'>
          <Col className='col-md-4'>
              <Button className='marginTop' onClick={create}>Save</Button>
              <Button colorStyle='cancel' onClick={navigateToAllTestCase}>Cancel</Button>
          </Col>
          </Row>
        </CardContent>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => ({
  testCaseData: state.auth.testCaseObject
});

const mapDispatchToProps = {
  createTestCase
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTestCases);