import {
    GET_SUITE_JOB
      } from "../actions/types";
      
    
      const initialState = {
        SuiteJobList: null,
      };
      
      const SuiteReport = (state = initialState, action) => {
        const { type, payload } = action;
      
        switch (type) {
          
            case GET_SUITE_JOB:
              return {
                ...state,
                SuiteJobList: payload,
            };
        
          default:
            return state;
        }
      };
      export default SuiteReport;
      