import { SCHEDULAR_REPORT_LIST } from "../actions/types";

const initialState = {
  Schedule_list: null,
};

const SchedularJob = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SCHEDULAR_REPORT_LIST:
      console.log("Schedular reducer",payload)
      return {
        ...state,
        Schedule_list: payload,
      };

    default:
      return state;
  }
};

export default SchedularJob;
