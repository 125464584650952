import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { connect } from "react-redux";

function ExportExcel({ type , data , testData}) {
  
  const [list, setList] = useState([]);
  const [name,setName]=useState('')
  
  useEffect(() => {
      setList(data);
      exportToExcel(data);
  },[data]);

  const exportToExcel = (data) => {
    if (data.length === 0) {
      let excelFormat = []
      let obj ; 
      setName(testData.case_name)
      if (type === 'attribute') {
        obj = {
          'Step Name': null,
          'Step Id': null,
          'Attribute Id': null,
          'XPath': null,
          'Value': null,
          'Sleep time' : null
        }
      } else if (type === 'testStep' ) {
        obj = {
          'Step ID': null,
          'Step Name': null,
          'Step Action Type': null,
          'Priority': null,
          'Test ID': testData?.case_id
        }
      } else if (type === 'validation') {
        obj = {
          'Step Name': null,
          'Validation ID': null,
          'XPath': null,
          'Value': null,
          'Step Id': null
        }
      }
      excelFormat.push(obj);
      const ws = XLSX.utils.json_to_sheet(excelFormat);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, `${name}_${type}_.xlsx`);
    }

    if (data && data.length > 0) {
      let headers;
      let wsData;
      if(type === 'testStep' ){
         headers = ['Step ID', 'Step Name', 'Step Action Type', 'Priority', 'Test ID'];
         wsData = [headers, ...data.map(item => [item.step_id, item.step_name, item.step_action_type, item.priority, item.test_id])];  
      }if(type === 'validation'){
         headers = ['Validation ID', 'Step Id', 'Step Name', 'XPath', 'Value'];
         wsData = [headers, ...data.map(item => [item.valid_id, item.test_step_id, item.step_name, item.valid_xpath, item.valid_expectedvalue])];  
   
      }if (type === 'attribute') {
         headers = ['Attribute Id', 'Step Id', 'Step Name', 'XPath', 'Value', 'Sleep time'];
         wsData = [headers, ...data.map(item => [item.attr_id, item.step_id, item.step_name, item.attr_xpath, item.attr_value , item.attr_sleeptime])];  
      }
      
      const ws = XLSX.utils.aoa_to_sheet(wsData);
      
     
      ws['!cols'] = headers.map(() => ({ wch: 20 }));
      ws['!rows'] = [{ hpt: 20 }];
      
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, `${testData.case_name}_${type}.xlsx`);
    }
  };

  return (
    <div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  attributeList: state.exportImport.attributesListForExport,
  validationList: state.exportImport.validationsListForExport,
  testStepList: state.exportImport.testStepListForExport
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportExcel);
