import { GET_CRONTAB_LIST, CREATE_CRONTAB, UPDATE_CRONTAB , DELETE_CRONTAB} from "../actions/types";
    
      const initialState = {
        crontabList: null,
      };
      
      const cronTab = (state = initialState, action) => {
        const { type, payload } = action;
      
        switch (type) {
          
            case GET_CRONTAB_LIST:
              return {
                ...state,
                crontabList: payload,
            };
        
            case CREATE_CRONTAB:
                return {
                  ...state,
                  isCrontabCreated: payload,
              };

              case UPDATE_CRONTAB:
                return {
                  ...state,
                  isCrontabUpdated: payload,
              };

              case DELETE_CRONTAB:
                return {
                  ...state,
                  isCrontabDeleted: payload,
              };
          default:
            return state;
        }
      };
      export default cronTab;
      