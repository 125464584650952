import { CREATE_AI_TEST } from "./types";
import AITestService from "../../services/aiTest.service";
import { toast } from 'react-toastify';


export const createAITest = (object) => async (dispatch) => {
    try {
        let res = await AITestService.createAITest(object);
        if (res.status === 200) {
            dispatch({
                type: CREATE_AI_TEST,
                payload: res.data
            });
            toast.success("AI test created successfully");
        } else {
            toast.error("Unable to create AI test " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};


