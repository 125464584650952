import { GET_SUITE_JOB } from "./types";
import SuiteJobService from "../../services/suiteReport.service";
import { toast } from 'react-toastify';

export const getSuiteJobs = () => async(dispatch) => {
  try {
    let res = await SuiteJobService.getSuiteJobList();
     if(res.status === 200){
      dispatch({
        type : GET_SUITE_JOB,
        payload : res.data
      });
    } else {
      toast.error("Unable to fetch suite jobs : " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const getSuiteReport = (obj) => async(dispatch) => {
  try {
    let res = await SuiteJobService.getDetailedSuiteReport(obj);
     if(res.status === 200){
       return res.data
    } else {
      toast.error("Unable to fetch suite report : " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};


