import React from 'react';
import { GridOverlay } from '@mui/x-data-grid';
import { Typography, Box } from '@mui/material';

function CustomNoRowsOverlay() {
  return (
    <GridOverlay>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Typography variant="h6" component="div" gutterBottom>
          No Data Available
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Please check back later or adjust your filters.
        </Typography>
      </Box>
    </GridOverlay>
  );
}

export default CustomNoRowsOverlay;
