import * as React from 'react';
import { createSuites, deleteSuites, getSuitesList, updateSuites } from "../redux/actions/suites";
import { getProjects } from "../redux/actions/auth";
import { connect } from "react-redux";
import { Card, CardContent, Button, MenuItem, Select , Typography , Tooltip} from '@mui/material';
import { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import Divider from "@mui/material/Divider";
import '../styles/project.css';
import '../styles/common.css';
import SettingsIcon from '@mui/icons-material/Settings';
import validationRules from '../components/ValidationRules';
import ErrorBox from '../components/ErrorBox';
import validateForm from '../components/Validate';
import ServerPaginationGridNoRowCount from "../components/Table/DataGrid";
import extractSortAndFilterParams from '../components/Table/filterSortParams';
import Search from '../components/Search'

export const Suites = ({ getSuitesList, suitesList, listOfProject, getProjects, createSuites, updateSuites, deleteSuites, suiteCount }) => {
  const initialValues = {
    name: '',
    proj_id: ''
  };
  const fields = [
    { name: 'name', label: 'Suit Name', type: 'text', validations: [validationRules.required] },
    { name: 'proj_id', label: 'Project Selection', type: 'select', validations: [validationRules.required] }
  ];
  const [suiteName, setName] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [projectDetails, setProjectDetails] = useState('');
  const [open, setOpen] = React.useState(false);
  const [showEditButton, setShowEditButton] = useState(false);
  const [selectedProject, setSelectedProject] = useState('');
  const navigate = useNavigate();
  const [selectedActions, setSelectedActions] = useState([]);
  const [suitesDetails, setSuitesDetails] = useState({});
  const [touched, setTouched] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [globalError, setGlobalError] = useState('');
  const [tableHeight, setTableHeight] = useState(400);
  const rowHeight = 52;
  const updateTableHeight = (pageSize) => {
    const headerHeight = 56; // Approximate header height (adjust based on your actual header height)
    const footerHeight = 56; // Approximate footer height (adjust based on your actual footer height)
    const newHeight = headerHeight + footerHeight + rowHeight * pageSize;
    setTableHeight(newHeight);
  };

  const OPTIONS = [
    { key: 'EDIT', value: 'Edit' },
    { key: 'TEST_CASES', value: 'Test cases' },
    { key: 'RUN_SUITES', value: 'Run Suites' },
    { key: 'DELETE', value: 'Delete' }
  ]
  const [paginationModals, setPaginationModals] = useState({
    page: 0,
    pageSize: 10,
  });
  const [sortModels, setSortModels] = useState([]);
  const [filterModels, setFilterModels] = useState({ items: [] });
  const columns = [
    {
      field: "id",
      headerName: "Index",
    },
    {
      field: "suite_name",
      headerName: "Suite Name",
    },
    {
      field: "proj_name",
      headerName: "Project",
    },
    {
      field: "count",
      headerName: "Total Cases",
    },
    {
      field: "aciton",
      headerName: "Actions",
      renderCell: (params) => (
        <>
          <Select startAdornment={<SettingsIcon style={{ color: '#9b9b9b' }} />} sx={{ height: '40px' }} value={selectedActions[params.row.id] || ''} onChange={(event) => handleActionsChange(event, params.row.id, params.row)} >
            {OPTIONS?.map((option, index) => (
              <MenuItem value={option.key} key={index}
                disabled={option.key === 'RUN_SUITES' && params.row.count == 0} >
                {option.value}
              </MenuItem>
            ))}
          </Select>
        </>
      ),
    },
  ];
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const [width, setWidth] = useState(100 / columns.length + '% !important');
  useEffect(() => {
    const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
    getSuitesList(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort, JSON.stringify(searchQuery));
    getProjects(0, 0, null, null, JSON.stringify({}));
  }, [paginationModals, sortModels, filterModels,searchQuery]
  )

  const validate = () => {
    const { newErrors, globalError } = validateForm(values, fields);
    setErrors(newErrors);
    setGlobalError(globalError);
    return Object.keys(newErrors).length === 0;
  };
  const handleActionsChange = (event, rowIndex, row) => {
    const newSelectedActions = [...selectedActions];
    newSelectedActions[rowIndex] = event.target.value;
    setSelectedActions(newSelectedActions);
    if (newSelectedActions[rowIndex] === 'EDIT') {
      setOpen(true);
      setShowEditButton(true);
      let valueObj = {
        id: row.suite_id,
        name: row.suite_name,
        proj_id: row.proj_id
      }
      // setName(row.suite_name);
      // console.log("====>",row);
      setValues(valueObj);
      setSuitesDetails(row);
      setSelectedProject(row.proj_id);
    } if (newSelectedActions[rowIndex] === 'DELETE') {
      deleteSuitesById(row);
    } if (newSelectedActions[rowIndex] === 'TEST_CASES') {
      navigate(`/view-cases`, { state: row });
    } if (newSelectedActions[rowIndex] === 'RUN_SUITES') {
      navigate(`/run-suites/${row.suite_id}`, { state: row })
    }
    setSelectedActions([]);
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleOpen = () => {
    setName('');
    setSelectedProject('');
    setShowEditButton(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChangesuiteName = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  }

  const create = () => {
    //   if(suiteName === ''){
    //     setTouched(true);
    //  return;
    //   }
    const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
    if (validate()) {
      setShowEditButton(false);
      // const obj = {
      //   name: suiteName,
      //   proj_id: selectedProject
      // }
      createSuites(values)
        .then(() => {
          getSuitesList(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort, JSON.stringify(searchQuery));
          handleClose();
        });
      setOpen(false);
    }
  }

  const updateSuitesData = () => {
    const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
    if (validate()) {
      // setValues({...values, ['id']: suitesDetails.suite_id});
      // const updatedObj = 
      //   {   
      //     id: suitesDetails.suite_id,
      //     name: suiteName,
      //     proj_id: selectedProject
      //   }
      setProjectDetails(suitesDetails.proj_id);
      updateSuites(values);
      getSuitesList(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort, JSON.stringify(searchQuery));
      setOpen(false);
    }
  }

  const deleteSuitesById = (row) => {
    const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
    deleteSuites(row.suite_id)
      .then(() => {
        getSuitesList(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort, JSON.stringify(searchQuery));
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }
  suitesList = suitesList?.map((item, index) => {
    return { ...item, id: index + 1 + (paginationModals.page * paginationModals.pageSize) }
  });
  useEffect(() => {
    updateTableHeight(suitesList?.length);
  }, [paginationModals.pageSize, suitesList]);
  return (
    <div>
     <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className="modalContainer">
        <Box
          component="div"
          className="modalContent"
          noValidate
          autoComplete="off"
        >
          {/* Header */}
          <div className="modal-header">
            <h3 className='label-bold'>
              {!showEditButton ? 'Create Suite' : 'Update Suite'}
            </h3>
          </div>
          {/* Content */}
          <div className="modal-body">
            {globalError && <ErrorBox message={globalError} onClose={() => setGlobalError('')} />}
            <div className="modalForm">
              {fields.map((field) => {
                if (field.type == 'text') {
                  return (
                    <div className="modalFormItem">
                      <label><b>Suite:</b></label><br></br>
                      <TextField
                        type={field.type}
                        name={field.name}
                        value={values[field.name]}
                        onChange={onChangesuiteName}
                      />
                    </div>
                  )
                } else {
                  return (
                    <div className="modalFormItem">
                      <label><b>Project</b></label><br></br>
                      <Select onChange={handleSelectChange} sx={'width: 280px'} name={field.name} value={values[field.name]}>
                        {listOfProject?.map((option, index) => (
                          <MenuItem value={option.proj_id} key={index}>{option.proj_name}</MenuItem>
                        ))}
                      </Select>
                    </div>
                  )
                }
              })}
            </div>
          </div>
          {/* Footer */}
          <div className="modal-footer">
            {!showEditButton ? <Button onClick={create}>Create Suite</Button> : <Button onClick={updateSuitesData} className="modalButton">Update</Button>}
            <Button colorStyle='cancel' onClick={handleClose} >Close</Button>
          </div>
        </Box>
      </Modal>
      <Card
        raised
        sx={{
          minWidth: "100%",
          overflowX: "auto" 
        }}>
        {/* <Typography variant="h5" sx={{
          p: 2
        }}>
          Suite List
          <Tooltip title="Add Suites"><Button className='right'  size="small" onClick={handleOpen}> + Add Suites</Button></Tooltip>
        </Typography> */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
          <Typography variant="h5">
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <label style={{ marginRight: '20px' }}>Suite List</label>

              <Box sx={{ flexGrow: 1 }} /> {/* This will push the Search and Button to the right */}
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Search onChangeQuery={handleSearchChange} value={searchQuery} />
                <Tooltip title="Add Suites"><Button className='right' size="small" onClick={handleOpen}> + Add Suites</Button></Tooltip>
              </Box>
            </Box>
          </Typography>
        </Box>



        <Divider></Divider>

        <CardContent>
          <div style={{ height: tableHeight, width: '100%' }}>
          {/* <Search className='right' onChangeQuery={handleSearchChange} value={searchQuery} />  */}

            <ServerPaginationGridNoRowCount
              setPaginationModals={setPaginationModals}
              paginationModals={paginationModals}
              rowsData={suitesList && suitesList.length ? suitesList : []}
              total={suiteCount && suiteCount ? suiteCount : 0}
              columns={columns}
              columnWidth={width}
              setSortModels={setSortModels}
              sortModels={sortModels}
              setFilterModels={setFilterModels}
              filterModels={filterModels}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => ({
  suitesList: state.suites?.suitesList?.rows,
  suiteCount: state.suites?.suitesList?.count,
  listOfProject: state.auth?.listProject?.rows
});

const mapDispatchToProps = {
  getSuitesList,
  updateSuites,
  deleteSuites,
  createSuites,
  getProjects
};

export default connect(mapStateToProps, mapDispatchToProps)(Suites);



