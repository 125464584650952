import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";

class SchedularService {
    constructor() {
        this.headers = createHeaders();
    }

   async getSchedularReport(obj) {
    try {
        const url = `${ENV_VAR.API_HOST}/api/v1/scheduleReports/`;
        const response = await axios.post(url, obj , this.headers);
        return response;
    }
    catch (error) {
        throw error.response.data.error;
    }
  }
}

export default new SchedularService();