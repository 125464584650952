import { GET_TESTCASE , CREATE_TESTCASE , DELETE_TEST_STEPS, CLEAR_VALIDATIONS, 
  CLEAR_ATTRIBUTES,CLEAR_TESTCASE_INFO, GET_ATTRIBUTES, UPDATE_ATTRIBUTES, DELETE_ATTRIBUTES,
  GET_ALL_TESTCASES ,GET_TEST_CASE_BY_ID, GET_VALIDATIONS , UPDATE_TEST_STEP , CLEAR_TEST_STEP_LIST, UPDATE_TESTCASE, DELETE_TEST_CASE, UPDATE_FILE_IN_TEST_STEP } from "./types";
import testcasesService from "../../services/testcases.service";
import testStepsService from "../../services/testSteps.service";
import attributeService from "../../services/attribute.service";
import validationService from "../../services/validation.service";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const testCaseList = (projectId) => async(dispatch) => {
  try {
    let res = await testcasesService.getTestCases(projectId);
     if(res.status === 200){
      dispatch({
        type : GET_TESTCASE,
        payload : res.data
      });
    } else {
      toast.error("Failed to fetch testcases. Server returned status: " + res.status);
    }
  } catch (error) {
    toast.error("An error occurred: " + error);
  }
};

export const getAllTestCases = (page, pageSize, sortColumn, sortDirection,filters,type) => async(dispatch) => {
  try {
    let res = await testcasesService.getAllTestCases(page, pageSize, sortColumn, sortDirection,filters,type);
     if(res.status === 200){
      dispatch({
        type : GET_ALL_TESTCASES,
        payload : res.data
      });
    } else {
      toast.error("Failed to fetch testcases. Server returned status: " + res.status);
    }
  } catch (error) {
    toast.error("An error occurred: " + error);
  }
};

export const getTestCaseForDropdown = (page, pageSize, sortColumn, sortDirection,filters,type) => async(dispatch) => {
  try {
    let res = await testcasesService.getAllTestCases(page, pageSize, sortColumn, sortDirection,filters,type);
     if(res.status === 200){
       return res.data;
    } else {
      toast.error("Failed to fetch testcases. Server returned status: " + res.status);
    }
  } catch (error) {
    toast.error("An error occurred: " + error);
  }
};


export const getTestcasesById = (id) => async(dispatch) => {
  try {
    dispatch({
      type : CLEAR_TESTCASE_INFO
    })
    let res = await testcasesService.getTestCaseById(id);
     if(res.status === 200){
      dispatch({
        type : GET_TEST_CASE_BY_ID,
        payload : res.data
      });
    } else {
      toast.error("Failed to fetch testcase list Server returned status: " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const createTestCase = (object) => async(dispatch) => {
  try {
    dispatch({
      type : CLEAR_TESTCASE_INFO
    })
    let res = await testcasesService.createNewTestcase(object);
     if(res.status === 200){
      dispatch({
        type : CREATE_TESTCASE,
        payload : res.data
      });
      toast.success("Test case created successfully");
    } else {
      toast.error("Failed to create testcase. Server returned status: " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
}

export const updateTestCase = (object) => async (dispatch) => {
  try {
    let res = await testcasesService.updateTestCase(object);
    if (res.status === 200) {
      dispatch({
        type: UPDATE_TESTCASE,
        payload: res.data
      });
      toast.success("Test case updated successfully");
    } else {
      toast.error("Failed to update testcase. Server returned status: " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const deleteTestCaseById = (obj) => async (dispatch) => {
  try {
    let res = await testcasesService.deleteTestCase(obj);
    if (res.status === 200) {
      dispatch({
        type: DELETE_TEST_CASE,
        payload: res.data
      });
      toast.success("Test case deleted successfully");
    } else {
      toast.error("Failed to delete testcase. Server returned status: " + res.status);
    }
  } catch (error) {
    toast.error("An error occurred: " + error);
  }
};

// -------------------------- Test Steps API --------------------------------

  export const createTestSteps = (object) => async(dispatch) => {
    try {
      let res = await testStepsService.createNewTestStep(object);
       if(res.status === 200){
        return res;
      } else {
        toast.error("Failed to create test step Server returned status: " + res.status);
      }
    } catch (error) {
      toast.error(error);
    }
  };

export const getTestStepsList = (id) => async(dispatch) => {
  try {
    dispatch({
      type : CLEAR_TEST_STEP_LIST
    })
    let res = await testStepsService.getTestStepById(id);
     if(res.status === 200){
     return res;
     }
  } catch (error) {
    toast.error(error);
  }
};

export const updateTestSteps = (obj) => async(dispatch) => {
  try {
    let res = await testStepsService.updateTestStep(obj);
     if(res.status===200){
      dispatch({
        type : UPDATE_TEST_STEP,
        payload : res.data
      });
    } else {
      toast.error("Failed to update test step Server returned status: " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const updateFile = (obj) => async(dispatch) => {
  try {
    let res = await testStepsService.updateFile(obj);
     if(res.status===200){
      dispatch({
        type : UPDATE_FILE_IN_TEST_STEP,
        payload : res.data
      });
      toast.success("File added successfully to the step");
    } else {
      toast.error("Failed to update test step Server returned status: " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const addUseData = (obj) => async(dispatch) => {
  try {
    let res = await testStepsService.addUseData(obj);
     if(res.status === 200){
      toast.success("References for store data added succesfully ");
    } else {
      toast.error("Failed to update test step Server returned status: " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const deleteTestStep = (id) => async(dispatch) => {
  try {
    let res = await testStepsService.deleteTestStepById(id);
     if(res.status===200){
      dispatch({
        type : DELETE_TEST_STEPS,
        payload : res.data
      });
      toast.success("Test step deleted successfully");
    } else {
      toast.error("Failed to delete test step Server returned status: " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

// -------------------- Test Attributes API's--------------------

export const createAttributes = (object) => async(dispatch) => {
  try {
    let res = await attributeService.createAttribute(object);
     if(res.status === 200){
      return res;
    }
  } catch (error) {
    toast.error(error);
  }
};


export const getAttributes = (object) => async(dispatch) => {
  try {
    dispatch({
      type : CLEAR_ATTRIBUTES
    })
    let res = await attributeService.getAllAttributes(object);
     if(res.status===200){
      dispatch({
        type : GET_ATTRIBUTES,
        payload : res.data
      });
    } else {
      toast.error("Unable to fetch attributes returned status: " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};


export const updateAttribute = (object) => async(dispatch) => {
  try {
    let res = await attributeService.updateAttributes(object);
     if(res.status===200){
      dispatch({
        type : UPDATE_ATTRIBUTES,
        payload : res.data
      });
    } else {
      toast.error("Unable to update attributes returned status: " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const deleteAttribute = (object) => async(dispatch) => {
  try {
    let res = await attributeService.deleteAttributeById(object);
     if(res.status===200){
      dispatch({
        type : DELETE_ATTRIBUTES,
        payload : res.data
      });
      toast.success("Test attribute deleted successfully");
    } else {
      toast.error("Unable to delete attributes returned status: " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

//----------------------- Test steps Validation API's ----------------------------


export const createValidations = (object) => async(dispatch) => {
  try {
    let res = await validationService.createValidation(object);
     if(res.status===200){
      return res;
    } else {
      toast.error("Unable to create validation returned status: " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};


export const getValidations = (object) => async(dispatch) => {
  try {
    dispatch({
      type : CLEAR_VALIDATIONS
    })
    
    let res = await validationService.getAllValidations(object);
     if(res.status===200){
      dispatch({
        type : GET_VALIDATIONS,
        payload : res.data
      });
    } else {
      toast.error("Unable to fetch validations returned status: " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};


export const updateValidations = (object) => async(dispatch) => {
  try {
    let res = await validationService.updateValidations(object);
     if(res.status===200){
      dispatch({
        type : UPDATE_ATTRIBUTES,
        payload : res.data
      });
    } else {
      toast.error("Unable to update validation returned status: " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};


export const deleteValidations = (object) => async(dispatch) => {
  try {
    let res = await validationService.deleteValidationById(object);
     if(res.status===200){
      dispatch({
        type : DELETE_ATTRIBUTES,
        payload : res.data
      });
      toast.success("Test validation deleted successfully");
    } else {
      toast.error("Unable to delete validation returned status: " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};
