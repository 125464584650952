import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";

class CeleryService {
    constructor() {
        this.headers = createHeaders();
    }

    restartCelery = async (org_name) => {
        try {
            
            const url = `${ENV_VAR.API_HOST}/api/v1/Celery`;
           const response = await axios.post(url, {
            Celery_name: org_name || null,
            }, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }
  
}

export default new CeleryService();