import { Bar} from "react-chartjs-2";
import { useEffect,useState } from "react";
import { Chart } from "chart.js";

import {
    CategoryScale,
    LinearScale,
    BarController,
    LineController,
    Title,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Tooltip,
  } from "chart.js";

  Chart.register(
    CategoryScale,
    LinearScale,
    BarController,
    LineController,
    Title,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Tooltip
  );

function BarChart({Data}){

    const [label,setLabel]=useState([]);
    const [pass ,setPass ]=useState([]);
    const [fail ,setFail ]=useState([]);
    const [stop ,setStop ]=useState([]);

    

    useEffect(()=>{
        var bar_label = [];
        var bar_passed = [];
        var bar_fail = [];
        var bar_stopped = [];

        for (let item in Data) {
            let pass_count = 0;
            let fail_count = 0;
            let stop_count = 0;
            for (let item2 in Data[item].sel_logs) {
              let obj = Data[item].sel_logs[item2];
              bar_label.push(obj.case_name);
        
              if (obj.test_status === "success") {
                pass_count = obj.total_steps;
              } else if (obj.test_status === "failure") {
                for (let sel in obj.seljoblogs) {
                  if (obj.seljoblogs[sel].log_type === "step") {
                    if (obj.seljoblogs[sel].status === "success") {
                      pass_count = pass_count + 1;
                    } else if (
                      obj.seljoblogs[sel].status === "failure" ||
                      obj.seljoblogs[sel].status === "error"
                    ) {
                      fail_count = fail_count + 1;
                    } else {
                      stop_count = stop_count + 1;
                    }
                  }
                }
              } else {
                let total = obj.total_steps
                for (let sel in obj.seljoblogs) {
                  if (obj.seljoblogs[sel].log_type === "step") {
                    if (obj.seljoblogs[sel].status === "success") {
                      pass_count = pass_count + 1;
                    } else if (
                      obj.seljoblogs[sel].status === "failure" ||
                      obj.seljoblogs[sel].status === "error"
                    ) {
                      fail_count = fail_count + 1;
                    } else {
                      stop_count = stop_count + 1;
                    }
                  }
                }
                stop_count += total - (pass_count + fail_count + stop_count);
              }
        
              bar_passed.push(pass_count);
              bar_fail.push(fail_count);
              bar_stopped.push(stop_count);
              pass_count = 0;
              fail_count = 0;
              stop_count = 0;
            }
            break;
        }
        setLabel(bar_label);
        setPass(bar_passed);
        setFail(bar_fail);
        setStop(bar_stopped);

    },[Data])

    const data = {
        labels: label,
        datasets: [
          {
            label: "Passed",
            data: pass,
            backgroundColor: "rgba(0, 255, 0, 0.9)",
          },
          {
            label: "Failed",
            data: fail,
            backgroundColor: "rgba(255, 0, 0, 0.9)",
          },
          {
            label: "Stopped",
            data: stop,
            backgroundColor: "rgba(255, 234, 0, 1)",
          },
        ],
      };
    
      const option = {
        indexAxis: "y",
        maintainAspectRatio: true,
        scales: {
          x: {
            stacked: true,
            ticks: {
              beginAtZero: true,
              autoSkip: false,
              maxTicksLimit: 5,
              fontSize: 12,
              color: "#6B778C",
            },
          },
          y: {
            stacked: true,
            display:false,
            grid: {
              display: true,
              drawBorder: false,
              color: "#F0F0F0",
              zeroLineColor: "#F0F0F0",
            },
            ticks: {
              beginAtZero: true,
              autoSkip: false,
              maxTicksLimit: 5,
              fontSize: 12,
              color: "#6B778C",
            },
          },
        },
      };

    


    return(
        <Bar data={data} options={option}/>
    )

}


export default BarChart