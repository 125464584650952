import { ASSIGN_PROJECTS_TO_USERS, GET_PROJECTS_FOR_USERS, GET_ALL_USERS_LIST, DELETE_PROJECTS_BY_USER_ID, CLEAR_PROJECT_LIST_BY_USER } from "./types";
import assignUserService from "../../services/assignUsers.service";
import { toast } from 'react-toastify';

export const getAllUsersProjects = (object) => async (dispatch) => {
  try {
    let res = await assignUserService.getAllProjects(object);
    if (res.status === 200) {
      dispatch({
        type: GET_PROJECTS_FOR_USERS,
        payload: res.data
      });
    } else {
      toast.error("Unable to fetch projects : " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const assignProjectToUsers = (object) => async (dispatch) => {
  try {
    let res = await assignUserService.assignProjectToUser(object);
    if (res.status === 200) {
      dispatch({
        type: ASSIGN_PROJECTS_TO_USERS,
        payload: res.data
      });
    } else {
      toast.error("Unable to assign project to user" + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const updateProjectAssignment = (object) => async (dispatch) => {
  try {
    let res = await assignUserService.updateProjectAssignmentToUsers(object);
    if (res.status === 200) {
      dispatch({
        type: ASSIGN_PROJECTS_TO_USERS,
        payload: res.data
      });
      toast.success("Project assigned successfully");
    } else {
      toast.error("Unable to assign project : " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const getAllUsersList = (object) => async (dispatch) => {
  try {
    let res = await assignUserService.getAllUsers(object);
    if (res.status === 200) {
      dispatch({
        type: GET_ALL_USERS_LIST,
        payload: res.data
      });
    } else {
      toast.error("Unable to get user list : " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const getProjectsByUserId = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_PROJECT_LIST_BY_USER
    })
    let res = await assignUserService.getProjectListUserWise(id);
    if (res.status === 200) {
      // dispatch({
      //   type: GET_PROJECTS_BY_USER_ID,
      //   payload: res.data
      // });
       return res.data;
    } else {
      toast.error("Unable to fetch projects: " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const deleteProjectsByUserId = (id) => async (dispatch) => {
  try {
    let res = await assignUserService.deleteProjectsUserWise(id);
    if (res.status === 200) {
      dispatch({
        type: DELETE_PROJECTS_BY_USER_ID,
        payload: res.data
      });
    } else {
      toast.error("Unable to delete project : " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};