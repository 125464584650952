import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";

class profileService {
    constructor() {
        this.headers = createHeaders();
     }

    getCurrentUserDetails = async () => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/user/auth_user`;
            const response = await axios.get(url,this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    changePassword = async (obj) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/user/changepassword`;
            const response = await axios.post(url,obj,this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    uploadProfilePicture = async (obj) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/user/uploadprofilepic`;
            const response = await axios.post(url,obj,this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }
}

export default new profileService();