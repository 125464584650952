import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import testImage from '../../Assets/GirikonLogo.jpg'
import { useNavigate } from 'react-router-dom';
import { useState, useEffect} from 'react';
import { resetToken } from "../../redux/actions/auth";
import { connect } from "react-redux";
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://girikon.ai/">
        Girikon AI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const ResetPassword = ({ resetToken , auth}) => {
const navigate = useNavigate();
const defaultTheme = createTheme();
const [password, setPassword] = useState('');
let { token, email } = useParams();


useEffect(() => {
  if (localStorage.getItem("token")) {
    navigate('/dashboard');
  } 
}, [localStorage.getItem("token")]);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  
  const handleSubmit = (event) => {
    let obj = {
      token : token,
      password : password
    }
    resetToken(obj).then((res) => {
      navigate('/signIn');
      if(res.status === 200){
        toast.success("Password has been reset successfully please log in to check")
      }
    })
    .catch((error) => {
        console.error('Error:', error);
    });
  };

  
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
          }}
         >
          <img src={testImage}   
          style={{
            width : '150px',
            height: '50px',
            marginBottom: 15
          }}/>
         
          <Typography component="h6" variant="h5" style={{ color: 'grey', fontSize: '24px' }}>
           Reset Password
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Username"
              name="email"
              autoComplete="email"
              value={email}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="New password"
              label="New password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handlePasswordChange}
              autoFocus
            />
          </Box>
          <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={() => handleSubmit()}
              sx={{ mt: 3, mb: 2 }}
            > 
             Reset Password
            </Button>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => ({
  
});

const mapDispatchToProps = {
  resetToken
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

