import { CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button ,Modal,Link ,Tooltip, Typography , Card} from '@mui/material';
import { getSuiteJobs } from "../redux/actions/suiteReport";
import  {Link as RouterLink}  from "react-router-dom";
import Box from '@mui/material/Box';
import { connect } from "react-redux";
import { useEffect,useState } from "react";
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/custom.css';
import '../styles/common.css';
import Divider from "@mui/material/Divider";
import DeleteIcon from '@mui/icons-material/Delete';
import StopIcon from '@mui/icons-material/Stop';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import {modalPopUpStyleLarge } from '../styles/getLPTheme';
import DateFormatter from '../components/DateFormatter';
import SuiteJobService from '../services/suiteReport.service'
import ENV_VAR from "../common";
import { toast } from 'react-toastify';
import showConfirmationAlert from "../components/Confirm";



export const SuiteCaseJobs = ({ getSuiteJobs , listOfSuiteJob}) =>{
const [video,setVideo]=useState('')
const [open_video, setOpenVideo] = useState(false);
const [token, setToken] = useState(localStorage.getItem("token"));

const navigate = useNavigate();

  useEffect(() => {
    getSuiteJobs();
    
  },[] )

  const navigateToSuiteTestCase = (row) => {
    navigate('/SuiteTestCaseReport', { state : row});
  }

  const navigateToDetailSuiteReport = ()  => {
    navigate('/SuiteDetailReport');
  }
  const updatePopUpVideo = (video) => {
    setOpenVideo(true);
    setVideo(video)
  }
  const handleVideoClose = () => {
    setOpenVideo(false);
  };
  const deleteJob = (row) => {
    showConfirmationAlert('Are you sure you want to Delete ?')
    .then((result) => {
    if (result.isConfirmed) {
      SuiteJobService.deleteSuiteJob(row.id).then((res) => {
        console.log("Response",res.data.message)
        toast.success(res.data.message)
        getSuiteJobs();
      }).catch((error) => {
        console.error('Error:', error);
        });
    }
  });

  }

  const tableRows = listOfSuiteJob?.map((row, index) => (
    <TableRow className='td'>
      <TableCell>{row.suite_name}</TableCell>
      <TableCell>{row.total_test_cases}</TableCell>
      <TableCell><DateFormatter date={row.execution_time}/></TableCell>
      <TableCell><DateFormatter date={row.completion_time}/></TableCell>
      <TableCell>
                  {row.status === 'running' ? (<div className="badge badge-opacity-warning text-capitalize">{row.status}</div>) :
                  row.status === 'stopped' ? (<div className="badge badge-opacity-warning text-capitalize">{row.status}<StopIcon style={{ color: '#FF0000' }} /></div> ): 
                  row.status === 'error' ? (<div className="badge badge badge-danger text-capitalize">{row.status}<ErrorIcon style={{ color: '#B22222' }} /></div>) :
                  row.status === 'failure' ? (<div className="badge badge-danger text-capitalize">{row.status}<ErrorIcon style={{ color: '#B22222' }} /></div>) : 
                  (<div className="badge badge-opacity-success text-capitalize">{row.status}<CheckCircleIcon style={{ color: '#008000' }} /></div>)} 
        </TableCell>
      <TableCell><Link onClick={ () => (navigateToSuiteTestCase(row))}>View Details</Link></TableCell>
      <TableCell>
      {row.status !== 'running' && (
        <TableCell>
          {row.video_url && (
            <RouterLink onClick={() => updatePopUpVideo(row)} title="Play">
              <SmartDisplayIcon style={{color:'red', cursor:'pointer'}}></SmartDisplayIcon>
            </RouterLink>
          )}
        </TableCell>
        
      )}
        </TableCell>
        {row.status !== 'running' &&(
        <TableCell>
          <Tooltip title="Delete">
              <DeleteIcon style={{ color: '#d71b1b' }} onClick={() => deleteJob(row)} className="action-icon clickIconClassDelete" />
          </Tooltip>
      </TableCell>)}
    </TableRow>
    
  ));


  return (
    <div> 
    <Card
        raised
        sx={{
          minWidth: "100%",
          overflowX: "auto"
        }}>
        <Typography variant="h5" sx={{
          p: 2   
        }}>
          Suite Report
         <Button className='right' onClick={ () => (navigateToDetailSuiteReport())}>Detail Reports</Button> </Typography>
        <Divider></Divider>
      <CardContent className='marginTop'>
      <TableContainer className='dashboard-table  TableCell table-responsive '>
        <Table >
          <TableHead >
            <TableRow className=' th'>
              <TableCell >Suite Name</TableCell>
              <TableCell >Total Cases</TableCell>
              <TableCell >Execution Time</TableCell>
              <TableCell >Completion Time</TableCell>
              <TableCell >Status</TableCell>
              <TableCell >Details</TableCell>
              <TableCell >Video</TableCell>
              <TableCell >Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="td">
            {tableRows}
          </TableBody>
        </Table>
        <Modal
        open={open_video}
        onClose={handleVideoClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className="modalContainer">
        <Box
           sx={{
            ...modalPopUpStyleLarge, '& > :not(style)': { m: 1 },
        }}
        noValidate
        autoComplete="off">
         
          <div className="modal-header">
          <video controls style={{ maxWidth: '100%' }}>
                <source src={`${process.env.REACT_APP_API_URL}/api/v1/getVideo/${video.id}?token=${token}`} type="video/mp4" />
              </video>
          </div>

          <div className="modal-footer">
            <Button onClick={handleVideoClose} className="modalButton modalCloseButton">Close</Button>
          </div>
        </Box>
      </Modal>
      </TableContainer>
      </CardContent>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => ({
  listOfSuiteJob : state.suiteReport?.SuiteJobList,
});

const mapDispatchToProps = {
  getSuiteJobs,
  
};

export default connect(mapStateToProps, mapDispatchToProps)(SuiteCaseJobs);