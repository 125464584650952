import {  SCHEDULAR_LIST,
  CREATE_SCHEDULAR,
  UPDATE_SCHEDULAR,
  DELETE_SCHEDULAR,} from "../actions/types";

const initialState = {
  listSchedule: null,
  
};

const JobSchedular = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    
    case CREATE_SCHEDULAR:
          return {
            ...state,
            isSchedularcreated: true, 
        };
        case SCHEDULAR_LIST:
          return {
            ...state,
            listSchedule: payload,
        };
        case UPDATE_SCHEDULAR:
          return {
            ...state,
            isScheduleUpdated: true,
            user: payload.data,
        };
        
        case DELETE_SCHEDULAR:
          return {
            ...state,
            isDeleted: true, 
        };
        default:
          return state;
  }
};

export default JobSchedular;
