import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import testImage from '../Assets/GirikonLogo.jpg'
import { useNavigate } from 'react-router-dom';
import { useState, useEffect} from 'react';
import { login} from "../redux/actions/auth";
import { connect } from "react-redux";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://girikon.ai/">
        Girikon AI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const SignIn = ({ login , auth}) => {
const navigate = useNavigate();

useEffect(() => {
  if (localStorage.getItem("token")) {
    navigate('/dashboard');
  } 
}, [localStorage.getItem("token")]);


const defaultTheme = createTheme();
const [username, setUsername] = useState('mail@example.com');
const [password, setPassword] = useState('P@$w0rd`');

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    login(username,password);
  };

  const navigateToForgotPassword = () => {
    navigate('/forgot-password');
  } 

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
          }}
         >
          <img src={testImage}   
          style={{
            width : '150px',
            height: '50px',
            marginBottom: 15
          }}/>
         
          <Typography component="h6" variant="h5" style={{ color: 'grey', fontSize: '24px' }}>
            Sign in to continue
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Username"
              name="email"
              autoComplete="email"
              onChange={handleUsernameChange}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handlePasswordChange}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              to="/signIn"
              onClick={handleSubmit}
              sx={{ mt: 3, mb: 2 }}
            > 
            Log In
            </Button>
            
            <Grid container>
              <Grid item xs>
                <Link onClick={() => navigateToForgotPassword()} variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => ({
  usersDataList: state.users,
  auth: state.auth
});

const mapDispatchToProps = {
login
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);

