import {
    STOP_JOB
  }
    from "./types";
import runJobService from "../../services/runJob.service";

export const StopJob = (taskId,type) => async (dispatch) => {
    try {
      let res = await runJobService.stopJob(taskId,type);
      if (res.status === 200) {
        dispatch({
          type: STOP_JOB,
          payload: res.data
        });
      }
    } catch (error) {
      
    }
  };
