import {  SCHEDULAR_LIST,CREATE_SCHEDULAR,UPDATE_SCHEDULAR,DELETE_SCHEDULAR,GET_SCHEDULAR_BY_ID} from "./types";
import JobSchedular from "../../services/schedule.service";

export const createSchedule = (object) => async(dispatch) => {
    try {
      let res = await JobSchedular.createSchedular(object);
       if(res.status === 200){
        dispatch({
          type : CREATE_SCHEDULAR,
          payload : res.data
        })
       }
    } catch (error) {
      console.error("error in login", error)
    }
  };
  
  export const updateSchedule = (obj) => async(dispatch) => {
    try {
      let res = await JobSchedular.updateSchedular(obj);
       if(res.status === 200){
        dispatch({
          type : UPDATE_SCHEDULAR,
          payload : res.data
        })
       }
    } catch (error) {
      console.error("error in login", error)
    }
  };
  
  export const deleteSchedule = (id) => async(dispatch) => {
    try {
      let res = await JobSchedular.deleteSchedularbyid(id);
       if(res.status === 200){
        dispatch({
          type : DELETE_SCHEDULAR,
          payload : res.data
        })
       }
    } catch (error) {
      console.error("error in login", error)
    }
  };

  export const getScheduleList = () => async(dispatch) => {
    try {
      let res = await JobSchedular.getSchedularList();
       if(res.status === 200){
        dispatch({
          type : SCHEDULAR_LIST,
          payload : res.data
        })
       }
    } catch (error) {
      console.error("error in login", error)
    }
  };

  export const getSchedulebyid = (id) => async(dispatch) => {
    try {
      dispatch({
        type : GET_SCHEDULAR_BY_ID
      })
      let res = await JobSchedular.getSchedularbyid(id);
       if(res.status === 200){
        dispatch({
          type : GET_SCHEDULAR_BY_ID,
          payload : res.data
        })
       }
    } catch (error) {
      console.error("error", error)
    }
  };
