import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";

class exportImport {
    constructor() {
        this.headers = createHeaders();
     }

    getAttributeData = async (testId) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/getattributebytestid/${testId}`;
            const response = await axios.get(url,this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    getValidationData = async (testId) => {
        try { 
            const url = `${ENV_VAR.API_HOST}/api/v1/getvalidationbytestid/${testId}`;
            const response = await axios.get(url,this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    getTeststepData = async (testId) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/get_stepby_testid/${testId}`;
            const response = await axios.get(url,this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }
}

export default new exportImport();