import { Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button , Typography , Tooltip} from '@mui/material';
import { testCaseList , createTestCase , deleteTestCaseById } from "../redux/actions/testcase";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { useLocation , useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import DeleteIcon from '@mui/icons-material/Delete';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import * as React from 'react';
import '../styles/custom.css';
import '../styles/common.css';
export const ProjectCases = ({ testCaseList ,testList , updateTestCase , deleteTestCaseById}) =>{
const { state } = useLocation();
const navigate = useNavigate();

  useEffect(() => {
    testCaseList(state?.proj_id);
  }, []
  )

  const navigateToTestScreen = (row) => {
    navigate(`/testcase`, { state : row});
  }

  const deleteTestCase = (row) => {
    let obj = {
      id: row.case_id
    }
    deleteTestCaseById(obj).then(() => {
      testCaseList(state?.proj_id);
    })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const navigateToAddScreen = (row) => {    
    const testCaseObject = {
      testCaseInfo : row,
      projectInfo : state,
      isEditScreen: true
    }
    navigate(`/add-testcases`,{ state : testCaseObject});
  }
  
  const navigateToRunJob = (row) =>{
    navigate(`/runJob`, {state: row});
}

  const tableRows = testList?.map((row, index) => (
    <TableRow key={index}>
      <TableCell> {row.case_name}</TableCell>
      <TableCell> {state.proj_name}</TableCell>
      <TableCell>{row.count}</TableCell>
      <TableCell>NA</TableCell>
      <TableCell>
        <Tooltip title="Run job"><PlayCircleOutlineIcon className="action-icon-brand"  onClick={() => navigateToRunJob(row)} /></Tooltip>
        <Tooltip title="Edit"><EditIcon className="action-icon"  onClick={() => navigateToTestScreen(row)} /></Tooltip> 
        <Tooltip title="Delete"><DeleteIcon className="action-icon-delete"  onClick={() => deleteTestCase(row)}/></Tooltip>
      </TableCell>
    </TableRow>
  ));

  return (
    <div>
      <Card
        raised
        sx={{
          minWidth: "100%",
          overflowX: "auto"  
        }}>
        <Typography variant="h5" sx={{
          p: 2
        }}>
          Test Cases ({state?.proj_name})
          <Button sx={"float: right"} onClick={() => navigateToAddScreen()}> + Add Testcase</Button>
        </Typography>
        <Divider></Divider>
        <CardContent className='marginTop'>
      <TableContainer component={Card} className='dashboard-table table td'>
              <Table>
                <TableHead className='th'>
              <TableRow>
                <TableCell>Test Case Name</TableCell>
                <TableCell>Project Name</TableCell>
                <TableCell>Test Steps</TableCell>
                <TableCell>Is Template</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                {tableRows}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => ({
  testCaseList : state.auth?.testCaseList,
  testList : state.auth?.testList
});

const mapDispatchToProps = {
  testCaseList,
  createTestCase,
  deleteTestCaseById
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCases);