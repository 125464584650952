import {CREATE_SUITES, GET_SUITES , ALL_TEST_LIST_FOR_SUITES ,CREATE_TEST_SUITE_CASE , GET_SUITES_TESTCASES ,
  GET_SUITES_TESTCASES_BY_ID , RUN_SUITES} from "../actions/types";
  
  const user = JSON.parse(localStorage.getItem("user"));
  const initialState = {
    suitesList: null,
    testCaseListSuites: null,
    suitesTestList: null,
    suiteListById : null,
    suiteRunStatus : null
  };
  
  const suites = (state = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {

        case CREATE_SUITES:
          return {
            ...state,
            isSuiteCreated: true, 
        };

        case GET_SUITES:
            return {
              ...state,
              suitesList: payload,
        };

        case ALL_TEST_LIST_FOR_SUITES:
          return {
            ...state,
            testCaseListSuites: payload,
        };

        case CREATE_TEST_SUITE_CASE:
          return {
            ...state,
            isTestSuiteCreated: payload,
        };

        case GET_SUITES_TESTCASES:
          return {
            ...state,
            suitesTestList: payload,
        };

        case GET_SUITES_TESTCASES_BY_ID:
          return {
            ...state,
            suiteListById: payload,
        };

        case RUN_SUITES:
          return {
            ...state,
            suiteRunStatus: payload,
        };
        
      default:
        return state;
    }
  };
  export default suites;
  