import { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";

export function DataList(dashboardList) {
  if (dashboardList?.length > 0) {
    const item = dashboardList[0];
    const suiteId = item.id;
    const name = item.suite_name;
    
    // let datetimeString = item.execution_time;
    // let datetime = new Date(datetimeString);
    // const execution_time = `${datetime.toLocaleDateString(
    //   "en-GB"
    // )} ${datetime.toLocaleTimeString("en-GB")}`;
    const execution_time = item.execution_time
    
    // datetimeString = item.completion_time;
    // datetime = new Date(datetimeString);
    // const completion_time = `${datetime.toLocaleDateString(
    //   "en-GB"
    // )} ${datetime.toLocaleTimeString("en-GB")}`;
    const completion_time = item.completion_time

    let doughnut_pass = 0;
    let doughnut_failed = 0;
    let doughnut_stop = 0;

    if (item.test_status === "success") {
      doughnut_pass = item.passed;
    } else if (item.test_status === "failure") {
      doughnut_failed = item.total_cases - item.passed;
    } else {
      let obj = item.sel_logs;
      let total = item.total_cases;

      for (let item2 in obj) {
        if (obj[item2].test_status === "success") {
          doughnut_pass += 1;
        } else if (obj[item2].test_status === "failure") {
          doughnut_failed += 1;
        } else {
          doughnut_stop += 1;
        }
      }
      doughnut_stop += total - (doughnut_pass + doughnut_failed + doughnut_stop);
    }

    const total_cases = item.total_cases;
    const executionTime = new Date(item.execution_time).getTime();
    const completionTime = new Date(item.completion_time).getTime();
    const timeDifference = Math.abs(completionTime - executionTime);

    const totalSeconds = timeDifference / 1000;
    const formattedTime = `${Math.floor(totalSeconds / 3600)}:${Math.floor(
      (totalSeconds % 3600) / 60
    )}:${(totalSeconds % 60).toFixed(2)}`;

    const avg_time = formattedTime;

    return {
      name,
      execution_time,
      completion_time,
      doughnut_pass,
      doughnut_failed,
      avg_time,
      total_cases,
      suiteId,
      doughnut_stop,
    };
  }

  return null;
}


export default function DoughnutChart({ dashboardList }) {
  const [latest, setLatest] = useState({
    name: "",
    execution_time: "",
    completion_time: "",
    doughnut_pass: 0,
    doughnut_failed: 0,
    avg_time: "",
    total_cases: 0,
    suiteId: "",
    doughnut_stop: 0,
  });

  useEffect(() => {
    const latestData = DataList(dashboardList);
    if (latestData) {
      setLatest(latestData);
    }
  }, [dashboardList]);

  const data = {
    labels: ["Passed", "Failed", "Stopped"],
    datasets: [
      {
        label: "Cases",
        data: [latest.doughnut_pass, latest.doughnut_failed, latest.doughnut_stop],
        backgroundColor: ["rgba(0, 255, 0, 0.8)", "rgba(255, 0, 0, 0.8)", "rgba(255, 234, 0, 0.9)"],
        hoverBackgroundColor: ["rgb(0, 255, 0)", "rgb(255, 0, 0)", "rgb(255, 234, 0)"],
        hoverBorderColor: ["rgba(255, 255, 255, 1)", "rgba(255, 255, 255, 1)", "rgba(255, 255, 255, 1)"],
        borderColor: ["rgba(255, 255, 255, 1)", "rgba(255, 255, 255, 1)", "rgba(255, 234, 0, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const option = {
    animationEasing: "easeOutBounce",
    animateRotate: true,
    animateScale: false,
    responsive: true,
    maintainAspectRatio: true,
    showScale: true,
    legend: false,
  };

  return (
    <Doughnut data={data} options={option} />
  );
}

