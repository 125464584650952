// validateForm.js
const validateForm = (values, fields) => {
    const newErrors = {};
    let globalErrorString = ``;
    fields.forEach((field) => {
      if (field.validations) {
        for (const rule of field.validations) {
          const error = rule(values[field.name], field.label);
          if (error) {
            newErrors[field.name] = error;
            globalErrorString += `<p>* ${error}</p>`;
            // break;
          }
        }
      }
    });
    const globalError = Object.keys(newErrors).length > 0 ? globalErrorString : '';
    return { newErrors, globalError };
  };
  
  export default validateForm;  