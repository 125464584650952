import React from 'react';
import SearchIcon from '@mui/icons-material/Search'; // Importing Search Icon from Material UI

const Search = ({ onChangeQuery, value }) => {
  // Updated CSS styles
  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #ccc',
      borderRadius: '4px',
      padding: '5px',
      maxWidth: '400px',
      backgroundColor: '#f5f5f5', // Lighter background for better contrast
    },
    searchIcon: {
      color: '#888',
      marginLeft: '8px',
    },
    searchInput: {
      border: 'none',
      outline: 'none',
      width: '100%',
      fontSize: '14px',
      padding: '5px', // Add padding to input for better readability
    },
  };

  return (
    <div style={styles.container}>
      
      <input
        type="text"
        placeholder="Search..."
        onChange={onChangeQuery}
        value={value}
        style={styles.searchInput}
      />
      <SearchIcon style={styles.searchIcon} />
    </div>
  );
};

export default Search;



