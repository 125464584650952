import React from 'react';
import { Card, CardContent, Button, TextField, Box, Modal} from '@mui/material';
import { styled } from '@mui/system';
import PersonIcon from '@mui/icons-material/Person';
import { useState, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import '../../styles/project.css'
import "../../utils/timezone.min.js";
import Select from "react-select";

import { getCurrentUser, changePassword, uploadPicture } from '../../redux/actions/profile';
import { connect } from "react-redux";
import { updateuser } from "../../redux/actions/manageuser";
import { modalPopUpStyle } from '../../styles/getLPTheme';
import '../../styles/common.css';


import moment from "moment";
import '../../styles/custom.css';
import { ENV_VAR } from '../../common.js';
import { dateFormatData, dateTimeFormatData } from '../../constants/datetime.js'
import ImageCropper from "./ImageCropper";
const UserProfileCard = styled(Card)({
  marginBottom: '10px',
  padding: '20px'
});

const UserProfile = ({
  getCurrentUser,
  updateuser,
  changePassword,
  uploadPicture,
  currentUserInfo,
}) => {

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [open, setOpen] = React.useState(false);
  const [photo, setPhoto] = useState(null);
  const [preview, setPreview] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [currentUserDetails, setCurrentUserDetails] = useState();

  const [timeZone, setTimeZone] = useState("");
  const [timeZoneLabel, setTimeZoneLabel] = useState("");
  const [daylightSaving, setDaylightSaving] = useState(false);
  const [selectedDateFormat, setSelectedDateFormat] = useState(null);
  const [selectedDateTimeFormat, setSelectedDateTimeFormat] = useState(null);


  const [formData, setFormData] = useState({
    email: '',
    password: '',
    resetPassword: ''
  });

  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({ unit: "%", width: 25, aspect: 1 });
  const [image, setImage] = useState(null);
  const [canvas, setCanvas] = useState(document.createElement('canvas'));
  const [openCropModal, setOpenCropModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  useEffect(() => {
    getCurrentUser();
  }, []);


  useEffect(() => {
    if (currentUserInfo) {
      setUsername(currentUserInfo?.u_name);
      setEmail(currentUserInfo?.u_email);
      setMobileNumber(currentUserInfo?.u_mobile);
      setTimeZone(currentUserInfo?.u_timezone);
      setDaylightSaving(currentUserInfo?.u_day_saving);
      setSelectedDateTimeFormat(currentUserInfo?.u_date_time_format);
      handleTimezoneLabelChange(currentUserInfo?.u_timezone);
    }
  }, [currentUserInfo]);


  const onChangeMobileNumber = (event) => {
    setMobileNumber(event.target.value);
  }

  const handleTimezoneLabelChange = (timezone) => {
    const selectedOption = timeZoneOptions.find(
      (option) => option.key === timezone
    );
    selectedOption
      ? setTimeZoneLabel(selectedOption.label)
      : setTimeZoneLabel("");
  };

  const handleTimeZoneChange = (event) => {
    setTimeZone(event.key);
    handleTimezoneLabelChange(event.key);
  };

  const getFlagImageUrl = (countryCode) => {
    return `/timezone/flags/64/${countryCode.toLowerCase()}.png`;
  };

  const timeZoneOptions = window.timeZoneList().map((timeZone) => ({
    value:
      timeZone.value +
      " " +
      timeZone.type +
      " " +
      timeZone.key +
      " " +
      timeZone.country,
    key: timeZone.type + "/" + timeZone.key,
    label: (
      <div>
        <img
          src={getFlagImageUrl(timeZone.url)}
          alt={timeZone.country}
          style={{ width: 20, marginRight: 5 }}
        />
        {`(GMT${timeZone.value}) ${timeZone.type}/${timeZone.key}, ${timeZone.country}`}
      </div>
    ),
  }));


  const handleDateTimeFormatChange = (selectedOption) => {
    setSelectedDateTimeFormat(selectedOption.label);
  };

  const [todayDate, setTodayDate] = useState("");
  const [todayDateTime, setTodayDateTime] = useState("");

  const formatTodayDate = () => {
    if (selectedDateFormat) {
      const today = moment().format(selectedDateFormat.label);
      setTodayDate(today);
    }
  };

  const formatTodayDateTime = () => {
    if (selectedDateTimeFormat) {
      const today = moment().format(selectedDateTimeFormat);
      setTodayDateTime(today);
    }
  };

  useEffect(() => {
    formatTodayDate();
  }, [selectedDateFormat]);

  useEffect(() => {
    formatTodayDateTime();
  }, [selectedDateTimeFormat]);


  const updateCurrentUserDetails = () => {
    const updatedDetails = {
      id: currentUserInfo.u_id,
      mobile: mobileNumber,
      timezone: timeZone,
      date_time_format: selectedDateTimeFormat
    };
    updateuser(updatedDetails)
      .then((res) => {
        const user = JSON.parse(localStorage.getItem("authentication"));
        user["timezone"] = timeZone;
        user["date_time_format"] = selectedDateTimeFormat;
        localStorage.setItem("authentication", JSON.stringify(user));
        // Wait for the update to complete before fetching the user details
        getCurrentUser();
      })
      .then((res) => {
        setMobileNumber(res?.u_mobile);
        setTimeZone(res?.timezone);
        setSelectedDateTimeFormat(res?.date_time_format);
        getCurrentUser();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleCancel = () => {
    toggleModalClose();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let obj = {
      email: email,
      oldpassword: formData.password,
      password: formData.resetPassword
    }

    changePassword(obj).then(() => {
      setFormData({
        email: '',
        password: '',
        resetPassword: ''
      });
      toggleModalClose();
    }).catch((error) => {
      console.error('Error:', error);
    });
  };

  const toggleModalOpen = () => {
    setOpen(true);
  }

  const toggleModalClose = () => {
    setOpen(false);
    setOpenCropModal(false);
  };

  const handleFileChange = (event) => {
    if (event.target.files[0]) {
      handleSelectImage(event.target.files[0]);
      setOpenCropModal(true);
    }
  };

  const handleUpload = async (photo) => {
    try {
      const formData = new FormData();
      formData.append("image", photo);
      uploadPicture(formData)
        .then(() => {
          getCurrentUser();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      console.error("Error uploading photo:", error);
    }
  };

  const handleSelectImage = (file) => {
    setSrc(URL.createObjectURL(file));
    setCrop({ unit: "%", width: 25, aspect: 1 });
  };

  const handleImageLoad = (img) => {
    img.style.maxHeight = "500px";
    setImage(img);
  };

  const handleCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  const handleCropImage = () => {
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    handleCroppedImage();

  };

  const handleCroppedImage = () => {
    canvas.toBlob((blob) => {
      handleUpload(blob);
      setOpenCropModal(false);
    }, 'image/jpeg');
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  

  return (
    <div className="marginTop">
      <h3 className='label-bold'>User Details</h3>
      <div className="marginTop" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <UserProfileCard style={{ width: "40%", marginRight: "50px" }}>
          <CardContent>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ position: "relative", padding: "10px", width: "150px", height: "150px" }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                {currentUserInfo?.u_avatar ? (
                  <img
                    src={`${process.env.REACT_APP_API_URL}/api/v1/user_image?token=${token}`}
                    
                    style={{
                      border: "2px solid #9b9b9b",
                      boxShadow: "0px 0px 10px 0px #9b9b9b",
                      borderRadius: "50%",
                      height: "100%",
                      width: "100%",
                      color: "#9b9b9b",
                      filter: isHovered ? 'brightness(70%)' : 'none'
                    }}
                  />
                ) : (
                  <div
                    style={{
                      border: "2px solid #cccccc",
                      backgroundColor: "#d9d9d9",
                      borderRadius: "50%",
                      height: "100%",
                      width: "100%",
                      filter: isHovered ? 'brightness(85%)' : 'none'
                    }}>
                    <PersonIcon
                      style={{ height: "100%", width: "100%", color: "#a6a6a6", filter: isHovered ? 'brightness(70%)' : 'none' }}
                    />
                  </div>
                )}
                <input
                  type="file"
                  onChange={handleFileChange}
                  name="user_profile"
                  id="image"
                  accept="images/*"
                  style={{ display: "none" }}
                />
                <label htmlFor="image">
                  {isHovered && (
                    <Button
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'transparent',
                        color: '#ffffff',
                      }}
                      onChange={handleFileChange}
                      component="span"
                    >
                      <EditIcon />
                    </Button>
                  )}
                </label>
              </div>
            </div>
          </CardContent>
        </UserProfileCard>
        <Modal
          open={openCropModal}
          onClose={toggleModalClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box
            sx={{
              ...modalPopUpStyle,
              "& > :not(style)": { m: 1, width: "25ch" },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            noValidate
            autoComplete="off"
          >
            <ImageCropper
              crop={crop}
              src={src}
              onImageLoaded={handleImageLoad}
              onCropChange={handleCropChange}
            />

            <div style={{ width: "auto" }}>
              <Button onClick={handleCropImage}>Update</Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </div>


          </Box>
        </Modal>

        {/* Account details card */}
        <UserProfileCard style={{ width: '60%' }}>
          <CardContent>
            <div className='margin-profile'>
              <label className='label-bold'>Username</label>
              <TextField placeholder="Username"  value={username} fullWidth disabled />
            </div>
            <div className='margin-profile'>
              <label className='label-bold'>E-mail</label>
              <TextField placeholder="Email" value={email} required fullWidth disabled/>
            </div>
            <div className='margin-profile'>
              <label className='label-bold'>Mobile</label>
              <TextField type="number" placeholder="Mobile Number" onChange={(event) => onChangeMobileNumber(event)} value={mobileNumber} fullWidth />
            </div>
            
            <div className='margin-profile'>
              <label className='label-bold'>TimeZone</label>
              <Select
                value= {{ label: timeZoneLabel }}
                onChange={handleTimeZoneChange}
                options={timeZoneOptions}
                maxMenuHeight={100}
                isSearchable
              />
            </div>
            <div className='margin-profile'>
              <label className='label-bold'>Date Time Format</label>
              <Select
                value={{label: selectedDateTimeFormat}}
                onChange={handleDateTimeFormatChange}
                options={dateTimeFormatData}
                maxMenuHeight={200}
                isSearchable
              />
            </div>

            <div style={{ marginTop: '10px' }}>
              <Button colorStyle='gray' style={{ marginRight: '10px' }} onClick={updateCurrentUserDetails}>Update_Profile</Button>
              <Button color="primary" onClick={toggleModalOpen}>Change Password</Button>
            </div>
          </CardContent>
        </UserProfileCard>

        <Modal
          open={open}
          onClose={toggleModalClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description">
          <Box
            component="form"
            sx={{
              ...modalPopUpStyle, '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off">
            <h2 id="child-modal-title">
              Change Password
            </h2>
            <form onSubmit={handleSubmit}>
              <div className='margin-profile'>
                <label className='label-bold'>E-Mail</label>
                <TextField
                  name="email"
                  placeholder="E-mail"
                  value={email}
                  onChange={handleChange}
                  required
                  fullWidth
                />
              </div>
              <div className='margin-profile'>
                <label className='label-bold'>Password</label>
                <TextField
                  name="password"
                  type="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleChange}
                  fullWidth
                />
              </div>
              <div className='margin-profile'>
                <label className='label-bold'>Reset Password</label>
                <TextField
                  name="resetPassword"
                  type="password"
                  placeholder="Reset Password"
                  value={formData.resetPassword}
                  onChange={handleChange}
                  fullWidth
                />
              </div>

              <div style={{ marginTop: '10px' }}>
                <Button onClick={handleSubmit}>Change</Button>
                <Button onClick={handleCancel}>Cancel</Button>
              </div>
            </form>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentUserInfo: state.profile.userDetails
});

const mapDispatchToProps = {
  getCurrentUser,
  updateuser,
  changePassword,
  uploadPicture
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);


