import axios from "axios";
import ENV_VAR from "../common";


class AuthService { 
  constructor() {
    //API_UTIL.addHeader(axios);
  }

  async login(username, password) {
    try {
      const login_url = `${ENV_VAR.API_HOST}/api/v1/user/sign_in`;
        const response = await axios.post(login_url ,{
          email: username,
          password: password,
        });
        let jsonString = JSON.stringify(response.data)
        localStorage.setItem('authentication', jsonString);
        localStorage.setItem('token', response.data.access_token);
      return response;
    } catch (error) {
      console.log("Login Error", error);
      throw error.response.data.error;
    }
  }

async forgetPassword(obj) {
  try {
    const login_url = `${ENV_VAR.API_HOST}/api/v1/user/resetpwd`;
      const response = await axios.post(login_url, obj);
      return response;
  } catch (error) {
    console.log("Forgot password error", error);
    throw error.response.data.error;
  }
 }

 async resetToken(obj) {
  try {
    const login_url = `${ENV_VAR.API_HOST}/api/v1/user/resettoken`;
      const response = await axios.post(login_url, obj);
      return response;
  } catch (error) {
    console.log("Reset Token Error", error);
    throw error.response.data.error;
  }
 }
}

//   forgotPassword(email) {y

//     return axios.post(API_UTIL.getApiUrl(`users/reset-password`), {
//       email: email,
//     });
//   }

//   resetPassword(password, token) {
//     return axios.post(API_UTIL.getApiUrl(`users/reset-password/${token}`), {
//       password: password,
//     });
//   }

//   getUserDetails(token) {
//     return axios.get(API_UTIL.getApiUrl(`users/info`), {
//       headers: { Authorization: `Bearer ${token}` },
//     });
//   }

//   refreshToken() {
//     return axios.post(API_UTIL.getApiUrl(`users/refresh`));
//   }  
// } 

export default new AuthService();
