import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";

class JobSchedular {
    constructor() {
        this.headers = createHeaders();
    }

    async getSchedularList() {
        try {    
            const url = `${ENV_VAR.API_HOST}/api/v1/periodictask`;
            const response = await axios.get(url,this.headers);
            return response;
        } 
        catch (error) {
            throw error.response.data.error;
        }
    }

    async createSchedular(obj) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/periodictask`;
            const response = await axios.post(url,obj,this.headers);
            return response;
        } 
        catch (error) {
            throw error.response.data.error;
        }
    }
    
    async getSchedularbyid(perodic_id) { 
        try {    
            const url = `${ENV_VAR.API_HOST}/api/v1/periodictask/${perodic_id}`;
            const response = await axios.get(url,this.headers);
            return response;
        } 
        catch (error) {
            throw error.response.data.error;
        }
    }

    async updateSchedular(obj) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/periodictask`; 
            const response = await axios.put(url,obj,this.headers);
            return response;
        } 
        catch (error) {
            throw error.response.data.error;
        }
    }

    async deleteSchedularbyid(perodic_id) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/periodictask/${perodic_id}`;
            const obj = {
                id: perodic_id
            }
            const response = await axios.delete(url, 
                   {
                    id: perodic_id
                   }, this.headers);
            return response;
        } 
        catch (error) {
            throw error.response.data.error;
        }
      }
}

export default new JobSchedular();