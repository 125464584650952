import Swal from 'sweetalert2';
import '../styles/common.css';

const showConfirmationAlert = (title, text, confirmButtonText = 'Yes', cancelButtonText = 'No', confirmButtonColor = '#34B1AA', cancelButtonColor = '#f44336c4') => {
  return Swal.fire({
    title: title,
    text: text,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: confirmButtonColor,
    cancelButtonColor: cancelButtonColor,
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    focusConfirm: false,
    customClass: {
      popup: 'swal-popup',
    },
  });
};

export default showConfirmationAlert;
