import { GET_CLOCKED_LIST, CREATE_CLOCK_SCHEDULE, UPDATE_CLOCK_SCHEDULE, DELETE_CLOCK_SCHEDULE} from "../actions/types";
    
      const initialState = {
        clockedList: null,
      };
      
      const clocked = (state = initialState, action) => {
        const { type, payload } = action;
      
        switch (type) {
          
            case GET_CLOCKED_LIST:
              return {
                ...state,
                clockedList: payload,
            };
        
            case CREATE_CLOCK_SCHEDULE:
                return {
                  ...state,
                  isClockedCreated: payload,
              };

              case UPDATE_CLOCK_SCHEDULE:
                return {
                  ...state,
                  isClockedUpdated: payload,
              };

              case DELETE_CLOCK_SCHEDULE:
                return {
                  ...state,
                  isClokedDeleted: payload,
              };
          default:
            return state;
        }
      };
      export default clocked;
      