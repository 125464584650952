import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, TableContainer, TableCell, Card, CardContent , Button , Typography , Divider } from '@mui/material';
import { connect } from "react-redux";
import BackupIcon from '@mui/icons-material/Backup';
import DownloadIcon from '@mui/icons-material/Download';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import RepeatIcon from '@mui/icons-material/Repeat';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {modalPopUpStyleLarge } from '../../styles/getLPTheme';
import{ ENV_VAR } from '../../common.js';
import { getFilesList, uploadFiles, replaceFiles, deleteFile } from '../../redux/actions/files';
import '../../styles/common.css';
import '../../styles/custom.css';
import '../../styles/files.css';

function FileUploader({ getFilesList, uploadFiles, replaceFiles, deleteFile }) {

    const [fileList, setFilelist] = useState([]);
    const [open, setOpen] = useState(false);
    const [openReplace, setReplaceOpen] = useState(false);
    const [files, setFileId] = useState();
    const [viewerUrl, setViewerUrl] = useState('');

    useEffect(() => {
        getFilesList().then((res) => {
            setFilelist(res);
        });
    }, [getFilesList]);

    const handleOpen = (row) => {
        let url = `${ENV_VAR.API_HOST}/${row.f_path}`;
        let googleDocsUrl = '';
       // url = url.replace(/\\/g, "/");
        //const googleDocsUrl = `https://docs.google.com/viewer?url=${url}&embedded=true`;
        if(row.f_ext === 'xlsx' || row.f_ext === 'xls'|| row.f_ext === 'xlsb' || row.f_ext === 'xlsm'){
             googleDocsUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${url}`;
        }
        else {
             googleDocsUrl = `https://docs.google.com/viewer?url=${url}&embedded=true`;
        }
        
        console.log("Url ", url , googleDocsUrl);
        setViewerUrl(googleDocsUrl);
        setFileId(row);
        setOpen(true);

    };

    const handleClose = () => {
        setOpen(false);
        setReplaceOpen(false);
    };

    const openReplacePopup = (row) => {
        setReplaceOpen(true);
        setFileId(row);
    };

    const handleFile = (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('files', file);
        uploadFiles(formData).then(() => {
            getFilesList().then((res) => {
                setFilelist(res);
            });
        });
    };

    const deleteFileById = (row) => {
        deleteFile(row.f_id).then(() => {
            getFilesList().then((res) => {
                setFilelist(res);
            });
        });
    };

    const replaceFileById = (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('files', file);
        replaceFiles(formData, files.f_id).then(() => {
            getFilesList().then((res) => {
                setFilelist(res);
            });
            handleClose();
        });
    };

    const downloadFile = (row) => {
        const link = document.createElement('a');
        link.href = `${ENV_VAR.API_HOST}/${row.f_path}`;
        link.download = row.f_original_name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    return (
        <div>
            {/* <h3 className='label-bold'>All Documents</h3> */}
            <Card
                raised
                sx={{
                    minWidth: "100%",
                    overflowX: "auto"
                }}>
                <Typography variant="h5" sx={{
                    p: 2
                }}>
                   All Documents
                </Typography>
                <Divider></Divider>
                <CardContent>
                    <div className='upload-container'>
                        <label htmlFor="file-upload-main" className="upload-label">
                            <BackupIcon className="upload-icon" />
                            <span>Upload documents from here</span>
                        </label>
                        <input id="file-upload-main" type="file" className="file-input" onChange={handleFile} />
                    </div>
                </CardContent>
            </Card>
            <br></br>
      
            <TableContainer component={Card} className='dashboard-table table td'>
            <div className="scroll">
                <Table>
                    <TableHead className='th'>
                        <TableRow>
                            <TableCell>Index</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Uploaded By</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fileList?.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{row.f_original_name}</TableCell>
                                <TableCell>{row.u_name}</TableCell>
                                <TableCell className="action-icons">
                                    <Tooltip title="Replace">
                                        <RepeatIcon onClick={() => openReplacePopup(row)} className="action-icon" />
                                    </Tooltip>
                                    <Tooltip title="Download">
                                           <DownloadIcon className="action-icon" onClick={() => downloadFile(row)}  />
                                     </Tooltip>
                                    <Tooltip title="View">
                                        <RemoveRedEyeIcon className="action-icon" onClick={() => handleOpen(row)} />
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <DeleteIcon style={{color: '#d71b1b'}} onClick={() => deleteFileById(row)} className="action-icon clickIconClassDelete" />
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
            </TableContainer>

            <Modal
                open={openReplace}
                onClose={handleClose}
                aria-labelledby="replace-modal-title"
                aria-describedby="replace-modal-description">
                <Box component="form"
                    sx={{
                        ...modalPopUpStyleLarge, '& > :not(style)': { m: 1 },
                    }}
                    noValidate
                    autoComplete="off">
                    <div className="modal-header">
                        <h4 className='label-bold'>{files?.f_original_name}</h4>
                    </div>
                    <div className='upload-container'>
                        <label htmlFor="file-replace-upload" className="upload-label">
                            <BackupIcon className="upload-icon" />
                            <span>Upload documents from here</span>
                        </label>
                        <input id="file-replace-upload" type="file" className="file-input" onChange={replaceFileById} />
                    </div>
                    <div className="modal-footer">
                        <Button onClick={handleClose} className="modalButton modalCloseButton">Close</Button>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="viewer-modal-title"
                aria-describedby="viewer-modal-description">
                <Box component="form"
                    sx={{
                        ...modalPopUpStyleLarge, '& > :not(style)': { m: 1 },
                    }}
                    noValidate
                    autoComplete="off">
                    <div className="modal-header">
                        <h4>{files?.f_original_name}</h4>
                    </div>
                    <div className='viewer-container'>
                        <iframe
                            // src={`data:application/pdf;base64,${viewerUrl}`}
                            src={viewerUrl}
                            title="Document Viewer"
                            width="100%"
                            height="600px"
                        ></iframe>
                    </div>
                    <div className="modal-footer">
                        <Button onClick={handleClose} className="modalButton modalCloseButton">Close</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => ({
    filesList: state?.files?.filesList
});

const mapDispatchToProps = {
    getFilesList, 
    uploadFiles,
    replaceFiles,
    deleteFile
};

export default connect(mapStateToProps, mapDispatchToProps)(FileUploader);
