import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";

class templateService {
    constructor() {
        this.headers = createHeaders();
    }

  
async getTemplates() {  
    try { 
        const url = `${ENV_VAR.API_HOST}/api/v1/Gettemplates`;
        const response = await axios.get(url, this.headers);
        return response;
    } 
    catch (error) {
        throw error.response.data.error;
    }
  }

  async addTemplates(obj) {  
    try { 
        const url = `${ENV_VAR.API_HOST}/api/v1/Addtemplate`;
        const response = await axios.post(url,obj, this.headers);
        return response;
    } 
    catch (error) {
        throw error.response.data.error;
    }
  }
}

export default new templateService();