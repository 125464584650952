import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import loader from "./loader";
import runJob from "./runJob";
import suites from "./suites";
import template from "./template";
import exportImport from "./exportImport"
import testCaseReport  from "./testCaseReport";
import suiteReport from "./suiteReport";
import manageuser from "./manageuser";
import assignUsers from "./assignUsers";
import profile from "./profile";
import cronTab from "./crontab";
import clocked from "./clocked";
import dashboard from "./dashboard";
import suiteExecution from "./executionReport";
import schedular from "./schedular";
import files from './files';
import SchedularJob from "./schedularReport";

export default combineReducers({
  auth,
  message,
  loader,
  runJob,
  suites,
  template,
  exportImport,
  testCaseReport,
  suiteReport,
  manageuser,
  assignUsers,
  profile,
  cronTab,
  clocked,
  dashboard,
  suiteExecution,
  schedular,
  files,
  SchedularJob,
});
