import { toast } from 'react-toastify';
import SchedularService from "../../services/schedularReport.service";

export const SchedularJobReport = (obj) => async (dispatch) => {
    try {
      let res = await SchedularService.getSchedularReport(obj);
      if (res.status === 200) {
        return res.data
      
      }else {
        toast.error("Unable to fetch suite report : " + res.status);
      }
    } catch (error) {
        toast.error(error);
    }
  };
