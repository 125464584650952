import {
    GET_TEST_JOB,
    GET_SUITECASE_JOB,
      } from "../actions/types";
      
    
      const initialState = {
        testJobList: null,
        suiteTestCaseList:null
      };
      
      const testCaseReport = (state = initialState, action) => {
        const { type, payload } = action;
      
        switch (type) {
          
            case GET_TEST_JOB:
              return {
                ...state,
                testJobList: payload,
            };
            case GET_SUITECASE_JOB:
              return {
                ...state,
                suiteTestCaseList: payload,
            };
        
          default:
            return state;
        }
      };
      export default testCaseReport;
      