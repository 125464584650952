import axios from "axios";
import ENV_VAR from "../common";

class DashboardService {
    constructor() {
        //API_UTIL.addHeader(axios);
    }

    async getUserDashboard() {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/userDashboard/`;
            const authentication = JSON.parse(localStorage.getItem('authentication'));
            const headers = {
                "accept": "application/json",
                "Content-Type": "application/json",
                "x-api-key": authentication.access_token
            }
            const response = await axios.get(url, headers);

            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    async getsuitelist() {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/SuitList/`;
            const authentication = JSON.parse(localStorage.getItem('authentication'));
            const headers = {
                "accept": "application/json",
                "Content-Type": "application/json",
                "x-api-key": authentication.access_token
            }
            const response = await axios.get(url, headers);

            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    async getUserDashboardById(Id) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/userDashboard/${Id}`;
            const authentication = JSON.parse(localStorage.getItem('authentication'));
            const headers = {
                "accept": "application/json",
                "Content-Type": "application/json",
                "x-api-key": authentication.access_token
            }    
            const response = await axios.get(url,headers);
            
            return response;
        } 
        catch (error) {
            throw error;
        }
    }
   
}


export default new DashboardService();