import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow,Divider,CardContent,TableCell, Checkbox, Button,Box,Typography,Tooltip, TableContainer , Card } from '@mui/material';
import { connect } from "react-redux";
import { getTemplateList , addTemplates} from "../../redux/actions/template";
import { useLocation, useNavigate } from "react-router-dom";
import '../../styles/common.css';
import Search from '../../components/Search'


function AddTemplate({ getTemplateList, addTemplates , templateList}) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [allTemplate, setAllTemplates] = useState();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');


  const [cellStyle, setCellStyle] = useState({
    backgroundColor: '#ddd9d9',
    fontSize: '16px',
    fontWeight: 'bold'
  });

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    getTemplateList();
  }, []
  )

  useEffect(() => {
    if(templateList && templateList.length){
        setAllTemplates(templateList);
    }
  }, [templateList]
  ) 

  useEffect(() => {
    // Initialize filteredData with templateList to handle cases where searchQuery is empty
    let filteredData = templateList;
  
    if (searchQuery && searchQuery.length > 0) {
      filteredData = templateList.filter(item => {
        const projMatch = item.proj_name && item.proj_name.toLowerCase().includes(searchQuery.toLowerCase());
        const suiteNameMatch = item.case_name && item.case_name.toLowerCase().includes(searchQuery.toLowerCase());
        const userNameMatch = item.u_name && item.u_name.toLowerCase().includes(searchQuery.toLowerCase());
        return projMatch || suiteNameMatch || userNameMatch;
      });
    }
  
    console.log("FilteredData", filteredData);
    setAllTemplates(filteredData);
  }, [searchQuery, templateList]);


  const handleRowSelect = (row) => {
    const isSelected = selectedRows.some(selectedRow => selectedRow.templateid === row.case_id);
  
    if (!isSelected) {
      setSelectedRows([...selectedRows, {
        testid: state.case_id,
        templateid: row.case_id // Assuming row contains the template_id
      }]);
    } else {
      setSelectedRows(selectedRows.filter(selectedRow => selectedRow.templateid !== row.case_id));
    }
  };
  

 const isRowSelected = (row) => {
  return selectedRows.some(selectedRow => selectedRow.templateid === row.case_id);
};
 
  const addTestCase = () => {
    selectedRows?.map((row, i) => {
        addTemplates(row)
          .then(() => {
            console.log("Test case data", row)
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      })

      navigate('/testcase', { state: state });
  }

  return (
    <div>
      <Card
        raised
        sx={{
          minWidth: "100%",
          overflowX: "auto"  
        }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
        <Typography variant="h5">
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <label style={{ marginRight: '20px' }}> Add Test Cases For</label>
            
            <Box sx={{ flexGrow: 1 }} /> {/* This will push the Search and Button to the right */}
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Search onChangeQuery={handleSearchChange} value={searchQuery} />
      <Tooltip title="Add Template">
                  <Button sx={"float: right ; marginBottom: 2px"} onClick={addTestCase}> + Add Test Cases</Button>
                </Tooltip>      </Box>
          </Box>
        </Typography>
      </Box>
        
        <Divider></Divider>
        <CardContent>
        <div style={{ width: '100%' }}>
         <TableContainer component={Card} className='dashboard-table table td'>
      <Table sx={{ minWidth: 650 }}>
      <TableHead className='th'>
          <TableRow>
            <TableCell padding="checkbox">        
            </TableCell>
            <TableCell>Testcase Name</TableCell>
            <TableCell>Project</TableCell>
            <TableCell>Total Steps</TableCell>
            <TableCell>Created By</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allTemplate?.map((row, index) => (
            <TableRow
              key={row?.case_id}
              hover
              checked={isRowSelected(row)}
              onChange={() => handleRowSelect(row)}
              role="checkbox"
              selected={isRowSelected(row)}
            >
              <TableCell padding="checkbox">
                <Checkbox checked={isRowSelected(row)} />
              </TableCell>
              <TableCell >{row.case_name}</TableCell>
              <TableCell >{row.proj_name}</TableCell>
              <TableCell >{row.teststep_counter}</TableCell>
              <TableCell>{row.u_name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
          </div>
      </CardContent>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => ({
    templateList: state?.template?.templateList,
});

const mapDispatchToProps = {
  getTemplateList,
  addTemplates
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTemplate);

