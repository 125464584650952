import { CLEAR_ATTRIBUTES_LIST_EXPORT, CLEAR_VALIDATION_LIST_EXPORT, CLEAR_TESTSTEPS_LIST_EXPORT }
    from "./types";
import exportImport from "../../services/exportImport";
import { toast } from 'react-toastify';

export const getAllAttributeListForExport = (testId) => async (dispatch) => {
    try {
        dispatch({
            type: CLEAR_ATTRIBUTES_LIST_EXPORT
        })
        let res = await exportImport.getAttributeData(testId);
        if (res.status === 200) {
           return res.data;
        } else {
            toast.error("Unable to fetch attributes list : " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};

export const getAllValidationListForExport = (testId) => async (dispatch) => {
    try {
        dispatch({
            type: CLEAR_VALIDATION_LIST_EXPORT
        })
        let res = await exportImport.getValidationData(testId);
        if (res.status === 200) {
            return res.data;
        } else {
            toast.error("Unable to fetch validation list : " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};

export const getAllTestStepListForExport = (testId) => async (dispatch) => {
    try {
        dispatch({
            type: CLEAR_TESTSTEPS_LIST_EXPORT
        })
        let res = await exportImport.getTeststepData(testId);
        if (res.status === 200) {
            return res.data;
        } else {
            toast.error("Unable to fetch test step list : " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};
