import React, { useState, useEffect } from 'react';
import { Card, Button, TextField, Select, MenuItem, TableRow, TableCell, TableContainer, TableHead, TableBody,Tooltip, Typography, Grid, Divider, Table, CardContent } from '@mui/material';
import { getCronTabList, deleteCronTab, updateCronTab, createCronTab, cronTabList } from '../../redux/actions/crontab';
import extractSortAndFilterParams from '../../components/Table/filterSortParams';
import ServerPaginationGridNoRowCount from "../../components/Table/DataGrid";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from 'moment-timezone';
import '../../styles/custom.css';
import '../../styles/common.css';
import cronstrue from 'cronstrue';


const Crontab = ({ getCronTabList, deleteCronTab, updateCronTab, createCronTab, cronTabList , count }) => {
  const [timezones, setTimezones] = useState([]);
  const [editClicked, setEditClicked] = useState(false);
  const [cronList, setCronList] = useState(false);
  const [editCronTabDetails, setCronTabDetails] = useState(false);
  const [totalCount, setTotalCount] = useState([]);
  const navigate = useNavigate();
  const [paginationModals, setPaginationModals] = useState({
    page: 0,
    pageSize: 10,
  });
  const [sortModels, setSortModels] = useState([]);
  const [filterModels, setFilterModels] = useState({ items: [] });
  const columns = [
    { field: "name", headerName: "Name" },
    { field: "u_name", headerName: "Created By" },
    {
      field: "Actions",
      headerName: "Actions",
      resizable: false,
      renderCell: (params) => (
        <>
          <Tooltip title="Edit">
            <EditIcon className='action-icon' onClick={() => editButtonClicked(params.row)} />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteIcon className='action-icon-delete' onClick={() => deleteCrontab(params.row)} />
          </Tooltip>
        </>
      ),
    },
  ];
  const [width, setWidth] = useState(100 / columns.length + '% !important');

  const [formData, setFormData] = useState({
    name: '',
    minutes: '*',
    hours: '*',
    daysOfWeek: '*',
    daysOfMonth: '*',
    monthsOfYear: '*',
    timeZone: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
    getCronTabList(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort, JSON.stringify(filters));
  }, [getCronTabList, paginationModals.page, paginationModals.pageSize]);

  useEffect(() => {
    const dataWithIds = cronTabList?.map((row, index) => ({
      ...row,
      id: row.cron_id,
    }));
    setCronList(dataWithIds);
    setTotalCount(count);
  }, [cronTabList]
  )

  useEffect(() => {
    const timezones = moment.tz.names();
    setTimezones(timezones);
  }, []);

  const submitCronTab = () => {
    let nameString = `${formData.minutes} ${formData.hours} ${formData.daysOfWeek} ${formData.daysOfMonth} ${formData.monthsOfYear}`;
    let obj = {
      name: cronstrue.toString(nameString),
      minute: formData.minutes,
      hour: formData.hours,
      day_of_week: formData.daysOfWeek,
      day_of_month: formData.daysOfMonth,
      month_of_year: formData.monthsOfYear,
      timezone: formData.timeZone
    };

    createCronTab(obj).then(() => {
      const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
      setFormData({
        name: '',
        minutes: '*',
        hours: '*',
        daysOfWeek: '*',
        daysOfMonth: '*',
        monthsOfYear: '*',
        timeZone: '',
      });
      getCronTabList(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort,JSON.stringify(filters));
    })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const deleteCrontab = (row) => {
    const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
    deleteCronTab(row.cron_id).then(() => {
      getCronTabList(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort,JSON.stringify(filters));
    })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const editButtonClicked = (cronData) => {
    setCronTabDetails(cronData);
    setEditClicked(true);
    setFormData({
      name: cronData.name,
      minutes: cronData.minute,
      hours: cronData.hour,
      daysOfWeek: cronData.day_of_week,
      daysOfMonth: cronData.day_of_month,
      monthsOfYear: cronData.month_of_year,
      timeZone: cronData.timezone,
    });
  };

  const update = () => {
    let nameString = `${formData.minutes} ${formData.hours} ${formData.daysOfWeek} ${formData.daysOfMonth} ${formData.monthsOfYear}`;
    let obj = {
      id: editCronTabDetails.cron_id,
      name: cronstrue.toString(nameString),
      minute: formData.minutes,
      hour: formData.hours,
      day_of_week: formData.daysOfWeek,
      day_of_month: formData.daysOfMonth,
      month_of_year: formData.monthsOfYear,
      timezone: formData.timeZone
    };

    updateCronTab(obj).then(() => {
      const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
      setFormData({
        name: '',
        minutes: '*',
        hours: '*',
        daysOfWeek: '*',
        daysOfMonth: '*',
        monthsOfYear: '*',
        timeZone: '',
      });
      getCronTabList(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort,JSON.stringify(filters));
      setEditClicked(false);
    })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const cancelClicked = () => {
    setFormData({
      name: '',
      minutes: '*',
      hours: '*',
      daysOfWeek: '*',
      daysOfMonth: '*',
      monthsOfYear: '*',
      timeZone: '',
    });
    setEditClicked(false);
    navigate('/Scheduler');
  };

  const tableRows = cronTabList?.map((row, index) => (
    <TableRow key={index}>
      <TableCell>{index + 1}</TableCell>
      <TableCell>{row.name}</TableCell>
      <TableCell>{row.u_name}</TableCell>
      <TableCell>
        <Button variant="secondary" onClick={() => editButtonClicked(row)}>Edit</Button>
        <Button variant="danger" style={{ backgroundColor: '#f8d7da', color: '#721c24' }} onClick={() => deleteCrontab(row)}>Delete</Button>
      </TableCell>
    </TableRow>
  ));

  return (
    <div>
      <Card
        raised
        sx={{
          minWidth: "100%",
          overflowX: "auto"
        }}>
        <Typography variant="h5" sx={{
          p: 2
        }}>
          Create New Crontab
        </Typography>
        <Divider></Divider>
        <CardContent>
          {/* <div className="row mb-3 align-items-center">
            <label className="col-md-2 col-form-label">Minutes:</label>
            <div className="col-md-4">
              <input
                className="form-control form-control-sm"
                name="minutes"
                onChange={handleChange}
                value={formData.minutes}
              />
            </div>
            <div className="col-md-6 text-muted">
              Cron Minutes to Run. Use "*" for "all". (Example: "0,30")
            </div>
          </div>

          <div className="row mb-3 align-items-center">
            <label className="col-md-2 col-form-label">Hours:</label>
            <div className="col-md-4">
              <input
                className="form-control form-control-sm"
                name="hours"
                onChange={handleChange}
                value={formData.hours}
              />
            </div>
            <div className="col-md-6 text-muted">
              Cron Hours to Run. Use "*" for "all". (Example: "8,20")
            </div>
          </div>

          <div className="row mb-3 align-items-center">
            <label className="col-md-2 col-form-label">Days of Week:</label>
            <div className="col-md-4">
              <input
                className="form-control form-control-sm"
                name="daysOfWeek"
                onChange={handleChange}
                value={formData.daysOfWeek}
              />
            </div>
            <div className="col-md-6 text-muted">
              Cron Days Of The Week to Run. Use "*" for "all", Sunday is 0 or 7, Monday is 1. (Example: "0,5")
            </div>
          </div>

          <div className="row mb-3 align-items-center">
            <label className="col-md-2 col-form-label">Days of Month:</label>
            <div className="col-md-4">
              <input
                className="form-control form-control-sm"
                name="daysOfMonth"
                onChange={handleChange}
                value={formData.daysOfMonth}
              />
            </div>
            <div className="col-md-6 text-muted">
              Cron Days Of The Month to Run. Use "*" for "all". (Example: "1,15")
            </div>
          </div>

          <div className="row mb-3 align-items-center">
            <label className="col-md-2 col-form-label">Months of Year:</label>
            <div className="col-md-4">
              <input
                className="form-control form-control-sm"
                name="monthsOfYear"
                onChange={handleChange}
                value={formData.monthsOfYear}
              />
            </div>
            <div className="col-md-6 text-muted">
              Cron Months (1-12) Of The Year to Run. Use "*" for "all". (Example: "1,12")
            </div>
          </div> */}

          {/* Timezone field is hidden */}
          {/* <div className="row mb-3 align-items-center d-none">
            <label className="col-md-2 col-form-label">Time Zone:</label>
            <div className="col-md-4">
              <Select
                className="form-control form-control-sm"
                name="timeZone"
                onChange={handleChange}
                value={formData.timeZone}
                fullWidth
              >
                {timezones.map((timezone, index) => (
                  <MenuItem key={index} value={timezone}>
                    {timezone}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div> */}

          {/* <div className="d-flex justify-content-start">
            {!editClicked && <Button variant="primary" className="mr-2" onClick={submitCronTab}>Save</Button>}
            {editClicked && <Button variant="primary" className="mr-2" onClick={update}>Update</Button>}
            <Button variant="secondary" style={{ backgroundColor: '#f8d7da', color: '#721c24' }} onClick={cancelClicked}>Back</Button>
          </div> */}

          <Grid container spacing={2} sx={{ p: 2 }}>
            {/* ---------- Minutes ---------- */}
            <Grid item xs={4} sm={2}>
              <Typography variant="subtitle2" fontSize={14}>
                Minutes:
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                className="form-control form-control-sm"
                name="minutes"
                onChange={handleChange}
                value={formData.minutes}
              />
            </Grid>
            <Grid item xs={4} sm={6}>
              <Typography fontSize={12}>
                Cron Minutes to Run. Use "*" for "all". (Example: "0,30")
              </Typography>
            </Grid>

            {/* ----------- Hours ---------- */}
            <Grid item xs={4} sm={2}>
              <Typography variant="subtitle2" fontSize={14}>
                Hours:
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                className="form-control form-control-sm"
                name="hours"
                onChange={handleChange}
                value={formData.hours}
              />
            </Grid>
            <Grid item xs={4} sm={6}>
              <Typography fontSize={12}>
                Cron Hours to Run. Use "*" for "all". (Example: "8,20")
              </Typography>
            </Grid>

            {/* ----------- Days of Week ---------- */}
            <Grid item xs={4} sm={2}>
              <Typography variant="subtitle2" fontSize={14}>
                Days of Week:
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                className="form-control form-control-sm"
                name="daysOfWeek"
                onChange={handleChange}
                value={formData.daysOfWeek}
              />
            </Grid>
            <Grid item xs={4} sm={6}>
              <Typography fontSize={12}>
                Cron Days Of The Week to Run. Use "*" for "all", Sunday is 0 or 7, Monday is 1. (Example: "0,5")
              </Typography>
            </Grid>

            {/* ----------- Days of Month ---------- */}
            <Grid item xs={4} sm={2}>
              <Typography variant="subtitle2" fontSize={14}>
                Days of Month:
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                className="form-control form-control-sm"
                name="daysOfMonth"
                onChange={handleChange}
                value={formData.daysOfMonth}
              />
            </Grid>
            <Grid item xs={4} sm={6}>
              <Typography fontSize={12}>
                Cron Days Of The Month to Run. Use "*" for "all". (Example: "1,15")
              </Typography>
            </Grid>

            {/* ----------- Months of Year ---------- */}
            <Grid item xs={4} sm={2}>
              <Typography variant="subtitle2" fontSize={14}>
                Months of Year:
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                className="form-control form-control-sm"
                name="monthsOfYear"
                onChange={handleChange}
                value={formData.monthsOfYear}
              />
            </Grid>
            <Grid item xs={4} sm={6}>
              <Typography fontSize={12}>
                Cron Months (1-12) Of The Year to Run. Use "*" for "all". (Example: "1,12")
              </Typography>
            </Grid>
            <br></br>
            <Grid item xs={4} sm={6}>
              <Typography fontSize={14}>
                Note : To run Schedule in Interval use "/" eg: ('*/5') in minutes to run Job Every 5 Minutes
              </Typography>
            </Grid>

            <Grid item xs={4} sm={6}>
              <Typography fontSize={14}>
                {!editClicked && <Button variant="primary" className="mr-2 right" onClick={submitCronTab}>Save</Button>}
                {editClicked && <Button variant="primary" className="mr-2 right" onClick={update}>Update</Button>}
                <Button colorStyle="cancel" className="mr-2 right" variant="secondary" onClick={cancelClicked}>Back</Button>
              </Typography>
            </Grid>
          </Grid>

          {/* Timezone field is hidden */}
          <div className="row mb-3 align-items-center d-none">
            <label className="col-md-2 col-form-label">Time Zone:</label>
            <div className="col-md-4">
              <Select
                className="form-control form-control-sm"
                name="timeZone"
                onChange={handleChange}
                value={formData.timeZone}
                fullWidth
              >
                {timezones.map((timezone, index) => (
                  <MenuItem key={index} value={timezone}>
                    {timezone}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </CardContent>
      </Card>
<br></br>
      <Card
        raised
        sx={{
          minWidth: "100%",
          overflowX: "auto"
        }}>
        <Typography variant="h5" sx={{
          p: 2
        }}>
          Crontab List
        </Typography>
        <Divider></Divider>
          <CardContent>
            <ServerPaginationGridNoRowCount
              setPaginationModals={setPaginationModals}
              paginationModals={paginationModals}
              rowsData={cronList}
              total={totalCount}
              columns={columns}
              columnWidth={width}
              setSortModels={setSortModels}
              sortModels={sortModels}
              setFilterModels={setFilterModels}
              filterModels={filterModels}
            />
          </CardContent>

        {/* <TableContainer component={Card} className='dashboard-table'>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Index</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableRows}
            </TableBody>
          </Table>
        </TableContainer> */}
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => ({
  cronTabList: state.cronTab?.crontabList?.rows,
  count: state.cronTab?.crontabList?.count
});

const mapDispatchToProps = {
  getCronTabList,
  deleteCronTab,
  updateCronTab,
  createCronTab
};

export default connect(mapStateToProps, mapDispatchToProps)(Crontab);
