import {
    SUITE_EXECUTION_REPORT
      } from "../actions/types";
      
    
      const initialState = {
        SuiteExecution: null,
      };
      
      const SuiteReport = (state = initialState, action) => {
        const { type, payload } = action;
      
        switch (type) {
          
            case SUITE_EXECUTION_REPORT:
              return {
                ...state,
                SuiteExecution: payload,
            };
        
          default:
            return state;
        }
      };
      export default SuiteReport;
      