import React, { useState, useEffect ,useRef } from 'react';
import * as XLSX from 'xlsx';
import { connect } from "react-redux";
import { createTestSteps, getTestStepsList, createAttributes, updateTestSteps, updateAttribute, updateValidations, getAttributes, createValidations, getValidations } from "../redux/actions/testcase";
import { Button, Modal, Box } from '@mui/material';
import BackupIcon from '@mui/icons-material/Backup';
import '../styles/common.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Uploader = ({ createTestSteps, updateTestSteps, testData, type, createAttributes, updateAttribute, updateValidations, createValidations, openModal, handleCloseModal }) => {
  const [data, setData] = useState([]);
  const [emptyExcelCheck, setEmptyExcelCheck] = useState(false);
  const [uploadedFile, setUploadedFile] = useState([]);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  useEffect(() => {
    setEmptyExcelCheck(false);
    setUploadedFile('');
  });

  const handleFile = (event) => {
    setEmptyExcelCheck(false);
    const file = event.target.files[0];
    const reader = new FileReader();
    setUploadedFile(file.name);
    reader.onload = (event) => {
      const binaryString = event.target.result;
      const workbook = XLSX.read(binaryString, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const headers = parsedData[0];
      const dataArray = parsedData.slice(1).map((row) => {
        const obj = {};
        headers.forEach((header, index) => {
          obj[header] = row[index];
        });
        return obj;
      });
      setData(dataArray);
      
    };
    reader.readAsBinaryString(file);
  };

  const handleFileForStepData = (event) => {
    setEmptyExcelCheck(false);
    const file = event.target.files[0];
    setUploadedFile(file.name);
    const reader = new FileReader();
    const requiredColumns = ['Step Name', 'Step Action Type'];

    reader.onload = (event) => {
      const binaryString = event.target.result;
      const workbook = XLSX.read(binaryString, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const headers = parsedData[0];
      const dataArray = parsedData.slice(1).map((row , rowIndex) => {
        const obj = {};
       let toastMessageShown = false;
        headers.forEach((header, index) => {
          obj[header] = row[index];
          if (requiredColumns.includes(header) && !row[index]) {
            if(!toastMessageShown){
              toast.error(`Header "${header}" is empty at line number ${rowIndex + 2}. Please check and retry.`);
              fileInputRef.current.value = '';
              setUploadedFile('');
              toastMessageShown = true;
            }
            return;
          }
        });
        return obj;
      });
      setData(dataArray);
    };
    reader.readAsBinaryString(file);
  }

  function cleanObjectKeys(obj) {
    const cleanedObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const cleanedKey = key.replace(/\s+/g, '');
        cleanedObj[cleanedKey] = obj[key];
      }
    }
    return cleanedObj;
  }

  const onSubmit = () => {
    if (data.length === 0) {
      toast.warning("Data is not present in excel please try again with atleast one row");
      return;
    }

    if (type === "testStep") {
      let successShown = false;
      let updateShown = false;
      let failedShown = false;
      data?.map((stepData) => {
        const cleanedStepData = cleanObjectKeys(stepData);
        if (cleanedStepData.TestID) {
          if (!cleanedStepData.StepID) {
            const obj = {
              step_name: cleanedStepData.StepName,
              step_action_type: cleanedStepData.StepActionType,
              test_id: testData?.case_id,
              priority: cleanedStepData.Priority,
            };
            createTestSteps(obj).then(() => {
              if (!successShown) {
                toast.success("Test steps created successfully");
                successShown = true;
              }
              let row = {
                case_id: testData?.case_id
              }
              handleCloseModal(false);
              navigate(`/testcase`, { state: row });
            })
              .catch((error) => {
                console.error('Error:', error);
              });
          } else {
            const obj = {
              id: cleanedStepData.StepID,
              step_name: cleanedStepData.StepName,
              step_action_type: cleanedStepData.StepActionType,
              test_id: cleanedStepData.TestID,
              priority: cleanedStepData.Priority,
            };
            updateTestSteps(obj).then(() => {
              if (!updateShown) {
                toast.success("Test steps updated successfully");
                updateShown = true;
              }
              let row = {
                case_id: testData?.case_id
              }
              handleCloseModal(false);
              navigate(`/testcase`, { state: row });
            })
              .catch((error) => {
                console.error('Error:', error);
              });
          }
        }
        else {
          if (!failedShown) {
            toast.error("Test steps does not contain Test id Please add and continue");
            failedShown = true;
          }
        }
      })
    }

    if (type === "attribute") {
      let successShown = false;
      let failedShown = false;
      let updateShown = false;
      data.map((attributeData) => {
        const cleanedAttributeData = cleanObjectKeys(attributeData);
        if (cleanedAttributeData.StepId) {
          if (!cleanedAttributeData.AttributeId) {
            const obj = {
              attr_xpath: cleanedAttributeData.XPath,
              attr_value: cleanedAttributeData.Value,
              attr_expectedvalue: '',
              step_id: cleanedAttributeData.StepId,
              attr_sleeptime: cleanedAttributeData.Sleeptime
            };
            createAttributes(obj)
              .then(() => {
                if (!successShown) {
                  toast.success("Test attributes uploaded successfully");
                  successShown = true;
                }
                let row = {
                  case_id: testData?.case_id
                }
                handleCloseModal(false);
                navigate(`/testcase`, { state: row });
              })
              .catch((error) => {
                console.error('Error:', error);
              });
          } else {
            successShown = false;
            const obj = {
              id: cleanedAttributeData.AttributeId,
              attr_xpath: cleanedAttributeData.XPath,
              attr_value: cleanedAttributeData.Value,
              attr_expectedvalue: '',
              step_id: cleanedAttributeData.StepId,
              attr_sleeptime: cleanedAttributeData.Sleeptime
            };
            updateAttribute(obj).then(() => {
              if (!updateShown) {
                toast.success("Test attributes updated successfully");
                updateShown = true;
              }
              let row = {
                case_id: testData?.case_id
              }
              handleCloseModal(false);
              navigate(`/testcase`, { state: row });
            })
              .catch((error) => {
                console.error('Error:', error);
              });
          }
        }
        else {
          if (failedShown) {
            toast.error("Test attribute does not contain step id please add and continue");
            failedShown = true;
          }

        }
      })
    }

    if (type === "validation") {
      let successShown = false;
      let failedShown = false;
      let updateShown = false;
      data.map((validationData) => {
        const cleanedValidationData = cleanObjectKeys(validationData);
        if (cleanedValidationData.StepId) {
          if (!cleanedValidationData.ValidationID) {
            const obj = {
              valid_xpath: cleanedValidationData.XPath,
              valid_expectedvalue: cleanedValidationData.Value,
              test_step_id: cleanedValidationData.StepId
            }
            createValidations(obj)
              .then(() => {
                if (!successShown) {
                  toast.success("Test validations uploaded successfully");
                  successShown = true;
                }
                let row = {
                  case_id: testData?.case_id
                }
                handleCloseModal(false);
                navigate(`/testcase`, { state: row });
              })
              .catch((error) => {
                console.error('Error:', error);
              });
          } else {
            successShown = false;
            const obj = {
              id: cleanedValidationData.ValidationID,
              valid_xpath: cleanedValidationData.XPath,
              valid_expectedvalue: cleanedValidationData.Value,
              test_step_id: cleanedValidationData.StepId,
            };
            updateValidations(obj).then(() => {
              if (!updateShown) {
                toast.success("Test validations updated successfully");
                updateShown = true;
              }
              let row = {
                case_id: testData?.case_id
              }
              handleCloseModal(false);
              navigate(`/testcase`, { state: row });
            })
              .catch((error) => {
                console.error('Error:', error);
              });
          }

        }
        else {
          if (failedShown) {
            toast.error("Test validation does not contain step id please add and continue");
            failedShown = true;
          }
        }
      })
    }
  }

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className="modalContainer">
        <Box
          component="div"
          className="modalContent"
          noValidate
          autoComplete="off"
        >
          <div className="modal-header">
            <h4 className='label-bold'>Upload {type === "testStep" ? "Teststep" : type}</h4>
            <button type="button" className="btn-close" onClick={handleCloseModal}></button> 
          </div>

          <div className="modal-body">
            <div className="modalForm">
              <div className="modalFormItem">     
                    <div className='upload-container'>
                      <label htmlFor="file-upload" className="upload-label">
                        <BackupIcon className="upload-icon" />
                        <span>Upload documents from here</span>
                      </label>
                      <input id="file-upload" type="file" className="file-input"  ref={fileInputRef} onChange={type === "testStep" ? handleFileForStepData : handleFile} />
                    </div>
                <br>
                </br>
               {/* { uploadedFile && <Card>
                  {uploadedFile}
                  
                </Card> } */}
                  
                {/* <input type="file" onChange={handleFile} /> */}
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <Button sx={{ float: 'right' }} onClick={onSubmit} disabled={fileInputRef?.current?.value === ''}>Submit</Button> 
            <Button colorStyle='cancel' onClick={handleCloseModal} >Close</Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {
  createTestSteps,
  getTestStepsList,
  createAttributes,
  getAttributes,
  createValidations,
  getValidations,
  updateTestSteps,
  updateAttribute,
  updateValidations
};

export default connect(mapStateToProps, mapDispatchToProps)(Uploader);

