import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

export const formatDate = (date) => {
  // Retrieve configuration from local storage
  const authConfig = JSON.parse(localStorage.getItem('authentication'));
  const formatStr = authConfig ? authConfig['date_time_format'] : 'YYYY-MM-DD HH:mm:ss'; // Default format
  const timezone = authConfig ? authConfig['timezone'] : 'UTC'; // Default timezone

  if (date && formatStr && timezone) {
    const parsedDate = moment(date).tz(timezone);
    return parsedDate.format(formatStr);
  }
  
  return '';
};

const DateFormatter = ({ date }) => {
  const [formattedDate, setFormattedDate] = useState('');

  // useEffect(() => {
  //   const authConfig = JSON.parse(localStorage.getItem('authentication'));
  //   const formatStr = authConfig['date_time_format'];
  //   const timezone = authConfig['timezone']; // Assuming 'timezone' is stored in local storage

  //   if (date && formatStr && timezone) {
  //     const parsedDate = moment(date).tz(timezone);
  //     const newFormattedDate = parsedDate.format(formatStr);
  //     setFormattedDate(newFormattedDate);
  //   }
  // }, [date]);
   useEffect(() => {
    setFormattedDate(formatDate(date))
    
  }, [date]);

  return <div>{formattedDate}</div>;
};

export default DateFormatter;

