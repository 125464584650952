import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";

class CrontabService {
    constructor() {
        this.headers = createHeaders();
     }

    async getCronTabList(page, pageSize, sortColumn, sortDirection,filters) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/crontabschedule?pageNo=${page}&size=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&filters=${filters}`;
            const response = await axios.get(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }


    createCronTab = async (obj) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/crontabschedule`;
            const response = await axios.post(url, obj , this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }


    updateCronTab = async (obj) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/crontabschedule`;
            const response = await axios.put(url,obj, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }


    deleteCronTabJob = async (id) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/crontabschedule/${id}`;
            const response = await axios.delete(url,this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }
}


export default new CrontabService();