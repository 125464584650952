import React, { useEffect, useState,useRef } from 'react';
import { Button, Card, Modal, Link, Typography } from "@mui/material";
import './RunningTestModal.css';
import Box from "@mui/material/Box";
import ENV_VAR from "../common";
import io from "socket.io-client";
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor';
import { modalPopUpStyleLarge } from '../styles/getLPTheme';
import { toast } from 'react-toastify';
const SOCKET_SERVER_URL = `${ENV_VAR.API_HOST}`;

const RunningTestModal = ({ ongoingOperation, setOngoingOperation, isVisible, children, onClose }) => {
  const socketRef = useRef(null);
  const [childrenJson, setChildrenJson] = useState(JSON.parse(children));
  const [Image, setImage] = useState(childrenJson?.Image);
  const [progress, setProgress] = useState(childrenJson?.progress);
  let [items, setItems] = useState(childrenJson?.items);
  const [Stoptask, setStoptask] = useState(childrenJson?.Stoptask);
  const [Message, setMessage] = useState();
  const [isMinimized, setIsMinimized] = useState(false);

  const getText = (items) => {
    const cleanedItems = items?.replace(/running Step :/g, 'Step :');
    const lines = cleanedItems?.split('</li>');
    const lastItem = lines[lines.length - 2];
    const startIndex = lastItem?.lastIndexOf('>') + 1;
    const liText = lastItem?.substring(startIndex)?.trim();
    setMessage(liText);
  };

  useEffect(() => {
    // Initialize socket connection
    socketRef.current = io(SOCKET_SERVER_URL, {
      withCredentials: true,
    });

    const handleImgToReact = (data) => {
     
      if (childrenJson?.taskId === data.data[1]) {
        setImage("data:image/png;base64, " + data.data[0]);
      }
    };

    const handleMessageToReact = (data) => {
      if (childrenJson?.caseId === data.data[2] && childrenJson?.taskId === data.data[3]){
        setStoptask(data.data[3]);
        setProgress(data.data[0]);
        getText(data.data[1]);
        setItems(data.data[1]);
        if (data.data[0] === 100) {
          socketRef.current.off("message_to_react", handleMessageToReact);
          socketRef.current.off("img_to_react", handleImgToReact);
          socketRef.current.disconnect();
          setOngoingOperation(null);
          
        }
      }
    };
// Set up event listeners
socketRef.current.on("img_to_react", handleImgToReact);
socketRef.current.on("message_to_react", handleMessageToReact);

// Clean up on unmount
return () => {
  if (socketRef.current) {
    socketRef.current.off("img_to_react", handleImgToReact);
    socketRef.current.off("message_to_react", handleMessageToReact);
    socketRef.current.disconnect();
  }
};
}, [childrenJson?.taskId , childrenJson?.caseId, SOCKET_SERVER_URL]);
   

  useEffect(() => {
    if (children) {
      getText(childrenJson?.items);
      setOngoingOperation(JSON.stringify({ ...childrenJson, Image: Image, progress: progress, items: items }));
    } 
  }, [Image, children]);

  useEffect(() => {
    if (progress===100) {
      toast.success("Job completed successfully!");
    }
  }, [progress]);

  if (!isVisible) return null;
  return (
    <div className="running-modal">
      <div className="running-modal-content">
        <span className="running-modal-close" onClick={() => setIsMinimized(!isMinimized)}>
          {isMinimized ? '▲' : '▼'}
        </span>
        {!isMinimized && (
          <Box noValidate autoComplete="off">
            <div className="modal-header">
              {Image ? (
                <img src={Image} alt="Screenshot" style={{ maxWidth: '100%', height: '100%' }} />
              ) : (
                <Typography variant="body2">Image loading...</Typography>
              )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="body1" style={{ fontSize: '0.9em' }}>{Message}</Typography>
              <Typography gutterBottom textAlign="right" variant="h5">
                <Button onClick={onClose} className="modalButton modalCloseButton"><ScreenshotMonitorIcon /></Button>
              </Typography>
            </div>
          </Box>
        )}
        {isMinimized && (
          <Box noValidate autoComplete="off">
            <Typography variant="body1" style={{ fontSize: '0.9em' }}>{Message}</Typography>
          </Box>
        )}
      </div>
    </div>
  );
};

export default RunningTestModal;
