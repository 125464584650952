import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";

class ExecutionService {
    constructor() {
        this.headers = createHeaders();
     }

    async getSuiteExecutionReport(suite_id) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/suiteExecutionReport/${suite_id}`;
            const response = await axios.get(url, this.headers);

            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }
}


export default new ExecutionService();