import { Button, Card } from "@mui/material";
import { connect } from "react-redux";
import React, { useEffect, useState} from "react";
import { useLocation, UseState, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import "../styles/project.css";
import CeleryService from "../services/restartCelery.service"
import { toast } from "react-toastify";
import showConfirmationAlert from "../components/Confirm";

export const CeleryRestart = () => {
  const [Celery_name,setCeleryName]= useState(null)

  const startJobApi = () => {

    showConfirmationAlert('Are you sure you want to proceed?')
    .then((result) => {
    if (result.isConfirmed) {
      toast.success('Celery restart Initiated.');
        CeleryService.restartCelery(Celery_name)
          .then(response => {
            // Handle success
            if (response.data?.message) {
              toast.success(response.data.message);
            }
          })
          .catch(error => {
            // Handle error
            console.error(error);
            toast.error('Failed to restart Celery.');
          });
    } else if (result.isDismissed) {
      toast.info('Celery restart cancelled.');
    }
  });
    
    // Swal.fire({
    //   title: 'Are you sure?',
    //   text: 'Do you really want to restart Celery?',
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#34B1AA',
    //   cancelButtonColor: '#f44336c4',
    //   confirmButtonText: 'Yes, restart it!',
    //   cancelButtonText: 'No, cancel!',
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     toast.success('Celery restart Initiated.');
    //     CeleryService.restartCelery(Celery_name)
    //       .then(response => {
    //         // Handle success
    //         if (response.data?.message) {
    //           toast.success(response.data.message);
    //         }
    //       })
    //       .catch(error => {
    //         // Handle error
    //         console.error(error);
    //         toast.error('Failed to restart Celery.');
    //       });
    //   } else {
    //     toast.info('Celery restart cancelled.');
    //   }
    // });
  };
 
  return (
    <div className="marginTop">
      
      <Card className="padding-runJob marginTop">
      <h3 className="label-bold">Celery</h3>
      <hr />

        <Box>
          <Button onClick={startJobApi} >Restart Celery</Button>
        </Box>
      </Card>  
    </div>
  );
};

export default connect()(CeleryRestart);
