import { SUITE_EXECUTION_REPORT } from "./types";
import ExecutionService from "../../services/executionReport.service";
import { toast } from 'react-toastify';

export const getExecutionReport = (id) => async (dispatch) => {
  try {
    let res = await ExecutionService.getSuiteExecutionReport(id);
    if (res.status === 200) {
      dispatch({
        type: SUITE_EXECUTION_REPORT,
        payload: res.data
      });
    } else {
      toast.error("Unable to fetch execution report : " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};


