import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";


class testCasesService {
    constructor() {
        this.headers = createHeaders();
    }

    async getTestCases(projId) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/test_case_projects/${projId}`;
            const response = await axios.get(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    async getAllTestCases(page, pageSize, sortColumn, sortDirection,filters,type) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/test_case?pageNo=${page}&size=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&filters=${filters}&type=${type}`;
            const response = await axios.get(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    async getTestCaseById(test_case_id) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/test_case/${test_case_id}`;
            const response = await axios.get(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    async createNewTestcase(obj) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/test_case`;
            const response = await axios.post(url, obj, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    async updateTestCase(obj) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/test_case`;
            const response = await axios.put(url, obj, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }


    async deleteTestCase(obj) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/test_case/${obj.id}`;
            const response = await axios.delete(url, obj, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }
}

export default new testCasesService();