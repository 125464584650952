//https://www.timeanddate.com/worldclock/converter.html?iso=20210101T030000
window.timeZoneList = () => [
    {"id":"1_+00:00","value":"+00:00","tzv":"+0000", "key":"Abidjan", "type":"Africa","url":"ci","country":"Cote d' Ivoire (Ivory Coast)"},
    {"id":"2_+00:00","value":"+00:00","tzv":"+0000", "key":"Accra", "type":"Africa","url":"gh","country":"Ghana"},
    {"id":"3_+03:00","value":"+03:00","tzv":"+0300", "key":"Addis_Ababa", "type":"Africa","url":"et","country":"Ethiopia"},
    {"id":"4_+01:00","value":"+01:00","tzv":"+0100", "key":"Algiers", "type":"Africa","url":"dz","country":"Algeria"},
    {"id":"5_+03:00","value":"+03:00","tzv":"+0300", "key":"Asmara", "type":"Africa","url":"er","country":"Eritrea"},
    {"id":"6_+00:00","value":"+00:00","tzv":"+0000", "key":"Bamako", "type":"Africa","url":"ml","country":"Mali"},
    {"id":"7_+01:00","value":"+01:00","tzv":"+0100", "key":"Bangui", "type":"Africa","url":"cf","country":"Central African Republic"},
    {"id":"8_+00:00","value":"+00:00","tzv":"+0000", "key":"Banjul", "type":"Africa","url":"gm","country":"Gambia"},
    {"id":"9_+00:00","value":"+00:00","tzv":"+0000", "key":"Bissau", "type":"Africa","url":"gw","country":"Guinea-Bissau"},
    {"id":"10_+02:00","value":"+02:00","tzv":"+0200", "key":"Blantyre", "type":"Africa","url":"mw","country":"Malawi"},
    {"id":"11_+01:00","value":"+01:00","tzv":"+0100", "key":"Brazzaville", "type":"Africa","url":"cg","country":"Congo"},
    {"id":"12_+02:00","value":"+02:00","tzv":"+0200", "key":"Bujumbura", "type":"Africa","url":"bi","country":"Burundi"},
    {"id":"13_+02:00","value":"+02:00","tzv":"+0200", "key":"Cairo", "type":"Africa","url":"eg","country":"Egypt"},
    {"id":"14_+00:00","value":"+00:00","tzv":"+0000", "key":"Casablanca", "type":"Africa","url":"ma","country":"Morocco"},
    {"id":"15_+01:00","value":"+01:00","tzv":"+0100", "key":"Ceuta", "type":"Africa","url":"es","country":"Spain"},
    {"id":"16_+00:00","value":"+00:00","tzv":"+0000", "key":"Conakry", "type":"Africa","url":"gn","country":"Guinea"},
    {"id":"17_+00:00","value":"+00:00","tzv":"+0000", "key":"Dakar", "type":"Africa","url":"sn","country":"Senegal"},
    {"id":"18_+03:00","value":"+03:00","tzv":"+0300", "key":"Dar_es_Salaam", "type":"Africa","url":"tz","country":"Tanzania"},
    {"id":"19_+03:00","value":"+03:00","tzv":"+0300", "key":"Djibouti", "type":"Africa","url":"dj","country":"Djibouti"},
    {"id":"20_+01:00","value":"+01:00","tzv":"+0100", "key":"Douala", "type":"Africa","url":"cm","country":"Cameroon"},
    {"id":"21_+00:00","value":"+00:00","tzv":"+0000", "key":"El_Aaiun", "type":"Africa","url":"eh","country":"Western Sahara"},
    {"id":"22_+00:00","value":"+00:00","tzv":"+0000", "key":"Freetown", "type":"Africa","url":"sl","country":"Sierra Leone"},
    {"id":"23_+02:00","value":"+02:00","tzv":"+0200", "key":"Gaborone", "type":"Africa","url":"bw","country":"Botswana"},
    {"id":"24_+02:00","value":"+02:00","tzv":"+0200", "key":"Harare", "type":"Africa","url":"zw","country":"Zimbabwe"},
    {"id":"25_+02:00","value":"+02:00","tzv":"+0200", "key":"Johannesburg", "type":"Africa","url":"za","country":"South Africa"},
    {"id":"26_+03:00","value":"+03:00","tzv":"+0300", "key":"Juba", "type":"Africa","url":"ss","country":"South Sudan"},
    {"id":"27_+03:00","value":"+03:00","tzv":"+0300", "key":"Kampala", "type":"Africa","url":"ug","country":"Uganda"},
    {"id":"28_+03:00","value":"+03:00","tzv":"+0300", "key":"Khartoum", "type":"Africa","url":"sd","country":"Sudan"},
    {"id":"29_+02:00","value":"+02:00","tzv":"+0200", "key":"Kigali", "type":"Africa","url":"rw","country":"Rwanda"},
    {"id":"30_+01:00","value":"+01:00","tzv":"+0100", "key":"Kinshasa", "type":"Africa","url":"cd","country":"Congo Dem. Rep."},
    {"id":"31_+01:00","value":"+01:00","tzv":"+0100", "key":"Lagos", "type":"Africa","url":"ng","country":"Nigeria"},
    {"id":"32_+01:00","value":"+01:00","tzv":"+0100", "key":"Libreville", "type":"Africa","url":"ga","country":"Gabon"},
    {"id":"33_+00:00","value":"+00:00","tzv":"+0000", "key":"Lome", "type":"Africa","url":"tg","country":"Togo"},
    {"id":"34_+01:00","value":"+01:00","tzv":"+0100", "key":"Luanda", "type":"Africa","url":"cd","country":"Angola"},
    {"id":"35_+02:00","value":"+02:00","tzv":"+0200", "key":"Lubumbashi", "type":"Africa","url":"cd","country":"Congo Dem. Rep."},
    {"id":"36_+02:00","value":"+02:00","tzv":"+0200", "key":"Lusaka", "type":"Africa","url":"zm","country":"Zambia"},
    {"id":"37_+01:00","value":"+01:00","tzv":"+0100", "key":"Malabo", "type":"Africa","url":"gq","country":"Equatorial Guinea"},
    {"id":"38_+02:00","value":"+02:00","tzv":"+0200", "key":"Maputo", "type":"Africa","url":"mz","country":"Mozambique"},
    {"id":"39_+02:00","value":"+02:00","tzv":"+0200", "key":"Maseru", "type":"Africa","url":"ls","country":"Lesotho"},
    {"id":"40_+02:00","value":"+02:00","tzv":"+0200", "key":"Mbabane", "type":"Africa","url":"sz","country":"Eswatini"},
    {"id":"41_+03:00","value":"+03:00","tzv":"+0300", "key":"Mogadishu", "type":"Africa","url":"so","country":"Somalia"},
    {"id":"42_+00:00","value":"+00:00","tzv":"+0000", "key":"Monrovia", "type":"Africa","url":"lr","country":"Liberia"},
    {"id":"43_+03:00","value":"+03:00","tzv":"+0300", "key":"Nairobi", "type":"Africa","url":"ke","country":"Kenya"},
    {"id":"44_+01:00","value":"+01:00","tzv":"+0100", "key":"Ndjamena", "type":"Africa","url":"td","country":"Chad"},
    {"id":"45_+01:00","value":"+01:00","tzv":"+0100", "key":"Niamey", "type":"Africa","url":"ne","country":"Niger"},
    {"id":"46_+00:00","value":"+00:00","tzv":"+0000", "key":"Nouakchott", "type":"Africa","url":"mr","country":"Mauritania"},
    {"id":"47_+00:00","value":"+00:00","tzv":"+0000", "key":"Ouagadougou", "type":"Africa","url":"bf","country":"Burkina Faso"},
    {"id":"48_+01:00","value":"+01:00","tzv":"+0100", "key":"Porto-Novo", "type":"Africa","url":"bj","country":"Benin"},
    {"id":"49_+00:00","value":"+00:00","tzv":"+0000", "key":"Sao_Tome", "type":"Africa","url":"st","country":"Sao Tome and Principe"},
    {"id":"50_+00:00","value":"+00:00","tzv":"+0000", "key":"Timbuktu", "type":"Africa","url":"ml","country":"Mali"},
    {"id":"51_+02:00","value":"+02:00","tzv":"+0200", "key":"Tripoli", "type":"Africa","url":"ly","country":"Libya"},
    {"id":"52_+01:00","value":"+01:00","tzv":"+0100", "key":"Tunis", "type":"Africa","url":"tn","country":"Tunisia"},
    {"id":"53_+01:00","value":"+01:00","tzv":"+0100", "key":"Windhoek", "type":"Africa","url":"na","country":"Namibia"},
    {"id":"54_-10:00","value":"-10:00","tzv":"-1000", "key":"Adak","type":"America","url":"us","country":"USA"},
    {"id":"55_-09:00","value":"-09:00","tzv":"-0900", "key":"Anchorage","type":"America","url":"us","country":"USA"},
    {"id":"56_-04:00","value":"-04:00","tzv":"-0400", "key":"Anguilla","type":"America","url":"ai","country":"Anguilla"},
    {"id":"57_-04:00","value":"-04:00","tzv":"-0400", "key":"Antigua","type":"America","url":"ag","country":"Antigua and Barbuda"},
    {"id":"58_-03:00","value":"-03:00","tzv":"-0300", "key":"Araguaina","type":"America","url":"br","country":"Brazil"},
    {"id":"59_-03:00","value":"-03:00","tzv":"-0300", "key":"Buenos Aires","type":"Argentina","url":"ar","country":"Argentina"},
    {"id":"60_-03:00","value":"-03:00","tzv":"-0300", "key":"Catamarca","type":"Argentina","url":"ar","country":"Argentina"},
    {"id":"61_-03:00","value":"-03:00","tzv":"-0300", "key":"Comod Rivadavia","type":"Argentina","url":"ar","country":"Argentina"},
    {"id":"62_-03:00","value":"-03:00","tzv":"-0300", "key":"Cordoba","type":"Argentina","url":"ar","country":"Argentina"},
    {"id":"63_-03:00","value":"-03:00","tzv":"-0300", "key":"Jujuy","type":"Argentina","url":"ar","country":"Argentina"},
    {"id":"64_-03:00","value":"-03:00","tzv":"-0300", "key":"La Rioja","type":"Argentina","url":"ar","country":"Argentina"},
    {"id":"65_-03:00","value":"-03:00","tzv":"-0300", "key":"Mendoza","type":"Argentina","url":"ar","country":"Argentina"},
    {"id":"66_-03:00","value":"-03:00","tzv":"-0300", "key":"Rio Gallegos","type":"Argentina","url":"ar","country":"Argentina"},
    {"id":"67_-03:00","value":"-03:00","tzv":"-0300", "key":"Salta","type":"Argentina","url":"ar","country":"Argentina"},
    {"id":"68_-03:00","value":"-03:00","tzv":"-0300", "key":"San Juan","type":"Argentina","url":"ar","country":"Argentina"},
    {"id":"69_-03:00","value":"-03:00","tzv":"-0300", "key":"San Luis","type":"Argentina","url":"ar","country":"Argentina"},
    {"id":"70_-03:00","value":"-03:00","tzv":"-0300", "key":"Tucuman","type":"Argentina","url":"ar","country":"Argentina"},
    {"id":"71_-03:00","value":"-03:00","tzv":"-0300", "key":"Ushuaia","type":"Argentina","url":"ar","country":"Argentina"},
    {"id":"72_-04:00","value":"-04:00","tzv":"-0400", "key":"Aruba","type":"America","url":"aw","country":"Aruba"},
    {"id":"73_-04:00","value":"-04:00","tzv":"-0400", "key":"Asuncion","type":"America","url":"py","country":"Paraguay"},
    {"id":"74_-05:00","value":"-05:00","tzv":"-0500", "key":"Atikokan","type":"America","url":"ca","country":"Canada"},
    {"id":"75_-10:00","value":"-10:00","tzv":"-1000", "key":"Atka","type":"America","url":"us","country":"USA"},
    {"id":"76_-03:00","value":"-03:00","tzv":"-0300", "key":"Bahia","type":"America","url":"br","country":"Brazil"},
    {"id":"77_-06:00","value":"-06:00","tzv":"-0600", "key":"Bahia Banderas","type":"America","url":"ar","country":"Argentina"},
    {"id":"78_-04:00","value":"-04:00","tzv":"-0400", "key":"Barbados","type":"America","url":"bb","country":"Barbados"},
    {"id":"79_-03:00","value":"-03:00","tzv":"-0300", "key":"Belem","type":"America","url":"br","country":"Brazil"},
    {"id":"80_-06:00","value":"-06:00","tzv":"-0600", "key":"Belize","type":"America","url":"bz","country":"Belize"},
    {"id":"81_-04:00","value":"-04:00","tzv":"-0400", "key":"Blanc-Sablon","type":"America","url":"ca","country":"Canada"},
    {"id":"82_-04:00","value":"-04:00","tzv":"-0400", "key":"Boa_Vista","type":"America","url":"br","country":"Brazil"},
    {"id":"83_-05:00","value":"-05:00","tzv":"-0500", "key":"Bogota","type":"America","url":"co","country":"Colombia"},
    {"id":"84_-07:00","value":"-07:00","tzv":"-0700", "key":"Boise","type":"America","url":"us","country":"USA"},
    {"id":"85_-03:00","value":"-03:00","tzv":"-0300", "key":"Buenos_Aires","type":"America","url":"ar","country":"Argentina"},
    {"id":"86_-07:00","value":"-07:00","tzv":"-0700", "key":"Cambridge_Bay","type":"America","url":"ca","country":"Canada"},
    {"id":"87_-04:00","value":"-04:00","tzv":"-0400", "key":"Campo_Grande","type":"America","url":"br","country":"Brazil"},
    {"id":"88_-05:00","value":"-05:00","tzv":"-0500", "key":"Cancun","type":"America","url":"mx","country":"Mexico"},
    {"id":"89_-04:30","value":"-04:30","tzv":"-0430", "key":"Caracas","type":"America","url":"ve","country":"Venezuela"},
    {"id":"90_-03:00","value":"-03:00","tzv":"-0300", "key":"Catamarca","type":"America","url":"ar","country":"Argentina"},
    {"id":"91_-03:00","value":"-03:00","tzv":"-0300", "key":"Cayenne","type":"America","url":"gf","country":"French Guiana"},
    {"id":"92_-05:00","value":"-05:00","tzv":"-0500", "key":"Cayman","type":"America","url":"ky","country":"Cayman Islands"},
    {"id":"93_-06:00","value":"-06:00","tzv":"-0600", "key":"Chicago","type":"America","url":"us","country":"USA"},
    {"id":"94_-07:00","value":"-07:00","tzv":"-0700", "key":"Chihuahua","type":"America","url":"mx","country":"Mexico"},
    {"id":"95_-05:00","value":"-05:00","tzv":"-0500", "key":"Coral_Harbour","type":"America","url":"ca","country":"Canada"},
    {"id":"96_-03:00","value":"-03:00","tzv":"-0300", "key":"Cordoba","type":"America","url":"ar","country":"Argentina"},
    {"id":"97_-06:00","value":"-06:00","tzv":"-0600", "key":"Costa_Rica","type":"America","url":"cr","country":"Costa Rica"},
    {"id":"98_-07:00","value":"-07:00","tzv":"-0700", "key":"Creston","type":"America","url":"ca","country":"Canada"},
    {"id":"99_-04:00","value":"-04:00","tzv":"-0400", "key":"Cuiaba","type":"America","url":"br","country":"Brazil"},
    {"id":"100_-04:00","value":"-04:00","tzv":"-0400", "key":"Curacao","type":"America","url":"cw","country":"Curaçao"},
    {"id":"101_+00:00","value":"+00:00","tzv":"+0000", "key":"Danmarkshavn","type":"America","url":"gl","country":"Greenland"},
    {"id":"102_-08:00","value":"-08:00","tzv":"-0800", "key":"Dawson","type":"America","url":"ca","country":"Canada"},
    {"id":"103_-07:00","value":"-07:00","tzv":"-0700", "key":"Dawson_Creek","type":"America","url":"ca","country":"Canada"},
    {"id":"104_-07:00","value":"-07:00","tzv":"-0700", "key":"Denver","type":"America","url":"us","country":"USA"},
    {"id":"105_-05:00","value":"-05:00","tzv":"-0500", "key":"Detroit","type":"America","url":"us","country":"USA"},
    {"id":"106_-04:00","value":"-04:00","tzv":"-0400", "key":"Dominica","type":"America","url":"dm","country":"Dominica"},
    {"id":"107_-07:00","value":"-07:00","tzv":"-0700", "key":"Edmonton","type":"America","url":"ca","country":"Canada"},
    {"id":"108_-05:00","value":"-05:00","tzv":"-0500", "key":"Eirunepe","type":"America","url":"br","country":"Brazil"},
    {"id":"109_-06:00","value":"-06:00","tzv":"-0600", "key":"El_Salvador","type":"America","url":"sv","country":"El Salvador"},
    {"id":"110_-08:00","value":"-08:00","tzv":"-0800", "key":"Ensenada","type":"America","url":"mx","country":"Mexico"},
    {"id":"111_-07:00","value":"-07:00","tzv":"-0700", "key":"Fort_Nelson","type":"America","url":"ca","country":"Canada"},
    {"id":"112_-05:00","value":"-05:00","tzv":"-0500", "key":"Fort_Wayne","type":"America","url":"us","country":"USA"},
    {"id":"113_-03:00","value":"-03:00","tzv":"-0300", "key":"Fortaleza","type":"America","url":"br","country":"Brazil"},
    {"id":"114_-04:00","value":"-04:00","tzv":"-0400", "key":"Glace_Bay","type":"America","url":"ca","country":"Canada"},
    {"id":"115_-03:00","value":"-03:00","tzv":"-0300", "key":"Godthab","type":"America","url":"gl","country":"Greenland"},
    {"id":"116_-04:00","value":"-04:00","tzv":"-0400", "key":"Goose_Bay","type":"America","url":"ca","country":"Canada"},
    {"id":"117_-04:00","value":"-04:00","tzv":"-0400", "key":"Grand_Turk","type":"America","url":"tc","country":"Turks and Caicos Islands"},
    {"id":"118_-04:00","value":"-04:00","tzv":"-0400", "key":"Grenada","type":"America","url":"gd","country":"Grenada"},
    {"id":"119_-04:00","value":"-04:00","tzv":"-0400", "key":"Guadeloupe","type":"America","url":"gp","country":"Guadeloupe"},
    {"id":"120_-06:00","value":"-06:00","tzv":"-0600", "key":"Guatemala","type":"America","url":"gt","country":"Guatemala"},
    {"id":"121_-05:00","value":"-05:00","tzv":"-0500", "key":"Guayaquil","type":"America","url":"ec","country":"Ecuador"},
    {"id":"122_-04:00","value":"-04:00","tzv":"-0400", "key":"Guyana","type":"America","url":"gy","country":"Guyana"},
    {"id":"123_-04:00","value":"-04:00","tzv":"-0400", "key":"Halifax","type":"America","url":"ca","country":"Canada"},
    {"id":"124_-05:00","value":"-05:00","tzv":"-0500", "key":"Havana","type":"America","url":"cu","country":"Cuba"},
    {"id":"125_-07:00","value":"-07:00","tzv":"-0700", "key":"Hermosillo","type":"America","url":"mx","country":"Mexico"},
    {"id":"126_-05:00","value":"-05:00","tzv":"-0500", "key":"Indianapolis","type":"Indiana","url":"us","country":"USA"},
    {"id":"127_-06:00","value":"-06:00","tzv":"-0600", "key":"Knox","type":"Indiana","url":"us","country":"USA"},
    {"id":"128_-05:00","value":"-05:00","tzv":"-0500", "key":"Marengo","type":"Indiana","url":"us","country":"USA"},
    {"id":"129_-05:00","value":"-05:00","tzv":"-0500", "key":"Petersburg","type":"Indiana","url":"us","country":"USA"},
    {"id":"130_-06:00","value":"-06:00","tzv":"-0600", "key":"Tell_City","type":"Indiana","url":"us","country":"USA"},
    {"id":"131_-05:00","value":"-05:00","tzv":"-0500", "key":"Vevay","type":"Indiana","url":"us","country":"USA"},
    {"id":"132_-05:00","value":"-05:00","tzv":"-0500", "key":"Vincennes","type":"Indiana","url":"us","country":"USA"},
    {"id":"133_-05:00","value":"-05:00","tzv":"-0500", "key":"Winamac","type":"Indiana","url":"us","country":"USA"},
    {"id":"134_-05:00","value":"-05:00","tzv":"-0500", "key":"Indianapolis","type":"America","url":"us","country":"USA"},
    {"id":"135_-07:00","value":"-07:00","tzv":"-0700", "key":"Inuvik","type":"America","url":"ca","country":"Canada"},
    {"id":"136_-05:00","value":"-05:00","tzv":"-0500", "key":"Iqaluit","type":"America","url":"ca","country":"Canada"},
    {"id":"137_-05:00","value":"-05:00","tzv":"-0500", "key":"Jamaica","type":"America","url":"jm","country":"Jamaica"},
    {"id":"138_-03:00","value":"-03:00","tzv":"-0300", "key":"Jujuy","type":"America","url":"ar","country":"Argentina"},
    {"id":"139_-09:00","value":"-09:00","tzv":"-0900", "key":"Juneau","type":"America","url":"us","country":"USA"},
    {"id":"140_-05:00","value":"-05:00","tzv":"-0500", "key":"Kentucky/Louisville","type":"America","url":"us","country":"USA"},
    {"id":"141_-05:00","value":"-05:00","tzv":"-0500", "key":"Kentucky/Monticello","type":"America","url":"us","country":"USA"},
    {"id":"142_-06:00","value":"-06:00","tzv":"-0600", "key":"Knox_IN","type":"America","url":"us","country":"USA"},
    {"id":"143_-04:00","value":"-04:00","tzv":"-0400", "key":"Kralendijk","type":"America","url":"nl","country":"Caribbean Netherlands"},
    {"id":"144_-04:00","value":"-04:00","tzv":"-0400", "key":"La_Paz","type":"America","url":"bo","country":"Bolivia"},
    {"id":"145_-05:00","value":"-05:00","tzv":"-0500", "key":"Lima","type":"America","url":"pe","country":"Peru"},
    {"id":"146_-08:00","value":"-08:00","tzv":"-0800", "key":"Los Angeles","type":"America","url":"us","country":"USA"},
    {"id":"147_-05:00","value":"-05:00","tzv":"-0500", "key":"Louisville","type":"America","url":"us","country":"USA"},
    {"id":"148_-04:00","value":"-04:00","tzv":"-0400", "key":"Lower Princes","type":"America","url":"us","country":"USA"},
    {"id":"149_-03:00","value":"-03:00","tzv":"-0300", "key":"Maceio","type":"America","url":"br","country":"Brazil"},
    {"id":"150_-06:00","value":"-06:00","tzv":"-0600", "key":"Managua","type":"America","url":"ni","country":"Nicaragua"},
    {"id":"151_-04:00","value":"-04:00","tzv":"-0400", "key":"Manaus","type":"America","url":"br","country":"Brazil"},
    {"id":"152_-04:00","value":"-04:00","tzv":"-0400", "key":"Marigot","type":"America","url":"gp","country":"Saint Martin"},
    {"id":"153_-04:00","value":"-04:00","tzv":"-0400", "key":"Martinique","type":"America","url":"mq","country":"Martinique"},
    {"id":"154_-06:00","value":"-06:00","tzv":"-0600", "key":"Matamoros","type":"America","url":"mx","country":"Mexico"},
    {"id":"155_-07:00","value":"-07:00","tzv":"-0700", "key":"Mazatlan","type":"America","url":"mx","country":"Mexico"},
    {"id":"156_-03:00","value":"-03:00","tzv":"-0300", "key":"Mendoza","type":"America","url":"ar","country":"Argentina"},
    {"id":"157_-06:00","value":"-06:00","tzv":"-0600", "key":"Menominee","type":"America","url":"us","country":"USA"},
    {"id":"158_-06:00","value":"-06:00","tzv":"-0600", "key":"Merida","type":"America","url":"mx","country":"Mexico"},
    {"id":"159_-09:00","value":"-09:00","tzv":"-0900", "key":"Metlakatla","type":"America","url":"us","country":"USA"},
    {"id":"160_-06:00","value":"-06:00","tzv":"-0600", "key":"Mexico_City","type":"America","url":"mx","country":"Mexico"},
    {"id":"161_-03:00","value":"-03:00","tzv":"-0300", "key":"Miquelon","type":"America","url":"pm","country":"Saint Pierre and Miquelon"},
    {"id":"162_-04:00","value":"-04:00","tzv":"-0400", "key":"Moncton","type":"America","url":"ca","country":"Canada"},
    {"id":"163_-06:00","value":"-06:00","tzv":"-0600", "key":"Monterrey","type":"America","url":"mx","country":"Mexico"},
    {"id":"164_-03:00","value":"-03:00","tzv":"-0300", "key":"Montevideo","type":"America","url":"uy","country":"Uruguay"},
    {"id":"165_-05:00","value":"-05:00","tzv":"-0500", "key":"Montreal","type":"America","url":"ca","country":"Canada"},
    {"id":"166_-04:00","value":"-04:00","tzv":"-0400", "key":"Montserrat","type":"America","url":"ms","country":"Montserrat"},
    {"id":"167_-05:00","value":"-05:00","tzv":"-0500", "key":"Nassau","type":"America","url":"bs","country":"Bahamas"},
    {"id":"168_-05:00","value":"-05:00","tzv":"-0500", "key":"New_York","type":"America","url":"us","country":"USA"},
    {"id":"169_-05:00","value":"-05:00","tzv":"-0500", "key":"Nipigon","type":"America","url":"ca","country":"Canada"},
    {"id":"170_-09:00","value":"-09:00","tzv":"-0900", "key":"Nome","type":"America","url":"us","country":"USA"},
    {"id":"171_-02:00","value":"-02:00","tzv":"-0200", "key":"Noronha","type":"America","url":"br","country":"Brazil"},
    {"id":"172_-06:00","value":"-06:00","tzv":"-0600", "key":"North_Dakota/Beulah","type":"America","url":"us","country":"USA"},
    {"id":"173_-06:00","value":"-06:00","tzv":"-0600", "key":"North_Dakota/Center","type":"America","url":"us","country":"USA"},
    {"id":"174_-06:00","value":"-06:00","tzv":"-0600", "key":"North_Dakota/New_Salem","type":"America","url":"us","country":"USA"},
    {"id":"175_-07:00","value":"-07:00","tzv":"-0700", "key":"Ojinaga","type":"America","url":"mx","country":"Mexico"},
    {"id":"176_-05:00","value":"-05:00","tzv":"-0500", "key":"Panama","type":"America","url":"pa","country":"Panama"},
    {"id":"177_-05:00","value":"-05:00","tzv":"-0500", "key":"Pangnirtung","type":"America","url":"ca","country":"Canada"},
    {"id":"178_-03:00","value":"-03:00","tzv":"-0300", "key":"Paramaribo","type":"America","url":"sr","country":"Suriname"},
    {"id":"179_-07:00","value":"-07:00","tzv":"-0700", "key":"Phoenix","type":"America","url":"us","country":"USA"},
    {"id":"180_-05:00","value":"-05:00","tzv":"-0500", "key":"Port-au-Prince","type":"America","url":"ht","country":"Haiti"},
    {"id":"181_-04:00","value":"-04:00","tzv":"-0400", "key":"Port_of_Spain","type":"America","url":"tt","country":"Trinidad and Tobago"},
    {"id":"182_-05:00","value":"-05:00","tzv":"-0500", "key":"Porto_Acre","type":"America","url":"br","country":"Brazil"},
    {"id":"183_-04:00","value":"-04:00","tzv":"-0400", "key":"Porto_Velho","type":"America","url":"br","country":"Brazil"},
    {"id":"184_-04:00","value":"-04:00","tzv":"-0400", "key":"Puerto_Rico","type":"America","url":"pr","country":"Puerto Rico"},
    {"id":"185_-06:00","value":"-06:00","tzv":"-0600", "key":"Rainy_River","type":"America","url":"ca","country":"Canada"},
    {"id":"186_-06:00","value":"-06:00","tzv":"-0600", "key":"Rankin_Inlet","type":"America","url":"ca","country":"Canada"},
    {"id":"187_-03:00","value":"-03:00","tzv":"-0300", "key":"Recife","type":"America","url":"br","country":"Brazil"},
    {"id":"188_-06:00","value":"-06:00","tzv":"-0600", "key":"Regina","type":"America","url":"ca","country":"Canada"},
    {"id":"189_-06:00","value":"-06:00","tzv":"-0600", "key":"Resolute","type":"America","url":"ca","country":"Canada"},
    {"id":"190_-05:00","value":"-05:00","tzv":"-0500", "key":"Rio Branco","type":"America","url":"br","country":"Brazil"},
    {"id":"191_-03:00","value":"-03:00","tzv":"-0300", "key":"Rosario","type":"America","url":"ar","country":"Argentina"},
    {"id":"192_-08:00","value":"-08:00","tzv":"-0800", "key":"Santa Isabel","type":"America","url":"ph","country":"Philippines"},
    {"id":"193_-03:00","value":"-03:00","tzv":"-0300", "key":"Santarem","type":"America","url":"br","country":"Brazil"},
    {"id":"194_-03:00","value":"-03:00","tzv":"-0300", "key":"Santiago","type":"America","url":"cl","country":"Chile"},
    {"id":"195_-04:00","value":"-04:00","tzv":"-0400", "key":"Santo Domingo","type":"America","url":"do","country":"Dominican Republic"},
    {"id":"196_-03:00","value":"-03:00","tzv":"-0300", "key":"Sao Paulo","type":"America","url":"br","country":"Brazil"},
    {"id":"197_-01:00","value":"-01:00","tzv":"-0100", "key":"Scoresbysund","type":"America","url":"gl","country":"Greenland"},
    {"id":"198_-07:00","value":"-07:00","tzv":"-0700", "key":"Shiprock","type":"America","url":"us","country":"USA"},
    {"id":"199_-09:00","value":"-09:00","tzv":"-0900", "key":"Sitka","type":"America","url":"us","country":"USA"},
    {"id":"200_-04:00","value":"-04:00","tzv":"-0400", "key":"St Barthelemy","type":"America","url":"fr","country":"Saint Barthélemy"},
    {"id":"201_-03:30","value":"-03:30","tzv":"-0330", "key":"St_Johns","type":"America","url":"ca","country":"Canada"},
    {"id":"202_-04:00","value":"-04:00","tzv":"-0400", "key":"St_Kitts","type":"America","url":"kn","country":"Saint Kitts and Nevis"},
    {"id":"203_-04:00","value":"-04:00","tzv":"-0400", "key":"St_Lucia","type":"America","url":"lc","country":"Saint Lucia"},
    {"id":"204_-04:00","value":"-04:00","tzv":"-0400", "key":"St_Thomas","type":"America","url":"ca","country":"Canada"},
    {"id":"205_-04:00","value":"-04:00","tzv":"-0400", "key":"St_Vincent","type":"America","url":"vc","country":"Saint Vincent and Grenadines"},
    {"id":"206_-06:00","value":"-06:00","tzv":"-0600", "key":"Swift_Current","type":"America","url":"ca","country":"Canada"},
    {"id":"207_-06:00","value":"-06:00","tzv":"-0600", "key":"Tegucigalpa","type":"America","url":"hn","country":"Honduras"},
    {"id":"208_-04:00","value":"-04:00","tzv":"-0400", "key":"Thule","type":"America","url":"gl","country":"Greenland"},
    {"id":"209_-05:00","value":"-05:00","tzv":"-0500", "key":"Thunder Bay","type":"America","url":"ca","country":"Canada"},
    {"id":"210_-08:00","value":"-08:00","tzv":"-0800", "key":"Tijuana","type":"America","url":"mx","country":"Mexico"},
    {"id":"211_-05:00","value":"-05:00","tzv":"-0500", "key":"Toronto","type":"America","url":"ca","country":"Canada"},
    {"id":"212_-04:00","value":"-04:00","tzv":"-0400", "key":"Tortola","type":"America","url":"vg","country":"British Virgin Islands"},
    {"id":"213_-08:00","value":"-08:00","tzv":"-0800", "key":"Vancouver","type":"America","url":"ca","country":"Canada"},
    {"id":"214_-04:00","value":"-04:00","tzv":"-0400", "key":"Virgin","type":"America","url":"us","country":"USA"},
    {"id":"215_-08:00","value":"-08:00","tzv":"-0800", "key":"Whitehorse","type":"America","url":"ca","country":"Canada"},
    {"id":"216_-06:00","value":"-06:00","tzv":"-0600", "key":"Winnipeg","type":"America","url":"ca","country":"Canada"},
    {"id":"217_-09:00","value":"-09:00","tzv":"-0900", "key":"Yakutat","type":"America","url":"us","country":"USA"},
    {"id":"218_-07:00","value":"-07:00","tzv":"-0700", "key":"Yellowknife","type":"America","url":"ca","country":"Canada"},
    {"id":"219_+08:00","value":"+08:00","tzv":"+0800", "key":"Casey","type":"Antarctica","url":"aq","country":"Antarctica"},
    {"id":"220_+07:00","value":"+07:00","tzv":"+0700", "key":"Davis","type":"Antarctica","url":"aq","country":"Antarctica"},
    {"id":"221_+10:00","value":"+10:00","tzv":"+1000", "key":"DumontDUrville","type":"Antarctica","url":"aq","country":"Antarctica"},
    {"id":"222_+11:00","value":"+11:00","tzv":"+1100", "key":"Macquarie","type":"Antarctica","url":"au","country":"Australia"},
    {"id":"223_+05:00","value":"+05:00","tzv":"+0500", "key":"Mawson","type":"Antarctica","url":"aq","country":"Antarctica"},
    {"id":"224_+12:00","value":"+12:00","tzv":"+1200", "key":"McMurdo","type":"Antarctica","url":"aq","country":"Antarctica"},
    {"id":"225_-03:00","value":"-03:00","tzv":"-0300", "key":"Palmer","type":"Antarctica","url":"aq","country":"Antarctica"},
    {"id":"226_-03:00","value":"-03:00","tzv":"-0300", "key":"Rothera","type":"Antarctica","url":"aq","country":"Antarctica"},
    {"id":"227_+12:00","value":"+12:00","tzv":"+1200", "key":"South_Pole","type":"Antarctica","url":"aq","country":"Antarctica"},
    {"id":"228_+03:00","value":"+03:00","tzv":"+0300", "key":"Syowa","type":"Antarctica","url":"aq","country":"Antarctica"},
    {"id":"229_+00:00","value":"+00:00","tzv":"+0000", "key":"Troll","type":"Antarctica","url":"aq","country":"Antarctica"},
    {"id":"230_+06:00","value":"+06:00","tzv":"+0600", "key":"Vostok","type":"Antarctica","url":"aq","country":"Antarctica"},
    {"id":"231_+01:00","value":"+01:00","tzv":"+0100", "key":"Longyearbyen","type":"Arctic","url":"no","country":"Norway"},
    {"id":"232_+03:00","value":"+03:00","tzv":"+0300", "key":"Aden","type":"Asia","url":"ye","country":"Yemen"},
    {"id":"233_+06:00","value":"+06:00","tzv":"+0600", "key":"Almaty","type":"Asia","url":"kz","country":"Kazakhstan"},
    {"id":"234_+02:00","value":"+02:00","tzv":"+0200", "key":"Amman","type":"Asia","url":"jo","country":"Jordan"},
    {"id":"235_+12:00","value":"+12:00","tzv":"+1200", "key":"Anadyr","type":"Asia","url":"ru","country":"Russia"},
    {"id":"236_+05:00","value":"+05:00","tzv":"+0500", "key":"Aqtau","type":"Asia","url":"kz","country":"Kazakhstan"},
    {"id":"237_+05:00","value":"+05:00","tzv":"+0500", "key":"Aqtobe","type":"Asia","url":"kz","country":"Kazakhstan"},
    {"id":"238_+05:00","value":"+05:00","tzv":"+0500", "key":"Ashgabat","type":"Asia","url":"tm","country":"Turkmenistan"},
    {"id":"239_+05:00","value":"+05:00","tzv":"+0500", "key":"Ashkhabad","type":"Asia","url":"tm","country":"Turkmenistan"},
    {"id":"240_+03:00","value":"+03:00","tzv":"+0300", "key":"Baghdad","type":"Asia","url":"iq","country":"Iraq"},
    {"id":"241_+03:00","value":"+03:00","tzv":"+0300", "key":"Bahrain","type":"Asia","url":"bh","country":"Bahrain"},
    {"id":"242_+04:00","value":"+04:00","tzv":"+0400", "key":"Baku","type":"Asia","url":"az","country":"Azerbaijan"},
    {"id":"243_+07:00","value":"+07:00","tzv":"+0700", "key":"Bangkok","type":"Asia","url":"th","country":"Thailand"},
    {"id":"244_+02:00","value":"+02:00","tzv":"+0200", "key":"Beirut","type":"Asia","url":"lb","country":"Lebanon"},
    {"id":"245_+06:00","value":"+06:00","tzv":"+0600", "key":"Bishkek","type":"Asia","url":"kg","country":"Kyrgyzstan"},
    {"id":"246_+08:00","value":"+08:00","tzv":"+0800", "key":"Brunei","type":"Asia","url":"bn","country":"Brunei"},
    {"id":"247_+05:30","value":"+05:30","tzv":"+0530", "key":"Calcutta","type":"Asia","url":"in","country":"India"},
    {"id":"248_+08:00","value":"+08:00","tzv":"+0800", "key":"Choibalsan","type":"Asia","url":"mn","country":"Mongolia"},
    {"id":"249_+08:00","value":"+08:00","tzv":"+0800", "key":"Chongqing","type":"Asia","url":"cn","country":"China"},
    {"id":"250_+08:00","value":"+08:00","tzv":"+0800", "key":"Chungking","type":"Asia","url":"cn","country":"China"},
    {"id":"251_+05:30","value":"+05:30","tzv":"+0530", "key":"Colombo","type":"Asia","url":"lk","country":"Sri Lanka"},
    {"id":"252_+06:00","value":"+06:00","tzv":"+0600", "key":"Dacca","type":"Asia","url":"bd","country":"Bangladesh"},
    {"id":"253_+02:00","value":"+02:00","tzv":"+0200", "key":"Damascus","type":"Asia","url":"sy","country":"Syria"},
    {"id":"254_+06:00","value":"+06:00","tzv":"+0600", "key":"Dhaka","type":"Asia","url":"bd","country":"Bangladesh"},
    {"id":"255_+09:00","value":"+09:00","tzv":"+0900", "key":"Dili","type":"Asia","url":"tl","country":"Timor-Leste"},
    {"id":"256_+04:00","value":"+04:00","tzv":"+0400", "key":"Dubai","type":"Asia","url":"ae","country":"United Arab Emirates"},
    {"id":"257_+05:00","value":"+05:00","tzv":"+0500", "key":"Dushanbe","type":"Asia","url":"tj","country":"Tajikistan"},
    {"id":"258_+02:00","value":"+02:00","tzv":"+0200", "key":"Gaza","type":"Asia","url":"ps","country":"Palestinian Territories"},
    {"id":"259_+08:00","value":"+08:00","tzv":"+0800", "key":"Harbin","type":"Asia","url":"cn","country":"China"},
    {"id":"260_+02:00","value":"+02:00","tzv":"+0200", "key":"Hebron","type":"Asia","url":"ps","country":"Palestinian Territories"},
    {"id":"261_+07:00","value":"+07:00","tzv":"+0700", "key":"Ho_Chi_Minh","type":"Asia","url":"vn","country":"Vietnam"},
    {"id":"262_+08:00","value":"+08:00","tzv":"+0800", "key":"Hong_Kong","type":"Asia","url":"hk","country":"Hong Kong"},
    {"id":"263_+07:00","value":"+07:00","tzv":"+0700", "key":"Hovd","type":"Asia","url":"mn","country":"Mongolia"},
    {"id":"264_+08:00","value":"+08:00","tzv":"+0800", "key":"Irkutsk","type":"Asia","url":"ru","country":"Russia"},
    {"id":"265_+02:00","value":"+02:00","tzv":"+0200", "key":"Istanbul","type":"Asia","url":"tr","country":"Turkey"},
    {"id":"266_+07:00","value":"+07:00","tzv":"+0700", "key":"Jakarta","type":"Asia","url":"id","country":"Indonesia"},
    {"id":"267_+09:00","value":"+09:00","tzv":"+0900", "key":"Jayapura","type":"Asia","url":"id","country":"Indonesia"},
    {"id":"268_+02:00","value":"+02:00","tzv":"+0200", "key":"Jerusalem","type":"Asia","url":"il","country":"Israel"},
    {"id":"269_+04:30","value":"+04:30","tzv":"+0430", "key":"Kabul","type":"Asia","url":"af","country":"Afghanistan"},
    {"id":"270_+12:00","value":"+12:00","tzv":"+1200", "key":"Kamchatka","type":"Asia","url":"ru","country":"Russia"},
    {"id":"271_+05:00","value":"+05:00","tzv":"+0500", "key":"Karachi","type":"Asia","url":"pk","country":"Pakistan"},
    {"id":"272_+06:00","value":"+06:00","tzv":"+0600", "key":"Kashgar","type":"Asia","url":"cn","country":"China"},
    {"id":"273_+05:45","value":"+05:45","tzv":"+0545", "key":"Kathmandu","type":"Asia","url":"np","country":"Nepal"},
    {"id":"274_+05:45","value":"+05:45","tzv":"+0545", "key":"Katmandu","type":"Asia","url":"np","country":"Nepal"},
    {"id":"275_+09:00","value":"+09:00","tzv":"+0900", "key":"Khandyga","type":"Asia","url":"ru","country":"Russia"},
    {"id":"276_+05:30","value":"+05:30","tzv":"+0530", "key":"Kolkata","type":"Asia","url":"in","country":"India"},
    {"id":"277_+07:00","value":"+07:00","tzv":"+0700", "key":"Krasnoyarsk","type":"Asia","url":"ru","country":"Russia"},
    {"id":"278_+08:00","value":"+08:00","tzv":"+0800", "key":"Kuala_Lumpur","type":"Asia","url":"my","country":"Malaysia"},
    {"id":"279_+08:00","value":"+08:00","tzv":"+0800", "key":"Kuching","type":"Asia","url":"my","country":"Malaysia"},
    {"id":"280_+03:00","value":"+03:00","tzv":"+0300", "key":"Kuwait","type":"Asia","url":"kw","country":"Kuwait"},
    {"id":"281_+08:00","value":"+08:00","tzv":"+0800", "key":"Macao","type":"Asia","url":"mo","country":"Macau"},
    {"id":"282_+08:00","value":"+08:00","tzv":"+0800", "key":"Macau","type":"Asia","url":"cn","country":"China"},
    {"id":"283_+10:00","value":"+10:00","tzv":"+1000", "key":"Magadan","type":"Asia","url":"ru","country":"Russia"},
    {"id":"284_+08:00","value":"+08:00","tzv":"+0800", "key":"Makassar","type":"Asia","url":"id","country":"Indonesia"},
    {"id":"285_+08:00","value":"+08:00","tzv":"+0800", "key":"Manila","type":"Asia","url":"ph","country":"Philippines"},
    {"id":"286_+04:00","value":"+04:00","tzv":"+0400", "key":"Muscat","type":"Asia","url":"om","country":"Oman"},
    {"id":"287_+02:00","value":"+02:00","tzv":"+0200", "key":"Nicosia","type":"Asia","url":"cy","country":"Cyprus"},
    {"id":"288_+07:00","value":"+07:00","tzv":"+0700", "key":"Novokuznetsk","type":"Asia","url":"ru","country":"Russia"},
    {"id":"289_+06:00","value":"+06:00","tzv":"+0600", "key":"Novosibirsk","type":"Asia","url":"ru","country":"Russia"},
    {"id":"290_+06:00","value":"+06:00","tzv":"+0600", "key":"Omsk","type":"Asia","url":"ru","country":"Russia"},
    {"id":"291_+05:00","value":"+05:00","tzv":"+0500", "key":"Oral","type":"Asia","url":"kz","country":"Kazakhstan"},
    {"id":"292_+07:00","value":"+07:00","tzv":"+0700", "key":"Phnom Penh","type":"Asia","url":"kh","country":"Cambodia"},
    {"id":"293_+07:00","value":"+07:00","tzv":"+0700", "key":"Pontianak","type":"Asia","url":"id","country":"Indonesia"},
    {"id":"294_+08:30","value":"+08:30","tzv":"+0830", "key":"Pyongyang","type":"Asia","url":"kp","country":"North Korea"},
    {"id":"295_+03:00","value":"+03:00","tzv":"+0300", "key":"Qatar","type":"Asia","url":"qa","country":"Qatar"},
    {"id":"296_+06:00","value":"+06:00","tzv":"+0600", "key":"Qyzylorda","type":"Asia","url":"kz","country":"Kazakhstan"},
    {"id":"297_+06:30","value":"+06:30","tzv":"+0630", "key":"Rangoon","type":"Asia","url":"mm","country":"Myanmar"},
    {"id":"298_+03:00","value":"+03:00","tzv":"+0300", "key":"Riyadh","type":"Asia","url":"sa","country":"Saudi Arabia"},
    {"id":"299_+07:00","value":"+07:00","tzv":"+0700", "key":"Saigon","type":"Asia","url":"vn","country":"Vietnam"},
    {"id":"300_+10:00","value":"+10:00","tzv":"+1000", "key":"Sakhalin","type":"Asia","url":"ru","country":"Russia"},
    {"id":"301_+05:00","value":"+05:00","tzv":"+0500", "key":"Samarkand","type":"Asia","url":"uz","country":"Uzbekistan"},
    {"id":"302_+09:00","value":"+09:00","tzv":"+0900", "key":"Seoul","type":"Asia","url":"kr","country":"South Korea"},
    {"id":"303_+08:00","value":"+08:00","tzv":"+0800", "key":"Shanghai","type":"Asia","url":"cn","country":"China"},
    {"id":"304_+08:00","value":"+08:00","tzv":"+0800", "key":"Singapore","type":"Asia","url":"sg","country":"Singapore"},
    {"id":"305_+11:00","value":"+11:00","tzv":"+1100", "key":"Srednekolymsk","type":"Asia","url":"ru","country":"Russia"},
    {"id":"306_+08:00","value":"+08:00","tzv":"+0800", "key":"Taipei","type":"Asia","url":"tw","country":"Taiwan"},
    {"id":"307_+05:00","value":"+05:00","tzv":"+0500", "key":"Tashkent","type":"Asia","url":"uz","country":"Uzbekistan"},
    {"id":"308_+04:00","value":"+04:00","tzv":"+0400", "key":"Tbilisi","type":"Asia","url":"ge","country":"Georgia"},
    {"id":"309_+03:30","value":"+03:30","tzv":"+0330", "key":"Tehran","type":"Asia","url":"ir","country":"Iran"},
    {"id":"310_+02:00","value":"+02:00","tzv":"+0200", "key":"Tel_Aviv","type":"Asia","url":"il","country":"Israel"},
    {"id":"311_+06:00","value":"+06:00","tzv":"+0600", "key":"Thimbu","type":"Asia","url":"bt","country":"Bhutan"},
    {"id":"312_+06:00","value":"+06:00","tzv":"+0600", "key":"Thimphu","type":"Asia","url":"bt","country":"Bhutan"},
    {"id":"313_+09:00","value":"+09:00","tzv":"+0900", "key":"Tokyo","type":"Asia","url":"jp","country":"Japan"},
    {"id":"314_+08:00","value":"+08:00","tzv":"+0800", "key":"Ujung_Pandang","type":"Asia","url":"id","country":"Indonesia"},
    {"id":"315_+08:00","value":"+08:00","tzv":"+0800", "key":"Ulaanbaatar","type":"Asia","url":"mn","country":"Mongolia"},
    {"id":"316_+08:00","value":"+08:00","tzv":"+0800", "key":"Ulan_Bator","type":"Asia","url":"ru","country":"Russia"},
    {"id":"317_+06:00","value":"+06:00","tzv":"+0600", "key":"Urumqi","type":"Asia","url":"cn","country":"China"},
    {"id":"318_+10:00","value":"+10:00","tzv":"+1000", "key":"Ust-Nera","type":"Asia","url":"ru","country":"Russia"},
    {"id":"319_+07:00","value":"+07:00","tzv":"+0700", "key":"Vientiane","type":"Asia","url":"la","country":"Laos"},
    {"id":"320_+10:00","value":"+10:00","tzv":"+1000", "key":"Vladivostok","type":"Asia","url":"ru","country":"Russia"},
    {"id":"321_+09:00","value":"+09:00","tzv":"+0900", "key":"Yakutsk","type":"Asia","url":"ru","country":"Russia"},
    {"id":"322_+05:00","value":"+05:00","tzv":"+0500", "key":"Yekaterinburg","type":"Asia","url":"ru","country":"Russia"},
    {"id":"323_+04:00","value":"+04:00","tzv":"+0400", "key":"Yerevan","type":"Asia","url":"am","country":"Armenia"},
    {"id":"324_-01:00","value":"-01:00","tzv":"-0100", "key":"Azores","type":"Atlantic","url":"pt","country":"Portugal"},
    {"id":"325_-04:00","value":"-04:00","tzv":"-0400", "key":"Bermuda","type":"Atlantic","url":"bm","country":"Bermuda"},
    {"id":"326_+00:00","value":"+00:00","tzv":"+0000", "key":"Canary","type":"Atlantic","url":"es","country":"Spain"},
    {"id":"327_-01:00","value":"-01:00","tzv":"-0100", "key":"Cape_Verde","type":"Atlantic","url":"cv","country":"Cabo Verde"},
    {"id":"328_+00:00","value":"+00:00","tzv":"+0000", "key":"Faeroe","type":"Atlantic","url":"dk","country":"Denmark"},
    {"id":"329_+00:00","value":"+00:00","tzv":"+0000", "key":"Faroe","type":"Atlantic","url":"fo","country":"Faroe Islands"},
    {"id":"330_+01:00","value":"+01:00","tzv":"+0100", "key":"Jan_Mayen","type":"Atlantic","url":"no","country":"Norway"},
    {"id":"331_+00:00","value":"+00:00","tzv":"+0000", "key":"Madeira","type":"Atlantic","url":"pt","country":"Portugal"},
    {"id":"332_+00:00","value":"+00:00","tzv":"+0000", "key":"Reykjavik","type":"Atlantic","url":"is","country":"Iceland"},
    {"id":"333_-02:00","value":"-02:00","tzv":"-0200", "key":"South_Georgia","type":"Atlantic","url":"gs","country":"South Georgia/Sandwich Is."},
    {"id":"334_+00:00","value":"+00:00","tzv":"+0000", "key":"St_Helena","type":"Atlantic","url":"sh","country":"Saint Helena"},
    {"id":"335_-03:00","value":"-03:00","tzv":"-0300", "key":"Stanley","type":"Atlantic","url":"fk","country":"Falkland Islands"},
    {"id":"336_+10:00","value":"+10:00","tzv":"+1000", "key":"ACT","type":"Australia","url":"au","country":"Australia"},
    {"id":"337_+09:30","value":"+09:30","tzv":"+0930", "key":"Adelaide","type":"Australia","url":"au","country":"Australia"},
    {"id":"338_+10:00","value":"+10:00","tzv":"+1000", "key":"Brisbane","type":"Australia","url":"au","country":"Australia"},
    {"id":"339_+09:30","value":"+09:30","tzv":"+0930", "key":"Broken_Hill","type":"Australia","url":"au","country":"Australia"},
    {"id":"340_+10:00","value":"+10:00","tzv":"+1000", "key":"Canberra","type":"Australia","url":"au","country":"Australia"},
    {"id":"341_+10:00","value":"+10:00","tzv":"+1000", "key":"Currie","type":"Australia","url":"au","country":"Australia"},
    {"id":"342_+09:30","value":"+09:30","tzv":"+0930", "key":"Darwin","type":"Australia","url":"au","country":"Australia"},
    {"id":"343_+08:45","value":"+08:45","tzv":"+0845", "key":"Eucla","type":"Australia","url":"au","country":"Australia"},
    {"id":"344_+10:00","value":"+10:00","tzv":"+1000", "key":"Hobart","type":"Australia","url":"au","country":"Australia"},
    {"id":"345_+10:30","value":"+10:30","tzv":"+1030", "key":"LHI","type":"Australia","url":"au","country":"Australia"},
    {"id":"346_+10:00","value":"+10:00","tzv":"+1000", "key":"Lindeman","type":"Australia","url":"au","country":"Australia"},
    {"id":"347_+10:30","value":"+10:30","tzv":"+1030", "key":"Lord_Howe","type":"Australia","url":"au","country":"Australia"},
    {"id":"348_+10:00","value":"+10:00","tzv":"+1000", "key":"Melbourne","type":"Australia","url":"au","country":"Australia"},
    {"id":"349_+10:00","value":"+10:00","tzv":"+1000", "key":"NSW","type":"Australia","url":"au","country":"Australia"},
    {"id":"350_+09:30","value":"+09:30","tzv":"+0930", "key":"North","type":"Australia","url":"au","country":"Australia"},
    {"id":"351_+08:00","value":"+08:00","tzv":"+0800", "key":"Perth","type":"Australia","url":"au","country":"Australia"},
    {"id":"352_+10:00","value":"+10:00","tzv":"+1000", "key":"Queensland","type":"Australia","url":"au","country":"Australia"},
    {"id":"353_+09:30","value":"+09:30","tzv":"+0930", "key":"South","type":"Australia","url":"au","country":"Australia"},
    {"id":"354_+10:00","value":"+10:00","tzv":"+1000", "key":"Sydney","type":"Australia","url":"au","country":"Australia"},
    {"id":"355_+10:00","value":"+10:00","tzv":"+1000", "key":"Tasmania","type":"Australia","url":"au","country":"Australia"},
    {"id":"356_+10:00","value":"+10:00","tzv":"+1000", "key":"Victoria","type":"Australia","url":"au","country":"Australia"},
    {"id":"357_+08:00","value":"+08:00","tzv":"+0800", "key":"West","type":"Australia","url":"au","country":"Australia"},
    {"id":"358_+09:30","value":"+09:30","tzv":"+0930", "key":"Yancowinna","type":"Australia","url":"au","country":"Australia"},
    {"id":"359_-05:00","value":"-05:00","tzv":"-0500", "key":"Acre","type":"Brazil","url":"br","country":"Brazil"},
    {"id":"360_-02:00","value":"-02:00","tzv":"-0200", "key":"DeNoronha","type":"Brazil","url":"br","country":"Brazil"},
    {"id":"361_-03:00","value":"-03:00","tzv":"-0300", "key":"East","type":"Brazil","url":"br","country":"Brazil"},
    {"id":"362_-04:00","value":"-04:00","tzv":"-0400", "key":"West","type":"Brazil","url":"br","country":"Brazil"},
    {"id":"363_-04:00","value":"-04:00","tzv":"-0400", "key":"Atlantic","type":"Canada","url":"ca","country":"Canada"},
    {"id":"364_-06:00","value":"-06:00","tzv":"-0600", "key":"Central","type":"Canada","url":"ca","country":"Canada"},
    {"id":"365_-06:00","value":"-06:00","tzv":"-0600", "key":"East Saskatchewan","type":"Canada","url":"ca","country":"Canada"},
    {"id":"366_-05:00","value":"-05:00","tzv":"-0500", "key":"Eastern","type":"Canada","url":"ca","country":"Canada"},
    {"id":"367_-07:00","value":"-07:00","tzv":"-0700", "key":"Mountain","type":"Canada","url":"ca","country":"Canada"},
    {"id":"368_-03:30","value":"-03:30","tzv":"-0330", "key":"Newfoundland","type":"Canada","url":"ca","country":"Canada"},
    {"id":"369_-08:00","value":"-08:00","tzv":"-0800", "key":"Pacific","type":"Canada","url":"ca","country":"Canada"},
    {"id":"370_-06:00","value":"-06:00","tzv":"-0600", "key":"Saskatchewan","type":"Canada","url":"ca","country":"Canada"},
    {"id":"371_-08:00","value":"-08:00","tzv":"-0800", "key":"Yukon","type":"Canada","url":"ca","country":"Canada"},
    {"id":"372_-03:00","value":"-03:00","tzv":"-0300", "key":"Continental","type":"Chile","url":"cl","country":"Chile"},
    {"id":"373_-05:00","value":"-05:00","tzv":"-0500", "key":"EasterIsland","type":"Chile","url":"cl","country":"Chile"},
    {"id":"374_+01:00","value":"+01:00","tzv":"+0100", "key":"Amsterdam","type":"Europe","url":"nl","country":"Netherlands"},
    {"id":"375_+01:00","value":"+01:00","tzv":"+0100", "key":"Andorra","type":"Europe","url":"ad","country":"Andorra"},
    {"id":"376_+02:00","value":"+02:00","tzv":"+0200", "key":"Athens","type":"Europe","url":"gr","country":"Greece"},
    {"id":"377_+00:00","value":"+00:00","tzv":"+0000", "key":"Belfast","type":"Europe","url":"gb","country":"United Kingdom"},
    {"id":"378_+01:00","value":"+01:00","tzv":"+0100", "key":"Belgrade","type":"Europe","url":"rs","country":"Serbia"},
    {"id":"379_+01:00","value":"+01:00","tzv":"+0100", "key":"Berlin","type":"Europe","url":"de","country":"Germany"},
    {"id":"380_+01:00","value":"+01:00","tzv":"+0100", "key":"Bratislava","type":"Europe","url":"sk",'country':"Slovakia"},
    {"id":"381_+01:00","value":"+01:00","tzv":"+0100", "key":"Brussels","type":"Europe","url":"be",'country':"Belgium"},
    {"id":"382_+02:00","value":"+02:00","tzv":"+0200", "key":"Bucharest","type":"Europe","url":"ro",'country':"Romania"},
    {"id":"383_+01:00","value":"+01:00","tzv":"+0100", "key":"Budapest","type":"Europe","url":"hu",'country':"Hungary"},
    {"id":"384_+01:00","value":"+01:00","tzv":"+0100", "key":"Busingen","type":"Europe","url":"de",'country':"Germany"},
    {"id":"385_+02:00","value":"+02:00","tzv":"+0200", "key":"Chisinau","type":"Europe","url":"md",'country':"Moldova"},
    {"id":"386_+01:00","value":"+01:00","tzv":"+0100", "key":"Copenhagen","type":"Europe","url":"dk","country":"Denmark"},
    {"id":"387_+00:00","value":"+00:00","tzv":"+0000", "key":"Dublin","type":"Europe","url":"ie","country":"Ireland"},
    {"id":"388_+01:00","value":"+01:00","tzv":"+0100", "key":"Gibraltar","type":"Europe","url":"gi","country":"Gibraltar"},
    {"id":"389_+00:00","value":"+00:00","tzv":"+0000", "key":"Guernsey","type":"Europe","url":"gg","country":"Guernsey"},
    {"id":"390_+02:00","value":"+02:00","tzv":"+0200", "key":"Helsinki","type":"Europe","url":"fi","country":"Finland"},
    {"id":"391_+00:00","value":"+00:00","tzv":"+0000", "key":"Isle_of_Man","type":"Europe","url":"im","country":"Isle of Man"},
    {"id":"392_+02:00","value":"+02:00","tzv":"+0200", "key":"Istanbul","type":"Europe","url":"tr","country":"Turkey"},
    {"id":"393_+00:00","value":"+00:00","tzv":"+0000", "key":"Jersey","type":"Europe","url":"je","country":"Jersey"},
    {"id":"394_+02:00","value":"+02:00","tzv":"+0200", "key":"Kaliningrad","type":"Europe","url":"ru","country":"Russia"},
    {"id":"395_+02:00","value":"+02:00","tzv":"+0200", "key":"Kiev","type":"Europe","url":"ua","country":"Ukraine"},
    {"id":"396_+00:00","value":"+00:00","tzv":"+0000", "key":"Lisbon","type":"Europe","url":"pt","country":"Portugal"},
    {"id":"397_+01:00","value":"+01:00","tzv":"+0100", "key":"Ljubljana","type":"Europe","url":"si","country":"Slovenia"},
    {"id":"398_+00:00","value":"+00:00","tzv":"+0000", "key":"London","type":"Europe","url":"gb","country":"United Kingdom"},
    {"id":"399_+01:00","value":"+01:00","tzv":"+0100", "key":"Luxembourg","type":"Europe","url":"lu","country":"Luxembourg"},
    {"id":"400_+01:00","value":"+01:00","tzv":"+0100", "key":"Madrid","type":"Europe","url":"es","country":"Spain"},
    {"id":"401_+01:00","value":"+01:00","tzv":"+0100", "key":"Malta","type":"Europe","url":"mt","country":"Malta"},
    {"id":"402_+02:00","value":"+02:00","tzv":"+0200", "key":"Mariehamn","type":"Europe","url":"ax","country":" Åland Islands"},
    {"id":"403_+03:00","value":"+03:00","tzv":"+0300", "key":"Minsk","type":"Europe","url":"by","country":"Belarus"},
    {"id":"404_+01:00","value":"+01:00","tzv":"+0100", "key":"Monaco","type":"Europe","url":"mc","country":"Monaco"},
    {"id":"405_+03:00","value":"+03:00","tzv":"+0300", "key":"Moscow","type":"Europe","url":"ru","country":"Russia"},
    {"id":"406_+02:00","value":"+02:00","tzv":"+0200", "key":"Nicosia","type":"Europe","url":"cy","country":"Cyprus"},
    {"id":"407_+01:00","value":"+01:00","tzv":"+0100", "key":"Oslo","type":"Europe","url":"no","country":"Norway"},
    {"id":"408_+01:00","value":"+01:00","tzv":"+0100", "key":"Paris","type":"Europe","url":"fr","country":"France"},
    {"id":"409_+01:00","value":"+01:00","tzv":"+0100", "key":"Podgorica","type":"Europe","url":"me","country":"Montenegro"},
    {"id":"410_+01:00","value":"+01:00","tzv":"+0100", "key":"Prague","type":"Europe","url":"cz","country":"Czechia"},
    {"id":"411_+02:00","value":"+02:00","tzv":"+0200", "key":"Riga","type":"Europe","url":"lv","country":"Latvia"},
    {"id":"412_+01:00","value":"+01:00","tzv":"+0100", "key":"Rome","type":"Europe","url":"it","country":"Italy"},
    {"id":"413_+04:00","value":"+04:00","tzv":"+0400", "key":"Samara","type":"Europe","url":"ru","country":"Russia"},
    {"id":"414_+01:00","value":"+01:00","tzv":"+0100", "key":"San Marino","type":"Europe","url":"sm","country":"San Marino"},
    {"id":"415_+01:00","value":"+01:00","tzv":"+0100", "key":"Sarajevo","type":"Europe","url":"ba","country":"Bosnia Herzegovina"},
    {"id":"416_+03:00","value":"+03:00","tzv":"+0300", "key":"Simferopol","type":"Europe","url":"ua","country":"Ukraine"},
    {"id":"417_+01:00","value":"+01:00","tzv":"+0100", "key":"Skopje","type":"Europe","url":"mk","country":"North Macedonia"},
    {"id":"418_+02:00","value":"+02:00","tzv":"+0200", "key":"Sofia","type":"Europe","url":"bg","country":"Bulgaria"},
    {"id":"419_+01:00","value":"+01:00","tzv":"+0100", "key":"Stockholm","type":"Europe","url":"se","country":"Sweden"},
    {"id":"420_+02:00","value":"+02:00","tzv":"+0200", "key":"Tallinn","type":"Europe","url":"ee","country":"Estonia"},
    {"id":"421_+01:00","value":"+01:00","tzv":"+0100", "key":"Tirane","type":"Europe","url":"al","country":"Albania"},
    {"id":"422_+02:00","value":"+02:00","tzv":"+0200", "key":"Tiraspol","type":"Europe","url":"md","country":"Moldova"},
    {"id":"423_+02:00","value":"+02:00","tzv":"+0200", "key":"Uzhgorod","type":"Europe","url":"ua","country":"Ukraine"},
    {"id":"424_+01:00","value":"+01:00","tzv":"+0100", "key":"Vaduz","type":"Europe","url":"li","country":"Liechtenstein"},
    {"id":"425_+01:00","value":"+01:00","tzv":"+0100", "key":"Vatican","type":"Europe","url":"va","country":"Vatican City State"},
    {"id":"426_+01:00","value":"+01:00","tzv":"+0100", "key":"Vienna","type":"Europe","url":"at","country":"Austria"},
    {"id":"427_+02:00","value":"+02:00","tzv":"+0200", "key":"Vilnius","type":"Europe","url":"lt","country":"Lithuania"},
    {"id":"428_+03:00","value":"+03:00","tzv":"+0300", "key":"Volgograd","type":"Europe","url":"ru","country":"Russia"},
    {"id":"429_+01:00","value":"+01:00","tzv":"+0100", "key":"Warsaw","type":"Europe","url":"pl","country":"Poland"},
    {"id":"430_+01:00","value":"+01:00","tzv":"+0100", "key":"Zagreb","type":"Europe","url":"hr","country":"Croatia"},
    {"id":"431_+02:00","value":"+02:00","tzv":"+0200", "key":"Zaporozhye","type":"Europe","url":"ua","country":"Ukraine"},
    {"id":"432_+01:00","value":"+01:00","tzv":"+0100", "key":"Zurich","type":"Europe","url":"ch","country":"Switzerland"},
    {"id":"433_+03:00","value":"+03:00","tzv":"+0300", "key":"Antananarivo","type":"Indian","url":"mg","country":"Madagascar"},
    {"id":"434_+06:00","value":"+06:00","tzv":"+0600", "key":"Chagos","type":"Indian","url":"mu","country":"Mauritius"},
    {"id":"435_+07:00","value":"+07:00","tzv":"+0700", "key":"Christmas","type":"Indian","url":"cx","country":"Christmas Island"},
    {"id":"436_+06:30","value":"+06:30","tzv":"+0630", "key":"Cocos","type":"Indian","url":"cc","country":"Cocos Islands"},
    {"id":"437_+03:00","value":"+03:00","tzv":"+0300", "key":"Comoro","type":"Indian","url":"km","country":"Comoros"},
    {"id":"438_+05:00","value":"+05:00","tzv":"+0500", "key":"Kerguelen","type":"Indian","url":"tf","country":"French Southern & Antarctic Lands"},
    {"id":"439_+04:00","value":"+04:00","tzv":"+0400", "key":"Mahe","type":"Indian","url":"sc","country":"Seychelles"},
    {"id":"440_+05:00","value":"+05:00","tzv":"+0500", "key":"Maldives","type":"Indian","url":"mv","country":"Maldives"},
    {"id":"441_+04:00","value":"+04:00","tzv":"+0400", "key":"Mauritius","type":"Indian","url":"mu","country":"Mauritius"},
    {"id":"442_+03:00","value":"+03:00","tzv":"+0300", "key":"Mayotte","type":"Indian","url":"yt","country":"Mayotte"},
    {"id":"443_+04:00","value":"+04:00","tzv":"+0400", "key":"Reunion","type":"Indian","url":"re","country":"Reunion"},
    {"id":"444_-08:00","value":"-08:00","tzv":"-0800", "key":"BajaNorte","type":"Mexico","url":"mx","country":"Mexico"},
    {"id":"445_-07:00","value":"-07:00","tzv":"-0700", "key":"BajaSur","type":"Mexico","url":"mx","country":"Mexico"},
    {"id":"446_-06:00","value":"-06:00","tzv":"-0600", "key":"General","type":"Mexico","url":"mx","country":"Mexico"},
    {"id":"447_+13:00","value":"+13:00","tzv":"+1300", "key":"Apia","type":"Pacific","url":"ws","country":"Samoa"},
    {"id":"448_+12:00","value":"+12:00","tzv":"+1200", "key":"Auckland","type":"Pacific","url":"nz","country":"New Zealand"},
    {"id":"449_+11:00","value":"+11:00","tzv":"+1100", "key":"Bougainville","type":"Pacific","url":"pg","country":"Papua New Guinea"},
    {"id":"450_+12:45","value":"+12:45","tzv":"+1245", "key":"Chatham","type":"Pacific","url":"nz","country":"New Zealand"},
    {"id":"451_+10:00","value":"+10:00","tzv":"+1000", "key":"Chuuk","type":"Pacific","url":"fm","country":"Micronesia"},
    {"id":"452_-05:00","value":"-05:00","tzv":"-0500", "key":"Easter","type":"Pacific","url":"cl","country":"Chile"},
    {"id":"453_+11:00","value":"+11:00","tzv":"+1100", "key":"Efate","type":"Pacific","url":"vu","country":"Vanuatu"},
    {"id":"454_+13:00","value":"+13:00","tzv":"+1300", "key":"Enderbury","type":"Pacific","url":"ki","country":"Kiribati"},
    {"id":"455_+13:00","value":"+13:00","tzv":"+1300", "key":"Fakaofo","type":"Pacific","url":"tk","country":"Tokelau"},
    {"id":"456_+12:00","value":"+12:00","tzv":"+1200", "key":"Fiji","type":"Pacific","url":"fj","country":"Fiji"},
    {"id":"457_+12:00","value":"+12:00","tzv":"+1200", "key":"Funafuti","type":"Pacific","url":"tv","country":"Tuvalu"},
    {"id":"458_-06:00","value":"-06:00","tzv":"-0600", "key":"Galapagos","type":"Pacific","url":"ec","country":"Ecuador"},
    {"id":"459_-09:00","value":"-09:00","tzv":"-0900", "key":"Gambier","type":"Pacific","url":"pf","country":"French Polynesia"},
    {"id":"460_+11:00","value":"+11:00","tzv":"+1100", "key":"Guadalcanal","type":"Pacific","url":"sb","country":"Solomon Islands"},
    {"id":"461_+10:00","value":"+10:00","tzv":"+1000", "key":"Guam","type":"Pacific","url":"gu","country":"Guam"},
    {"id":"462_-10:00","value":"-10:00","tzv":"-1000", "key":"Honolulu","type":"Pacific","url":"us","country":"USA"},
    {"id":"463_-10:00","value":"-10:00","tzv":"-1000", "key":"Johnston","type":"Pacific","url":"us","country":"US minor outlying islands"},
    {"id":"464_+14:00","value":"+14:00","tzv":"+1400", "key":"Kiritimati","type":"Pacific","url":"ki","country":"Kiribati"},
    {"id":"465_+11:00","value":"+11:00","tzv":"+1100", "key":"Kosrae","type":"Pacific","url":"fm","country":"Micronesia"},
    {"id":"466_+12:00","value":"+12:00","tzv":"+1200", "key":"Kwajalein","type":"Pacific","url":"mh","country":"Marshall Islands"},
    {"id":"467_+12:00","value":"+12:00","tzv":"+1200", "key":"Majuro","type":"Pacific","url":"mh","country":"Marshall Islands"},
    {"id":"468_-09:30","value":"-09:30","tzv":"-0930", "key":"Marquesas","type":"Pacific","url":"pf","country":"French Polynesia"},
    {"id":"469_-11:00","value":"-11:00","tzv":"-1100", "key":"Midway","type":"Pacific","url":"us","country":"US Minor Outlying Islands"},
    {"id":"470_+12:00","value":"+12:00","tzv":"+1200", "key":"Nauru","type":"Pacific","url":"nr","country":"Nauru"},
    {"id":"471_-11:00","value":"-11:00","tzv":"-1100", "key":"Niue","type":"Pacific","url":"nu","country":"Niue"},
    {"id":"472_+11:00","value":"+11:00","tzv":"+1100", "key":"Norfolk","type":"Pacific","url":"us","country":"USA"},
    {"id":"473_+11:00","value":"+11:00","tzv":"+1100", "key":"Noumea","type":"Pacific","url":"nc","country":"New Caledonia"},
    {"id":"474_-11:00","value":"-11:00","tzv":"-1100", "key":"Pago_Pago","type":"Pacific","url":"as","country":"American Samoa"},
    {"id":"475_+09:00","value":"+09:00","tzv":"+0900", "key":"Palau","type":"Pacific","url":"pw","country":"Palau"},
    {"id":"476_-08:00","value":"-08:00","tzv":"-0800", "key":"Pitcairn","type":"Pacific","url":"pn","country":"Pitcairn Islands"},
    {"id":"477_+11:00","value":"+11:00","tzv":"+1100", "key":"Pohnpei","type":"Pacific","url":"fm","country":"Micronesia"},
    {"id":"478_+11:00","value":"+11:00","tzv":"+1100", "key":"Ponape","type":"Pacific","url":"fm","country":"Micronesia"},
    {"id":"479_+10:00","value":"+10:00","tzv":"+1000", "key":"Port_Moresby","type":"Pacific","url":"pg","country":"Papua New Guinea"},
    {"id":"480_-10:00","value":"-10:00","tzv":"-1000", "key":"Rarotonga","type":"Pacific","url":"ck","country":"Cook Islands"},
    {"id":"481_+10:00","value":"+10:00","tzv":"+1000", "key":"Saipan","type":"Pacific","url":"mp","country":"Northern Mariana Islands"},
    {"id":"482_-11:00","value":"-11:00","tzv":"-1100", "key":"Samoa","type":"Pacific","url":"ws","country":"Samoa"},
    {"id":"483_-10:00","value":"-10:00","tzv":"-1000", "key":"Tahiti","type":"Pacific","url":"pf","country":"French Polynesia"},
    {"id":"484_+12:00","value":"+12:00","tzv":"+1200", "key":"Tarawa","type":"Pacific","url":"ki","country":"Kiribati"},
    {"id":"485_+13:00","value":"+13:00","tzv":"+1300", "key":"Tongatapu","type":"Pacific","url":"to","country":"Tonga"},
    {"id":"486_+10:00","value":"+10:00","tzv":"+1000", "key":"Truk","type":"Pacific","url":"fm","country":"Micronesia"},
    {"id":"487_+12:00","value":"+12:00","tzv":"+1200", "key":"Wake","type":"Pacific","url":"us","country":"US Minor Outlying Islands"},
    {"id":"488_+12:00","value":"+12:00","tzv":"+1200", "key":"Wallis","type":"Pacific","url":"ch","country":"Switzerland"},
    {"id":"489_+10:00","value":"+10:00","tzv":"+1000", "key":"Yap","type":"Pacific","url":"fm","country":"Micronesia"},
    {"id":"490_-09:00","value":"-09:00","tzv":"-0900", "key":"Alaska", "type":"US","url":"us","country":"USA"},
    {"id":"491_-10:00","value":"-10:00","tzv":"-1000", "key":"Aleutian", "type":"US","url":"us","country":"USA"},
    {"id":"492_-07:00","value":"-07:00","tzv":"-0700", "key":"Arizona", "type":"US","url":"us","country":"USA"},
    {"id":"493_-06:00","value":"-06:00","tzv":"-0600", "key":"Central", "type":"US","url":"us","country":"USA"},
    {"id":"494_-05:00","value":"-05:00","tzv":"-0500", "key":"East-Indiana", "type":"US","url":"us","country":"USA"},
    {"id":"495_-05:00","value":"-05:00","tzv":"-0500", "key":"Eastern", "type":"US","url":"us","country":"USA"},
    {"id":"496_-10:00","value":"-10:00","tzv":"-1000", "key":"Hawaii", "type":"US","url":"us","country":"USA"},
    {"id":"497_-06:00","value":"-06:00","tzv":"-0600", "key":"Indiana-Starke", "type":"US","url":"us","country":"USA"},
    {"id":"498_-05:00","value":"-05:00","tzv":"-0500", "key":"Michigan", "type":"US","url":"us","country":"USA"},
    {"id":"499_-11:00","value":"-11:00","tzv":"-1100", "key":"Samoa", "type":"US","url":"ws","country":"Samoa"},
    {"id":"500_+02:00","value":"+02:00","tzv":"+0200", "key":"Turkey", "type":"Others","url":"tr","country":"Turkey"},
    {"id":"501_+01:00","value":"+01:00","tzv":"+0100", "key":"Poland", "type":"Others","url":"pl","country":"Poland"},
    {"id":"502_+00:00","value":"+00:00","tzv":"+0000", "key":"Portugal", "type":"Others","url":"pt","country":"Portugal"},
    {"id":"503_+09:00","value":"+09:00","tzv":"+0900", "key":"ROK", "type":"Others","url":"kr","country":"South Korea"},
    {"id":"504_+08:00","value":"+08:00","tzv":"+0800", "key":"Singapore", "type":"Others","url":"sg","country":"Singapore"},
    {"id":"505_+03:30","value":"+03:30","tzv":"+0330", "key":"Iran", "type":"Others","url":"ir","country":"Iran"},
    {"id":"506_+02:00","value":"+02:00","tzv":"+0200", "key":"Israel", "type":"Others","url":"il","country":"Israel"},
    {"id":"507_-05:00","value":"-05:00","tzv":"-0500", "key":"Jamaica", "type":"Others","url":"jm","country":"Jamaica"},
    {"id":"508_+09:00","value":"+09:00","tzv":"+0900", "key":"Japan", "type":"Others","url":"jp","country":"Japan"},
    {"id":"509_+12:00","value":"+12:00","tzv":"+1200", "key":"Kwajalein", "type":"Others","url":"mh","country":"Marshall Islands"},
    {"id":"510_+02:00","value":"+02:00","tzv":"+0200", "key":"Libya", "type":"Others","url":"ly","country":"Libya"},
    {"id":"511_+08:00","value":"+08:00","tzv":"+0800", "key":"Hongkong", "type":"Others","url":"hk","country":"Hong kong"},
    {"id":"512_+00:00","value":"+00:00","tzv":"+0000", "key":"Iceland", "type":"Others","url":"is","country":"Iceland"},
    {"id":"513_-05:00","value":"-05:00","tzv":"-0500", "key":"Cuba", "type":"Others","url":"cu","country":"Cuba"},
    {"id":"514_+02:00","value":"+02:00","tzv":"+0200", "key":"Egypt", "type":"Others","url":"eg","country":"Egypt"},
    {"id":"515_-07:00","value":"-07:00","tzv":"-0700", "key":"Mountain", "type":"US","url":"","country":"Mountain Time"},
    {"id":"516_+01:00","value":"+01:00","tzv":"+0100", "key":"CET", "type":"Others","url":"","country":"Central European Time"},
    {"id":"517_-06:00","value":"-06:00","tzv":"-0600", "key":"CST6CDT", "type":"Others","url":"","country":"Central Time"},
    {"id":"518_+12:00","value":"+12:00","tzv":"+1200", "key":"NZ", "type":"Others","url":"","country":"New Zealand Standard Time"},
    {"id":"519_-08:00","value":"-08:00","tzv":"-0800", "key":"Pacific", "type":"US","url":"","country":"Pacific Time"},
    {"id":"520_-08:00","value":"-08:00","tzv":"-0800", "key":"Pacific-New", "type":"US","url":"","country":"Pacific Time"},
    {"id":"521_+12:45","value":"+12:45","tzv":"+1245", "key":"NZ-CHAT", "type":"Others","url":"",country:""},
    {"id":"522_-07:00","value":"-07:00","tzv":"-0700", "key":"Navajo", "type":"Others","url":"",country:""},
    {"id":"523_+08:00","value":"+08:00","tzv":"+0800", "key":"PRC", "type":"Others","url":"",country:""},
    {"id":"524_-08:00","value":"-08:00","tzv":"-0800", "key":"PST8PDT", "type":"Others","url":"",country:""},
    {"id":"525_+02:00","value":"+02:00","tzv":"+0200", "key":"EET", "type":"Others","url":"",country:""},
    {"id":"526_-05:00","value":"-05:00","tzv":"-0500", "key":"EST5EDT", "type":"Others","url":"",country:""},
    {"id":"527_+00:00","value":"+00:00","tzv":"+0000", "key":"Eire", "type":"Others","url":"",country:""},
    {"id":"528_+01:00","value":"+01:00","tzv":"+0100", "key":"MET", "type":"Others","url":"",country:""},
    {"id":"529_-07:00","value":"-07:00","tzv":"-0700", "key":"MST7MDT", "type":"Others","url":"",country:""},
    {"id":"530_+00:00","value":"+00:00","tzv":"+0000", "key":"GB", "type":"Others","url":"",country:""},
    {"id":"531_+00:00","value":"+00:00","tzv":"+0000", "key":"GB-Eire", "type":"Others","url":"",country:""},
    {"id":"532_+00:00","value":"+00:00","tzv":"+0000", "key":"GMT", "type":"Others","url":"",country:""},
    {"id":"533_+00:00","value":"+00:00","tzv":"+0000", "key":"GMT0", "type":"Others","url":"",country:""},
    {"id":"534_+00:00","value":"+00:00","tzv":"+0000", "key":"Greenwich", "type":"Others","url":"",country:""},
    {"id":"535_+00:00","value":"+00:00","tzv":"+0000", "key":"UCT", "type":"Others","url":"",country:""},
    {"id":"536_+00:00","value":"+00:00","tzv":"+0000", "key":"UTC", "type":"Others","url":"",country:""},
    {"id":"537_+00:00","value":"+00:00","tzv":"+0000", "key":"Universal", "type":"Others","url":"",country:""},
    {"id":"538_+03:00","value":"+03:00","tzv":"+0300", "key":"W-SU", "type":"Others","url":"",country:""},
    {"id":"539_+00:00","value":"+00:00","tzv":"+0000", "key":"WET", "type":"Others","url":"",country:""},
    {"id":"540_+00:00","value":"+00:00","tzv":"+0000", "key":"Zulu", "type":"Others","url":"",country:""},
    {"id":"541_-05:00","value":"-05:00","tzv":"-0500", "key":"EST", "type":"Others","url":"",country:""},
    {"id":"542_-10:00","value":"-10:00","tzv":"-1000", "key":"HST", "type":"Others","url":"",country:""},
    {"id":"543_-07:00","value":"-07:00","tzv":"-0700", "key":"MST", "type":"Others","url":"",country:""},
    {"id":"544_+09:30","value":"+09:30","tzv":"+0930", "key":"ACT", "type":"Others","url":"",country:""},
    {"id":"545_+10:00","value":"+10:00","tzv":"+1000", "key":"AET", "type":"Others","url":"",country:""},
    {"id":"546_-03:00","value":"-03:00","tzv":"-0300", "key":"AGT", "type":"Others","url":"",country:""},
    {"id":"547_+02:00","value":"+02:00","tzv":"+0200", "key":"ART", "type":"Others","url":"",country:""},
    {"id":"548_-09:00","value":"-09:00","tzv":"-0900", "key":"AST", "type":"Others","url":"",country:""},
    {"id":"549_-03:00","value":"-03:00","tzv":"-0300", "key":"BET", "type":"Others","url":"",country:""},
    {"id":"550_+06:00","value":"+06:00","tzv":"+0600", "key":"BST", "type":"Others","url":"",country:""},
    {"id":"551_+02:00","value":"+02:00","tzv":"+0200", "key":"CAT", "type":"Others","url":"",country:""},
    {"id":"552_-03:30","value":"-03:30","tzv":"-0330", "key":"CNT", "type":"Others","url":"",country:""},
    {"id":"553_-06:00","value":"-06:00","tzv":"-0600", "key":"CST", "type":"Others","url":"",country:""},
    {"id":"554_+08:00","value":"+08:00","tzv":"+0800", "key":"CTT", "type":"Others","url":"",country:""},
    {"id":"555_+03:00","value":"+03:00","tzv":"+0300", "key":"EAT", "type":"Others","url":"",country:""},
    {"id":"556_+01:00","value":"+01:00","tzv":"+0100", "key":"ECT", "type":"Others","url":"",country:""},
    {"id":"557_-05:00","value":"-05:00","tzv":"-0500", "key":"IET", "type":"Others","url":"",country:""},
    {"id":"558_+09:00","value":"+09:00","tzv":"+0900", "key":"JST", "type":"Others","url":"",country:""},
    {"id":"559_+13:00","value":"+13:00","tzv":"+1300", "key":"MIT", "type":"Others","url":"",country:""},
    {"id":"560_+04:00","value":"+04:00","tzv":"+0400", "key":"NET", "type":"Others","url":"",country:""},
    {"id":"561_+12:00","value":"+12:00","tzv":"+1200", "key":"NST", "type":"Others","url":"",country:""},
    {"id":"562_+05:00","value":"+05:00","tzv":"+0500", "key":"PLT", "type":"Others","url":"",country:""},
    {"id":"563_-07:00","value":"-07:00","tzv":"-0700", "key":"PNT", "type":"Others","url":"",country:""},
    {"id":"564_-04:00","value":"-04:00","tzv":"-0400", "key":"PRT", "type":"Others","url":"",country:""},
    {"id":"565_-08:00","value":"-08:00","tzv":"-0800", "key":"PST", "type":"Others","url":"",country:""},
    {"id":"566_+11:00","value":"+11:00","tzv":"+1100", "key":"SST", "type":"Others","url":"",country:""},
    {"id":"567_+07:00","value":"+07:00","tzv":"+0700", "key":"VST", "type":"Others","url":"",country:""},
    {"id":"568_+00:00","value":"+00:00","tzv":"+0000", "key":"GMT","type":"ETC","url":"",country:""},
    {"id":"569_+00:00","value":"+00:00","tzv":"+0000", "key":"GMT+0","type":"ETC","url":"",country:""},
    {"id":"570_-01:00","value":"-01:00","tzv":"-0100", "key":"GMT+1","type":"ETC","url":"",country:""},
    {"id":"571_-10:00","value":"-10:00","tzv":"-1000", "key":"GMT+10","type":"ETC","url":"",country:""},
    {"id":"572_-11:00","value":"-11:00","tzv":"-1100", "key":"GMT+11","type":"ETC","url":"",country:""},
    {"id":"573_+11:00","value":"+11:00","tzv":"+1100", "key":"GMT-11","type":"ETC","url":"",country:""},
    {"id":"574_-12:00","value":"-12:00","tzv":"-1200", "key":"GMT+12","type":"ETC","url":"",country:""},
    {"id":"575_-02:00","value":"-02:00","tzv":"-0200", "key":"GMT+2","type":"ETC","url":"",country:""},
    {"id":"576_-03:00","value":"-03:00","tzv":"-0300", "key":"GMT+3","type":"ETC","url":"",country:""},
    {"id":"577_-04:00","value":"-04:00","tzv":"-0400", "key":"GMT+4","type":"ETC","url":"",country:""},
    {"id":"578_-05:00","value":"-05:00","tzv":"-0500", "key":"GMT+5","type":"ETC","url":"",country:""},
    {"id":"579_-06:00","value":"-06:00","tzv":"-0600", "key":"GMT+6","type":"ETC","url":"",country:""},
    {"id":"580_-07:00","value":"-07:00","tzv":"-0700", "key":"GMT+7","type":"ETC","url":"",country:""},
    {"id":"581_-08:00","value":"-08:00","tzv":"-0800", "key":"GMT+8","type":"ETC","url":"",country:""},
    {"id":"582_-09:00","value":"-09:00","tzv":"-0900", "key":"GMT+9","type":"ETC","url":"",country:""},
    {"id":"583_+00:00","value":"+00:00","tzv":"+0000", "key":"GMT-0","type":"ETC","url":"",country:""},
    {"id":"584_+01:00","value":"+01:00","tzv":"+0100", "key":"GMT-1","type":"ETC","url":"",country:""},
    {"id":"585_+10:00","value":"+10:00","tzv":"+1000", "key":"GMT-10","type":"ETC","url":"",country:""},
    {"id":"586_+12:00","value":"+12:00","tzv":"+1200", "key":"GMT-12","type":"ETC","url":"",country:""},
    {"id":"587_+13:00","value":"+13:00","tzv":"+1300", "key":"GMT-13","type":"ETC","url":"",country:""},
    {"id":"588_+14:00","value":"+14:00","tzv":"+1400", "key":"GMT-14","type":"ETC","url":"",country:""},
    {"id":"589_+02:00","value":"+02:00","tzv":"+0200", "key":"GMT-2","type":"ETC","url":"",country:""},
    {"id":"590_+03:00","value":"+03:00","tzv":"+0300", "key":"GMT-3","type":"ETC","url":"",country:""},
    {"id":"591_+04:00","value":"+04:00","tzv":"+0400", "key":"GMT-4","type":"ETC","url":"",country:""},
    {"id":"592_+05:00","value":"+05:00","tzv":"+0500", "key":"GMT-5","type":"ETC","url":"",country:""},
    {"id":"593_+06:00","value":"+06:00","tzv":"+0600", "key":"GMT-6","type":"ETC","url":"",country:""},
    {"id":"594_+07:00","value":"+07:00","tzv":"+0700", "key":"GMT-7","type":"ETC","url":"",country:""},
    {"id":"595_+08:00","value":"+08:00","tzv":"+0800", "key":"GMT-8","type":"ETC","url":"",country:""},
    {"id":"596_+09:00","value":"+09:00","tzv":"+0900", "key":"GMT-9","type":"ETC","url":"",country:""},
    {"id":"597_+00:00","value":"+00:00","tzv":"+0000", "key":"GMT0","type":"ETC","url":"",country:""},
    {"id":"598_+00:00","value":"+00:00","tzv":"+0000", "key":"Greenwich","type":"ETC","url":"",country:""},
    {"id":"599_+00:00","value":"+00:00","tzv":"+0000", "key":"UCT","type":"ETC","url":"",country:""},
    {"id":"600_+00:00","value":"+00:00","tzv":"+0000", "key":"UTC","type":"ETC","url":"",country:""},
    {"id":"601_+00:00","value":"+00:00","tzv":"+0000", "key":"Universal","type":"ETC","url":"",country:""},
    {"id":"602_+00:00","value":"+00:00","tzv":"+0000", "key":"Zulu","type":"ETC","url":"",country:""},
];