import moment from "moment";

let momentValue = moment();

export const dateFormatData =[
    { id: 1, label: "DD/MM/YYYY", value: momentValue.format("DD/MM/YYYY") },
    { id: 2, label: "MM/DD/YYYY", value: momentValue.format("MM/DD/YYYY") },
    { id: 3, label: "YYYY/MM/DD", value: momentValue.format("YYYY/MM/DD") },
    { id: 4, label: "DD-MM-YYYY", value: momentValue.format("DD-MM-YYYY") },
    { id: 5, label: "MM-DD-YYYY", value: momentValue.format("MM-DD-YYYY") },
    { id: 6, label: "YYYY-MM-DD", value: momentValue.format("YYYY-MM-DD") },
    { id: 7, label: "DD.MM.YYYY", value: momentValue.format("DD.MM.YYYY") },
    { id: 8, label: "MM.DD.YYYY", value: momentValue.format("MM.DD.YYYY") },
    { id: 9, label: "YYYY.MM.DD", value: momentValue.format("YYYY.MM.DD") },
    { id: 10, label:"DD MMM YYYY",value: momentValue.format("DD MMM YYYY")},
    { id: 11,label: "MMM DD YYYY",value: momentValue.format("MMM DD YYYY")},
    { id: 12,label: "YYYY MMM DD",value: momentValue.format("YYYY MMM DD")},
    { id: 13,label: "DD MMMM, YYYY",value: momentValue.format("DD MMMM, YYYY")},
    { id: 14,label: "MMMM DD, YYYY",value: momentValue.format("MMMM DD, YYYY")},
    { id: 15,label: "YYYY, MMMM DD",value: momentValue.format("YYYY, MMMM DD")},
    { id: 16, label: "DDMMYYYY", value: momentValue.format("DDMMYYYY") },
    { id: 17, label: "MMDDYYYY", value: momentValue.format("MMDDYYYY") },
    { id: 18, label: "YYYYMMDD", value: momentValue.format("YYYYMMDD") },
  ];

export const  dateTimeFormatData=[
    {
      id: 1,
      label: "DD/MM/YYYY hh:mm A",
      value: momentValue.format("DD/MM/YYYY hh:mm A"),
    },
    {
      id: 2,
      label: "DD/MM/YYYY HH:mm",
      value: momentValue.format("DD/MM/YYYY HH:mm"),
    },
    {
      id: 3,
      label: "MM/DD/YYYY hh:mm A",
      value: momentValue.format("MM/DD/YYYY hh:mm A"),
    },
    {
      id: 4,
      label: "MM/DD/YYYY HH:mm",
      value: momentValue.format("MM/DD/YYYY HH:mm"),
    },
    {
      id: 5,
      label: "YYYY/MM/DD hh:mm A",
      value: momentValue.format("YYYY/MM/DD hh:mm A"),
    },
    {
      id: 6,
      label: "YYYY/MM/DD HH:mm",
      value: momentValue.format("YYYY/MM/DD HH:mm"),
    },
    {
      id: 7,
      label: "DD-MM-YYYY hh:mm A",
      value: momentValue.format("DD-MM-YYYY hh:mm A"),
    },
    {
      id: 8,
      label: "DD-MM-YYYY HH:mm",
      value: momentValue.format("DD-MM-YYYY HH:mm"),
    },
    {
      id: 9,
      label: "MM-DD-YYYY hh:mm A",
      value: momentValue.format("MM-DD-YYYY hh:mm A"),
    },
    {
      id: 10,
      label: "MM-DD-YYYY HH:mm",
      value: momentValue.format("MM-DD-YYYY HH:mm"),
    },
    {
      id: 11,
      label: "YYYY-MM-DD hh:mm A",
      value: momentValue.format("YYYY-MM-DD hh:mm A"),
    },
    {
      id: 12,
      label: "YYYY-MM-DD HH:mm",
      value: momentValue.format("YYYY-MM-DD HH:mm"),
    },
    {
      id: 13,
      label: "DD.MM.YYYY hh:mm A",
      value: momentValue.format("DD.MM.YYYY hh:mm A"),
    },
    {
      id: 14,
      label: "DD.MM.YYYY HH:mm",
      value: momentValue.format("DD.MM.YYYY HH:mm"),
    },
    {
      id: 15,
      label: "MM.DD.YYYY hh:mm A",
      value: momentValue.format("MM.DD.YYYY hh:mm A"),
    },
    {
      id: 16,
      label: "MM.DD.YYYY HH:mm",
      value: momentValue.format("MM.DD.YYYY HH:mm"),
    },
    {
      id: 17,
      label: "YYYY.MM.DD hh:mm A",
      value: momentValue.format("YYYY.MM.DD hh:mm A"),
    },
    {
      id: 18,
      label: "YYYY.MM.DD HH:mm",
      value: momentValue.format("YYYY.MM.DD HH:mm"),
    },
    {
      id: 19,
      label: "DD MMM YYYY hh:mm A",
      value: momentValue.format("DD MMM YYYY hh:mm A"),
    },
    {
      id: 20,
      label: "DD MMM YYYY HH:mm",
      value: momentValue.format("DD MMM YYYY HH:mm"),
    },
    {
      id: 21,
      label: "MMM DD YYYY hh:mm A",
      value: momentValue.format("MMM DD YYYY hh:mm A"),
    },
    {
      id: 22,
      label: "MMM DD YYYY HH:mm",
      value: momentValue.format("MMM DD YYYY HH:mm"),
    },
    {
      id: 23,
      label: "YYYY MMM DD hh:mm A",
      value: momentValue.format("YYYY MMM DD hh:mm A"),
    },
    {
      id: 24,
      label: "YYYY MMM DD HH:mm",
      value: momentValue.format("YYYY MMM DD HH:mm"),
    },
    {
      id: 25,
      label: "DD MMMM, YYYY hh:mm A",
      value: momentValue.format("DD MMMM, YYYY hh:mm A"),
    },
    {
      id: 26,
      label: "DD MMMM, YYYY HH:mm",
      value: momentValue.format("DD MMMM, YYYY HH:mm"),
    },
    {
      id: 27,
      label: "MMMM DD, YYYY hh:mm A",
      value: momentValue.format("MMMM DD, YYYY hh:mm A"),
    },
    {
      id: 28,
      label: "MMMM DD, YYYY HH:mm",
      value: momentValue.format("MMMM DD, YYYY HH:mm"),
    },
    {
      id: 29,
      label: "YYYY, MMMM DD hh:mm A",
      value: momentValue.format("YYYY, MMMM DD hh:mm A"),
    },
    {
      id: 30,
      label: "YYYY, MMMM DD HH:mm",
      value: momentValue.format("YYYY, MMMM DD HH:mm"),
    },
    {
      id: 31,
      label: "DDMMYYYY hh:mm A",
      value: momentValue.format("DDMMYYYY hh:mm A"),
    },
    {
      id: 32,
      label: "DDMMYYYY HH:mm",
      value: momentValue.format("DDMMYYYY HH:mm"),
    },
    {
      id: 33,
      label: "MMDDYYYY hh:mm A",
      value: momentValue.format("MMDDYYYY hh:mm A"),
    },
    {
      id: 34,
      label: "MMDDYYYY HH:mm",
      value: momentValue.format("MMDDYYYY HH:mm"),
    },
    {
      id: 35,
      label: "YYYYMMDD hh:mm A",
      value: momentValue.format("YYYYMMDD hh:mm A"),
    },
    {
      id: 36,
      label: "YYYYMMDD HH:mm",
      value: momentValue.format("YYYYMMDD HH:mm"),
    },
  ];
