import * as React from 'react';
import { getProjects, createProjects, updateProjectDetails, deleteProject } from "../redux/actions/auth";
import { connect } from "react-redux";
import { Card, CardContent, Tooltip , Button , Typography } from '@mui/material';
import { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import '../styles/project.css';
import '../styles/common.css';
import { useNavigate } from 'react-router-dom';
import validationRules from '../components/ValidationRules';
import ErrorBox from '../components/ErrorBox';
import validateForm from '../components/Validate';
import ServerPaginationGridNoRowCount from "../components/Table/DataGrid"
import EditIcon from "@mui/icons-material/Edit";
import ListAltIcon from '@mui/icons-material/ListAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import extractSortAndFilterParams from '../components/Table/filterSortParams';
import Divider from "@mui/material/Divider";
import Search from '../components/Search'

export const Project = ({ getProjects, createProjects, projectCreated, updateProjectDetails, deleteProject, projectCount }) => {
  const initialValues = {
    name: '',
  };
  const [projectName, setName] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [projectDetails, setProjectDetails] = useState('');
  const [open, setOpen] = React.useState(false);
  const [showEditButton, setShowEditButton] = useState(false);
  const [globalError, setGlobalError] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const handleOpen = () => {
    setValues(initialValues);
    setErrors({});
    setShowEditButton(false);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onChangeProjectName = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    // setName(event.target.value);
  }
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const [paginationModals, setPaginationModals] = useState({
    page: 0,
    pageSize: 10,
  });
  const [sortModels, setSortModels] = useState([]);
  const [filterModels, setFilterModels] = useState({ items: [] });
  const [tableHeight, setTableHeight] = useState(400);
  const rowHeight = 52;
  const updateTableHeight = (pageSize) => {
    const headerHeight = 56; // Approximate header height (adjust based on your actual header height)
    const footerHeight = 56; // Approximate footer height (adjust based on your actual footer height)
    const newHeight = headerHeight + footerHeight + rowHeight * pageSize;
    setTableHeight(newHeight);
  };
  const columns = [
    {
      field: "proj_name",
      headerName: "Project Name",
    },
    {
      field: "testcase_counter",
      headerName: "Total Cases",
    },
    {
      field: "u_name",
      headerName: "Created By",
    },
    
    {
      field: "aciton",
      headerName: "Actions",
      renderCell: (params) => (
        <>
       <Tooltip title="Edit"><EditIcon className="action-icon" onClick={() => updatePopUp(params.row)} titleAccess='Edit'/></Tooltip> 
       <Tooltip title="Test case"><ListAltIcon sx={{ color: '#34B1AA' }} className="action-icon" onClick={() => (navigateToProjectCase(params.row))}/></Tooltip>
        { isAdmin && <Tooltip title="Delete"><DeleteIcon className="action-icon-delete" onClick={() => deleteProjectById(params.row)}/></Tooltip>}
         {/* <Button colorStyle='gray' onClick={() => updatePopUp(params.row)}>Edit</Button> */}
        {/* <Button onClick={() => (navigateToProjectCase(params.row))}>Test Cases</Button> */}
        {/* <Button colorStyle='cancel' onClick={() => deleteProjectById(params.row)}>Delete</Button> */}
        </>
      ),
    },
  ];
  const [width, setWidth] = useState(100/columns.length + '% !important');

  const fields = [
    { name: 'name', label: 'Project Name', type: 'text', validations: [validationRules.required] }
  ];
  const validate = () => {
    const { newErrors, globalError } = validateForm(values, fields);
    setErrors(newErrors);
    setGlobalError(globalError);
    return Object.keys(newErrors).length === 0;
  };

  const createProject = () => {
    const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
    if (validate()) {
      setShowEditButton(false);
      createProjects(values[fields[0].name])
        .then(() => {
          getProjects(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort,JSON.stringify(searchQuery));
          setValues({ ...values, [fields[0].name]: '' });
        })
        .catch((error) => {
          console.error('Error:', error);
        });
      setOpen(false);
    }
  }

  const updateProject = () => {
    const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
    if (validate()) {
      const updatedDetails = {
        id: projectDetails.proj_id,
        name: values[fields[0].name]
      }
      setErrors({});
      setProjectDetails(updatedDetails);
      updateProjectDetails(updatedDetails);
      getProjects(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort,JSON.stringify(searchQuery));
      setOpen(false);
   }
  }

  const updatePopUp = (projectDetails) => {
    setOpen(true);
    setShowEditButton(true);
    setErrors({});
    setValues({...values, [fields[0].name]:projectDetails.proj_name});
    setProjectDetails(projectDetails);
  }

  const deleteProjectById = (row) => {
    const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
    deleteProject(row.proj_id)
      .then(() => {
        getProjects(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort,JSON.stringify(searchQuery));
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  useEffect(() => {
    const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
    console.log(sort);
    getProjects(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort,JSON.stringify(searchQuery));
  }, [paginationModals, sortModels, filterModels,searchQuery]
  )

  useEffect(() => {
    if (localStorage.getItem("token") !== null && localStorage.getItem("token").length > 0) {
      let auth = JSON.parse(localStorage.getItem("authentication"));
      setIsAdmin(auth?.is_admin);
    }
  }, [localStorage.getItem("token")]);

  const navigateToProjectCase = (row) => {
    navigate('/test_cases', { state: row });
  }

  const tableRows = projectCreated?.rows.map((row, index)=>{
    return {...row, id:row.proj_id}
  });
  useEffect(() => {
    updateTableHeight(tableRows?.length);
  }, [paginationModals.pageSize, tableRows]);
  // const tableRows = projectCreated?.rows.map((row, index) => (
  //   <TableRow className='align-items-table' key={index}>
  //     <TableCell>{row.proj_name}</TableCell>
  //     <TableCell>{row.testcase_counter}</TableCell>
  //     <TableCell>{row.u_name}</TableCell>
  //     <TableCell>
  //       <Button colorStyle='gray' onClick={() => updatePopUp(row)}>Edit</Button>
  //       <Button onClick={() => (navigateToProjectCase(row))}>Test Cases</Button>
  //       <Button colorStyle='cancel' onClick={() => deleteProjectById(row)}>Delete</Button>
  //     </TableCell>
  //   </TableRow>
  // ));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className="modalContainer">
        <Box
          component="div"
          className="modalContent"
          noValidate
          autoComplete="off"
        >
          <div className="modal-header">
            <h4 className='label-bold'> {!showEditButton ? 'Create Project' : 'Update Project'}</h4>
            <button type="button" className="btn-close" onClick={handleClose}></button>
          </div>

          <div className="modal-body">
            <div className="modalForm">
            {globalError && <ErrorBox message={globalError} onClose={() => setGlobalError('')} />}
            {fields.map((field) => (
              <div className="modalFormItem">
                <label className='label-bold'>Project Name: </label>
                <TextField 
                  type={field.type}
                  name={field.name}
                  value={values[field.name]}
                  onChange={onChangeProjectName}
                  style={{ borderColor: errors[field.name] ? 'red' : 'initial' }}
                  />
                  {/* {errors[field.name] && <div style={{ color: 'red' }}>{errors[field.name]}</div>} */}
              </div>
              ))}
            </div>
          </div>

          <div className="modal-footer">
            {!showEditButton ? <Button onClick={createProject}>Create Project</Button> : <Button onClick={updateProject}>Update</Button>}
            <Button buttonStyle="cancel" onClick={handleClose}>Close</Button>
          </div>
        </Box>
      </Modal>
      <Card
        raised
        sx={{
          minWidth: "100%",
          overflowX: "auto"  
        }}>
        {/* <Typography variant="h5" sx={{
          p: 2
        }}>
          Project List
          <Tooltip title="Add Project">
            <Button sx={'float: right ; marginBottom: 2px; font-weight: bold'} size={"small"} onClick={handleOpen}> + Add Project</Button>
          </Tooltip>
        </Typography> */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
          <Typography variant="h5">
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <label style={{ marginRight: '20px' }}>Project List</label>

              <Box sx={{ flexGrow: 1 }} /> {/* This will push the Search and Button to the right */}
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Search onChangeQuery={handleSearchChange} value={searchQuery} />
                <Tooltip title="Add Project">
                  <Button sx={'float: right ; marginBottom: 2px; font-weight: bold'} size={"small"} onClick={handleOpen}> + Add Project</Button>
                </Tooltip>
              </Box>
            </Box>
          </Typography>
        </Box>
        <Divider></Divider>

        <CardContent>
        <div style={{ height: tableHeight, width: '100%' }}>
        {/* <Search onChangeQuery={handleSearchChange} value={searchQuery} />  */}
          <ServerPaginationGridNoRowCount
            setPaginationModals={setPaginationModals}
            paginationModals={paginationModals}
            rowsData={tableRows && tableRows.length ? tableRows : []}
            total={projectCreated && projectCreated.count ? projectCreated.count : 0}
            columns={columns}
            columnWidth={width}
            setSortModels={setSortModels}
            sortModels={sortModels}
            setFilterModels={setFilterModels}
            filterModels={filterModels}
          />
        </div>
      </CardContent>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => ({
  usersDataList: state.users,
  projectCreated: state.auth.listProject
});

const mapDispatchToProps = {
  getProjects,
  createProjects,
  updateProjectDetails,
  deleteProject
};

export default connect(mapStateToProps, mapDispatchToProps)(Project);



