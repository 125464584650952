import {
  GET_DASHBOARD,GET_SUITE_LIST,GET_DASHBOARD_BY_ID
    } from "../actions/types";
    
  
    const initialState = {
      dashboard: null,
    };
    
    const DashboardReport = (state = initialState, action) => {
      const { type, payload } = action;
    
      switch (type) {
        
          case GET_DASHBOARD:
            return {
              ...state,
              dashboard: payload,
          };
          case GET_SUITE_LIST:
            return {
              ...state,
              suitelist: payload,
          };
          case GET_DASHBOARD_BY_ID:
            return {
              ...state,
              dashboard: payload,
          };
      
        default:
          return state;
      }
    };
    export default DashboardReport;
    